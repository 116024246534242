// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_YoutubevideoSection__8hcra {\n  background-color: #e8f8ff6b;\n}\n.style_YoutubevideoContent__Eo9UG {\n  width: 80%;\n  margin: auto;\n}\n.style_YoutubevideoBox__1SseE {\n  margin-top: 2rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.style_VideoFrame__FRLgy {\n  width: 746px;\n  height: 428px;\n}\n/* ------------------------------------------------------------------------media query----------------------------------------------------------------- */\n\n@media only screen and (max-width: 600px) {\n  .style_YoutubevideoContent__Eo9UG {\n    width: 100%;\n    padding: 1rem;\n  }\n  .style_VideoFrame__FRLgy {\n    width: 100%;\n    height: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/HTML/InvestmentPlanning/YoutubevideoSection/style.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;AACA;EACE,UAAU;EACV,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,aAAa;AACf;AACA,yJAAyJ;;AAEzJ;EACE;IACE,WAAW;IACX,aAAa;EACf;EACA;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":[".YoutubevideoSection {\n  background-color: #e8f8ff6b;\n}\n.YoutubevideoContent {\n  width: 80%;\n  margin: auto;\n}\n.YoutubevideoBox {\n  margin-top: 2rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.VideoFrame {\n  width: 746px;\n  height: 428px;\n}\n/* ------------------------------------------------------------------------media query----------------------------------------------------------------- */\n\n@media only screen and (max-width: 600px) {\n  .YoutubevideoContent {\n    width: 100%;\n    padding: 1rem;\n  }\n  .VideoFrame {\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"YoutubevideoSection": "style_YoutubevideoSection__8hcra",
	"YoutubevideoContent": "style_YoutubevideoContent__Eo9UG",
	"YoutubevideoBox": "style_YoutubevideoBox__1SseE",
	"VideoFrame": "style_VideoFrame__FRLgy"
};
export default ___CSS_LOADER_EXPORT___;
