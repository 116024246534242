import React, { useState } from "react";
import NoDataFound from "./NoDataFound";
import {
  getSubtracion,
  makePositive,
  rsFilter
} from "../../../common_utilities";
import { imagePath } from "../../../constants";

function NetSurplusShortfall(props) {
  const netSurplusShortfallData = props.data;
  const totalGrossIncome = props.data.income ? props.data.income : [];
  const totalGrossExpense = props.data.expense ? props.data.expense : [];
  const colspan = props.data.surplusShortfallColspan
    ? props.data.surplusShortfallColspan
    : 0;
  const cashflowInvestment = props.data.cashflowinvestments
    ? props.data.cashflowinvestments
    : {};
  const grossInvestment = props.data.gross_investment
    ? props.data.gross_investment
    : {};
  const cashflowYear = props.data.cashflowyear ? props.data.cashflowyear : [];
  const [toggle, setToggle] = useState(false);
  
  return (
    <>
      <div className="CashflowNetSurplus">
        {totalGrossIncome.length > 0 && totalGrossExpense.length > 0 ? (
          <>
            <h4 className="rTitle">
            <img src={imagePath + "/static/assets/img/reports/cashflow/net-surplus.svg"} />
              Net Surplus / Shortfall
            </h4>
            <div className="rContent">
              <p
                dangerouslySetInnerHTML={{
                  __html: netSurplusShortfallData["section_text"]
                    ? netSurplusShortfallData["section_text"]
                    : "",
                }}
              ></p>
            </div>
            <div className="table-responsive rTable cashFlowTables">
              <table className="bgStyleTable">
                <tbody>
                  <tr className="text-center color">
                    <td colSpan={2}>AGE PROFILING</td>
                    <td colSpan={2}>CASH INFLOW /OUTFLOW</td>
                    <td colSpan={1}>GROSS INVESTABLE SURPLUS / SHORTFALL</td>
                    <td id="exsitingColumn" colSpan={toggle * colspan}>
                      EXISTING INVESTMENT COMMITMENT
                    </td>
                    <td>SURPLUS/SHORTFALL</td>
                  </tr>
                  <tr className="outline">
                    <td>Year</td>
                    <td>Age</td>
                    <td>Cash Inflow (₹)(A)</td>
                    <td>Cash Outflow (₹)(B)</td>
                    <td style={{ backgroundColor: "#e2e2e2" }}>
                      Gross Investable Surplus /Shortfall (₹)(C) =(A)-(B)
                    </td>
                    <td style={{ backgroundColor: "#e2e2e2" }}>
                      Gross Investment (₹)(D)
                      {Object.keys(cashflowInvestment).length > 0 ? (
                        <>
                          <span
                            className="expandColumns expandColumnsNet"
                            style={{ whiteSpace: "nowrap" }}
                            onClick={() => setToggle(!toggle)}
                          >
                            {toggle ? "Close Details <" : "View Details >"}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                    </td>
                    {toggle ? (
                      <>
                        {Object.values(cashflowInvestment).map((val, index) => (
                          <td key={index} className=" surpls bg-lightgreen">
                            {val.cat_name} (₹)
                          </td>
                        ))}
                      </>
                    ) : null}
                    <td>Net Investable Surplus (₹)(C)-(D)</td>
                  </tr>
                  {Object.keys(cashflowYear).map((year, index) => (
                    <>
                      <tr className="tabledata" key={index}>
                        <td>{index === 0 ? `${year}*` : year}</td>
                        <td>{cashflowYear[year]}</td>
                        <td>{rsFilter(totalGrossIncome[index])}</td>
                        <td>{rsFilter(totalGrossExpense[index])}</td>
                        {getSubtracion(
                          totalGrossIncome[index],
                          totalGrossExpense[index]
                        ) > 0 ? (
                          <td style={{ backgroundColor: "#e2e2e2" }}>
                            {rsFilter(
                              getSubtracion(
                                totalGrossIncome[index],
                                totalGrossExpense[index]
                              )
                            )}
                          </td>
                        ) : (
                          <td
                            style={{ color: "red", backgroundColor: "#e2e2e2" }}
                          >
                            {rsFilter(
                              makePositive(
                                getSubtracion(
                                  totalGrossIncome[index],
                                  totalGrossExpense[index]
                                )
                              )
                            )}
                          </td>
                        )}
                        <td style={{ backgroundColor: "#e2e2e2" }}>
                          {rsFilter(grossInvestment[year])}
                        </td>
                        {toggle ? (
                          Object.keys(cashflowInvestment).length > 0 ? (
                            <>
                              {Object.values(cashflowInvestment).map((v, i) => (
                                <td key={i}>{rsFilter(v["amount"][year])}</td>
                              ))}
                            </>
                          ) : null
                        ) : null}
                        {getSubtracion(
                          getSubtracion(
                            totalGrossIncome[index],
                            totalGrossExpense[index]
                          ),
                          grossInvestment[year]
                        ) > 0 ? (
                          <td>
                            {rsFilter(
                              getSubtracion(
                                getSubtracion(
                                  totalGrossIncome[index],
                                  totalGrossExpense[index]
                                ),
                                grossInvestment[year]
                              )
                            )}
                          </td>
                        ) : (
                          <td style={{ color: "red" }}>
                            (
                            {rsFilter(
                              makePositive(
                                getSubtracion(
                                  getSubtracion(
                                    totalGrossIncome[index],
                                    totalGrossExpense[index]
                                  ),
                                  grossInvestment[year]
                                )
                              )
                            )}
                            )
                          </td>
                        )}
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="container" style={{ padding: "5%" }}>
              <div className="notes_sec_div mt-4" style={{ border: "none" }}>
                <div className="notes_head_div">
                  <i />
                  <span>Notes</span>
                </div>
                <div className="notes_text_div" style={{ marginTop: "0%" }}>
                  <div className="rContent ">
                    <p>
                      All the calculations in the above table are made as per
                      the calendar year. Eg. If you start your financial
                      planning in the month of September, the gross outflow
                      analysis for the first year will be calculated from
                      September to December.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <NoDataFound />
          </>
        )}
      </div>
    </>
  );
}

export default NetSurplusShortfall;
