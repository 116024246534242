import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import metadata from "../meta-data.json";
import schemaTemplate from "../schema-template.json";
import { removeSlash } from "../common_utilities";


const Title = () => {
    const location = useLocation();
    let url = removeSlash(location.pathname);
    const serviceData = {
        "/": {
            "@type": "Service",
            "name": "Fintoo"
        },
        "/web/financial-planning-page": {
            "@type": "Service",
            "name": "Financial Planning"
        },
        "/web/retirement-planning-page": {
            "@type": "Service",
            "name": "Retirement Planning"
        },
        "/web/investment-planning-page": {
            "@type": "Service",
            "name": "Investment Planning"
        },
        "/web/tax-planning-page": {
            "@type": "Service",
            "name": "Tax Planning"
        },
        "/web/risk-management":{
            "@type": "Service",
            "name": "Risk Management"
        },
        "/web/direct-mutual-funds": {
            "@type": "DirectMutualFundService",
            "name": "Direct Mutual Fund"
        },
        "/web/bond-investment": {
            "@type": "BondInvestmentService",
            "name": "Bond Investment"
        },
        "/web/stock-advisory": {
            "@type": "StockAdvisory",
            "name": "Stock Advisory"
        },
        "/web/international-equity": {
            "@type": "InternationalEquityService",
            "name": "International Equity"
        },
        "/web/ipo": {
            "@type": "IPOService",
            "name": "IPO"
        },
        "/web/itr-file": {
            "@type": "ITRFilingService",
            "name": "ITR Filing"
        },
        "/web/nri-taxation": {
            "@type": "NRITaxationService",
            "name": "NRI Taxation"
        },
        "/web/notices": { "@type": "IncomeTaxNoticeService", "name": "Notices"},
        "/web/tax-planning-page": { "@type": "TaxService", "name": "Tax Planning"},
        "/web/tax-calculators": {"@type": "TaxCalculatorsService", "name": "Tax Calculators"},

        "/web/pricing": {"@type": "FintooPricing", "name": "Pricing"}
    }

    const addSchema = (page) => {
        if (url in serviceData || page == "home"){
             // Remove old schema scripts
             const oldScripts = document.querySelectorAll('script[type="application/ld+json"]');
             oldScripts.forEach(script => script.remove());

            const script = document.createElement('script');
            script.type = 'application/ld+json';
            const schemaData = { ...schemaTemplate };
            
            if (url == "/web/itr-file"){
                const fullPath = `${location.pathname}${location.search}`;
                console.log("full_path: ", fullPath)
                schemaData["url"] = "https://stg.minty.co.in" + fullPath; 
                schemaData["mainEntityOfPage"]["@id"] = "https://stg.minty.co.in" + fullPath;
            }
            else if (page == "home"){
                schemaData["url"] = "https://stg.minty.co.in"; 
                schemaData["about"]["contactPoint"] = {
                    "@type": "ContactPoint",
                    "telephone": "9699800600",
                    "contactType": "customer service",
                    "areaServed": ["IN","AE"],
                    "availableLanguage": ["en","Hindi"]
                }
                if ("mainEntityOfPage" in schemaData) {
                    delete schemaData["mainEntityOfPage"]; 
                }
            }
            else{
                schemaData["url"] = "https://stg.minty.co.in" + url; 
                schemaData["mainEntityOfPage"]["@id"] = "https://stg.minty.co.in" + url;
            }
           
            if (page == "home"){
                schemaData["about"]["description"] = metadata["/"]['description'];
                schemaData["name"] = metadata["/"]["title"];
                schemaData["about"]["@type"] = serviceData["/"]["@type"];
                schemaData["about"]["name"] = serviceData["/"]["name"];
                schemaData["about"]["serviceType"] = [
                    "Financial Planning",
                    "Investment Advisory",
                    "Tax Planning",
                    "Insurance Services",
                    "Mutual Funds"
                ]
            }else{
                schemaData["about"]["description"] = metadata[url]['description'];
                schemaData["name"] = metadata[url]["title"];
                schemaData["about"]["@type"] = serviceData[url]["@type"];
                schemaData["about"]["name"] = serviceData[url]["name"];
                schemaData["about"]["serviceType"] = serviceData[url]["name"];
            }
            console.log("schemaData", schemaData);
            script.textContent = JSON.stringify(schemaData);

            const head = document.head;
            head.insertBefore(script, head.firstChild);
            console.log("Script added successfully.");
        }
    }

    useEffect(()=> {
        let url = removeSlash(location.pathname);
        console.log("schemaURL: ", url)

        if(url in metadata) {
            document.title = metadata[url]["title"];

            const metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
                metaDescription.setAttribute('content', metadata[url]["description"]);
            }
            console.log("schemaURL", url, "  location: ", location);
            addSchema();
           
        } else {
            document.title = metadata["/"]["title"];

            const metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
                metaDescription.setAttribute('content', metadata["/"]["description"]);
            }
            addSchema("home");
        }
    }, [location]);
    return <></>;
}
export default Title;