import React, { useEffect, useRef, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import ReactDatePicker from "../../../components/HTML/ReactDatePicker/ReactDatePicker";
import FintooRadio2 from "../../../components/FintooRadio2";
import Switch from "react-switch";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import GoalsDropdown from "../../../components/GoalsDropdown/GoalDropdown";
import customStyles from "../../../components/CustomStyles";
import * as toastr from "toastr";
import "toastr/build/toastr.css";
import { useDispatch } from "react-redux";
import { apiCall } from "../../../common_utilities";
import { ADVISORY_MULTIPLE_ASSETS_LINKAGE_API } from "../../../constants";
import { ScrollToTop } from "../ScrollToTop"
function Realestate(props) {
  const setAssetsDetails = props.setAssetsDetails;
  const assetsDetails = props.assetsDetails;
  const familyData = props.familyData;
  const goalData = props.goalData;
  const setDate = props.setDate;
  const rentalincomeData = props.rentalincomeData;
  const addForm = props.addForm;
  const updateForm = props.updateForm;
  const addAssetsSubmit = props.addAssetsSubmit;
  const cancelAssetForm = props.cancelAssetForm;
  const updateAssetsSubmit = props.updateAssetsSubmit;
  const assetForMember = props.assetForMember;
  const session = props.session;
  const setGoalSelected = props.setGoalSelected;
  const closeModal = props.closeModal;
  const selectGoals = props.selectGoals;
  const selectedGoals = props.selectedGoals;
  const selectedGoalIdArray = props.selectedGoalIdArray;
  const selectedGoalsId = props.selectedGoalsId;
  const setPriorityArray = props.setPriorityArray;
  const selectedPriorityArray = props.selectedPriorityArray;
  const setAutoMatedGoal = props.setAutoMatedGoal;
  const isAutoMatedGoal = props.isAutoMatedGoal;
  const setGoalLink = props.setGoalLink;
  const isGoalSelected = props.isGoalSelected;
  const setSelectedGoals = props.setSelectedGoals;
  const setSelectedGoalsId = props.setSelectedGoalsId;
  const setSelectedPriorityArray = props.setSelectedPriorityArray;
  const unchangedgoaldata = props.unchangedgoaldata;
  const [familySortedData, setFamilySortedData] = useState([]);
  const [multipleTotalInvestedValue, setMultipleTotalInvestedValue] =
    useState(0);
  const [multipleTotalCurrentValue, setMultipleTotalCurrentValue] = useState(0);
  const [multipleTotalAssetValueLinked, setMultipleTotalAssetValueLinked] =
    useState(0);
  const [
    multipleTotalAssetValueForLinkages,
    setMultipleTotalAssetValueForLinkages,
  ] = useState(0);
  const [
    multipleTotalRecurringInvestment,
    setMultipleTotalRecurringInvestment,
  ] = useState(0);
  const data = props.filteredAssetsData.select_subclass;
  const [subClass, setSubClass] = useState("");  
  const [investmentFrom, setInvestmentFrom] = useState("");  
  const dispatch = useDispatch();

  const scrollToList = () => {
    window.scroll({ top: 0 });
  };

  const groupedData = {};
  if (data) {
    for (const item of data) {
      const key = `${item.asset_member_id}-${item.value}`;
      if (!groupedData[key]) {
        groupedData[key] = { ...item, asset_id: [] };
      }
      groupedData[key].asset_id.push(item.asset_id);
    }
  }

  useEffect(() => {
    if('multi_linkage_goal_data' in assetsDetails && Array.isArray(assetsDetails.multi_linkage_goal_data) && assetsDetails.multi_linkage_goal_data.length > 0) {
      setMultipleTotalInvestedValue(assetsDetails['multi_linkage_goal_data'][0]['totalinvestedvalue'])
      setMultipleTotalCurrentValue(assetsDetails['multi_linkage_goal_data'][0]['totalcurrentvalue'])
      setMultipleTotalAssetValueLinked(assetsDetails['multi_linkage_goal_data'][0]['totalassetvaluelinked'])
      setMultipleTotalAssetValueForLinkages(assetsDetails['multi_linkage_goal_data'][0]['totalassetvalueforlinkages'])
      setMultipleTotalRecurringInvestment(assetsDetails['multi_linkage_goal_data'][0]['totalrecurringinvestment'])
      setSubClass(assetsDetails['asset_name'])
      const resultObject = familyData.find(item => item.value === assetsDetails['asset_member_id']);
      const labelName = resultObject ? resultObject.label : null;
      setInvestmentFrom(labelName)
    }
  }, [assetsDetails?.multi_linkage_goal_data]);


  useEffect(() => {
    if (assetsDetails.asset_sub_category_id != 125) {
      setMultipleTotalInvestedValue(0);
      setMultipleTotalCurrentValue(0);
      setMultipleTotalAssetValueLinked(0);
      setMultipleTotalAssetValueForLinkages(0);
      setMultipleTotalRecurringInvestment(0);
    }
  }, [assetsDetails.asset_sub_category_id]);

  const result = Object.values(groupedData).map((item) => {
    return {
      asset_id: item.asset_id,
      value: item.value,
      label: item.label,
      asset_recurring: item.asset_recurring,
      asset_amount: item.asset_amount,
      asset_member_id: item.asset_member_id,
      total_invested_value: item.total_invested_value,
      total_current_value: item.total_current_value,
      total_linked_goals_value: item.total_linked_goals_value,
    };
  });
  const uniqueData = result;
  const dynamicLabelsToRemove = [
    "Agricultural Land",
    "Commercial",
    "Land",
    "Residential Premises",
    "Under Construction Property",
    "Others",
  ];
  // Extract unique labels
  const uniqueLabels = [...new Set(uniqueData.map((item) => item.label))];

  // Remove entries with dynamic labels
  // const modifiedData = uniqueData.filter((item) => {
  //   if (
  //     dynamicLabelsToRemove.includes(item.label) &&
  //     uniqueLabels.includes(item.label)
  //   ) {
  //     uniqueLabels.splice(uniqueLabels.indexOf(item.label), 1);
  //     return true;
  //   }
  //   return !dynamicLabelsToRemove.includes(item.label);
  // });

  // useEffect(() => {
  //   if (assetsDetails?.asset_sub_class_id) {
  //     let all_subclass = props.filteredAssetsData.all_subclass
  //       .filter((v) => v.value == assetsDetails.asset_sub_class_id)
  //       .map((v) => v.asset_member_id);
  //     all_subclass = [...new Set(all_subclass)];
  //     var totalInvestedValue = 0;
  //     var totalCurrentValue = 0;
  //     var totalAssetValueLinked = 0;
  //     var totalAssetValueForLinkages = 0;
  //     var totalRecurringInvestment = 0;
  //     for (let i = 0; i < props.filteredAssetsData.all_subclass.length; i++) {
  //       let _records = props.filteredAssetsData.all_subclass;
  //       if (
  //         _records[i].value == assetsDetails.asset_sub_class_id &&
  //         _records[i].asset_member_id == assetsDetails.asset_member_id
  //       ) {
  //         totalInvestedValue =
  //           totalInvestedValue + Number(_records[i].total_invested_value);
  //         totalCurrentValue =
  //           totalCurrentValue + Number(_records[i].total_current_value);
  //         if (_records[i].total_linked_goals_value == 1) {
  //           totalAssetValueLinked =
  //             totalAssetValueLinked + Number(_records[i].total_current_value);
  //         } else {
  //           totalAssetValueForLinkages =
  //             totalAssetValueForLinkages +
  //             Number(_records[i].total_current_value);
  //         }
  //         // if (_records[i].value == 62 &&_records[i].asset_recurring == '1' || _records[i].asset_recurring == true) {
  //         //   totalRecurringInvestment = totalRecurringInvestment + Number(_records[i].asset_amount)
  //         // }
  //       }
  //     }
  //     setMultipleTotalInvestedValue(totalInvestedValue);
  //     setMultipleTotalCurrentValue(totalCurrentValue);
  //     setMultipleTotalAssetValueLinked(totalAssetValueLinked);
  //     setMultipleTotalAssetValueForLinkages(totalAssetValueForLinkages);
  //     setMultipleTotalRecurringInvestment(totalRecurringInvestment);
  //     setFamilySortedData(
  //       familyData.filter((v) => all_subclass.includes(v.value))
  //     );
  //   } else {
  //     setFamilySortedData([]);
  //   }
  // }, [
  //   assetsDetails?.asset_sub_class_id,
  //   assetsDetails?.asset_id,
  //   assetsDetails?.asset_member_id,
  // ]);
  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        pincode: {
          // name the rule
          message: "Please enter valid :attribute",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[1-9][0-9]{5}$/i);
          },
        },
      },
    })
  );
  const [, forceUpdate] = useState();
  const [, setForceUpdate] = useState(0);
  const assetEditId = props.assetEditId;

  const handleRealEstateSubmit = async (e) => {
    if (assetsDetails.asset_sub_category_id == 125) {
      if (multipleTotalCurrentValue == 0) {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Please select the required dropdown options");
      } else {
        let assetsDetailss = assetsDetails;
        let assetMemberIdToSearch = assetsDetailss.asset_member_id;
        let assetSubClassIdToSearch = assetsDetailss.asset_sub_class_id;
        let arrayToSearch = uniqueData;
        let matchingObject = arrayToSearch.find(
          (obj) =>
            obj.asset_member_id === assetMemberIdToSearch &&
            obj.asset_sub_class_id === assetSubClassIdToSearch
        );
        if (matchingObject) {
          assetsDetailss.asset_id = matchingObject.asset_id;
        }
        try {
          let url = ADVISORY_MULTIPLE_ASSETS_LINKAGE_API;
          let payload = assetsDetailss;
          let addassetData = await apiCall(url, payload, true, false);
          if (addassetData["error_code"] == "100") {
            var msg = assetsDetailss.subcategorydetail
              ? " - " + assetsDetailss.asset_name
              : "";
            scrollToList();
            setMultipleTotalInvestedValue(0);
            setMultipleTotalCurrentValue(0);
            setMultipleTotalAssetValueLinked(0);
            setMultipleTotalAssetValueForLinkages(0);
            setMultipleTotalRecurringInvestment(0);
            toastr.options.positionClass = "toast-bottom-left";
            toastr.success(
              assetsDetailss.categorydetail + msg + " added succesfully"
            );
            dispatch({ type: "RELOAD_SIDEBAR", payload: true });
          } else {
            toastr.options.positionClass = "toast-bottom-left";
            toastr.error("Something went wrong");
          }
        } catch (err) {
          toastr.options.positionClass = "toast-bottom-left";
          toastr.error("Something went wrong");
        }
      }
    }
    e.preventDefault();
    var isFormValid = simpleValidator.current.allValid();
    simpleValidator.current.showMessages();
    setForceUpdate((v) => ++v);
    if (isFormValid) {
      // setIsLoading(true);
      addAssetsSubmit(e);
      setGoalSelected(false);
      setSelectedGoalsId([]);
      setSelectedPriorityArray([]);
      setAutoMatedGoal(true);
      setSelectedGoals("Automated Linkage");
      simpleValidator.current.hideMessages();
      setForceUpdate((v) => ++v);
    }
  };

  const handleRealEstateUpdate = async (e) => {
    e.preventDefault();
    var isFormValid = simpleValidator.current.allValid();
    simpleValidator.current.showMessages();
    setForceUpdate((v) => ++v);

    if (isFormValid) {
      // setIsLoading(true);
      updateAssetsSubmit(e);
      setGoalSelected(false);

      setSelectedGoalsId([]);
      setSelectedPriorityArray([]);
      setAutoMatedGoal(true);
      setSelectedGoals("Automated Linkage");
      simpleValidator.current.hideMessages();
      setForceUpdate((v) => ++v);
    }
  };

  const handleRealEstateCancel = async (e) => {
    e.preventDefault();
    cancelAssetForm(e);
    simpleValidator.current.hideMessages();
    setForceUpdate((v) => ++v);
  };

  useEffect(() => {
    simpleValidator.current.hideMessages();
    simpleValidator.current.purgeFields();

    if (assetEditId) {
      if (selectedGoals == "Automated Linkage") {
        setAutoMatedGoal(true);
        setSelectedGoals("Automated Linkage");
      } else {
        setAutoMatedGoal(false);
      }
    } else {
      setAutoMatedGoal(true);
      setSelectedGoals("Automated Linkage");
    }
    if (session && !assetEditId) {
      setSelectedGoalsId([]);
      setSelectedPriorityArray([]);
      setGoalSelected(false);
      props.getfpgoalsdata(session.data.fp_log_id);
    }
    setForceUpdate((v) => ++v);
  }, [assetsDetails?.asset_sub_category_id]);

  return (
    <div>
      {(assetsDetails.asset_sub_category_id == 53 ||
        assetsDetails.asset_sub_category_id == 55 ||
        assetsDetails.asset_sub_category_id == 52 ||
        assetsDetails.asset_sub_category_id == 56 ||
        assetsDetails.asset_sub_category_id == 51 ||
        assetsDetails.asset_sub_category_id == 59) && (
        <form noValidate="novalidate" name="goldassetform">
          <div className="row  d-flex align-items-center">
            <div className="col-md-5 custom-input">
              <div
                className={`form-group ${
                  assetsDetails.asset_name ? "inputData" : null
                } `}
                style={{ paddingTop: "19px" }}
              >
                <input
                  type="text"
                  id="asset_name_Real_estate"
                  name="asset_name"
                  maxLength={35}
                  value={assetsDetails.asset_name}
                  onChange={(e) => {
                    setAssetsDetails({
                      ...assetsDetails,
                      asset_name: e.target.value,
                    });
                  }}
                  required
                  autoComplete="off"
                />
                <span class="highlight"></span>
                <span class="bar"></span>
                <label for="name">Name of Asset*</label>
              </div>
              {simpleValidator.current.message(
                "Asset Name",
                assetsDetails.asset_name,
                "required|alpha_space|min:3|max:60",
                {
                  messages: {
                    alpha_space: "Alphabets are allowed only.",
                    required: "Please enter asset name",
                    max: "Asset name must be between 3-35 characters.",
                    min: "Asset name must be between 3-35 characters.",
                  },
                }
              )}
            </div>
            <div className="col-md-5">
              <div className="material">
                <Form.Label>Who is This Investment For?*</Form.Label>
                {familyData && (
                  <Select
                    classNamePrefix="sortSelect"
                    isSearchable={false}
                    styles={customStyles}
                    options={familyData}
                    onChange={(e) =>
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_member_id: e.value,
                      })
                    }
                    value={familyData.filter(
                      (v) => v.value == assetsDetails.asset_member_id
                    )}
                    isDisabled={
                      assetsDetails.asset_type &&
                      assetsDetails.asset_type != "none"
                    }
                  />
                )}
              </div>
            </div>
          </div>

          <div className="row d-flex align-items-center">
            <div className="col-md-5 ">
              <div className="material mb-3 form-floating">
                <Form.Label className="mb-0">Date of Purchase</Form.Label>
                <div
                  className="dt-conbx"
                  style={{
                    borderBottom: "1px solid #dadada",
                    paddingTop: "15px",
                  }}
                >
                  <ReactDatePicker
                    select_date={moment(
                      assetsDetails.asset_purchase_date,
                      "DD/MM/YYYY"
                    ).toDate()}
                    setDate={(date) => {
                      setDate(date, "assetPurchaseDate");
                    }}
                    minDate={""}
                    maxDate={moment().toDate()}
                    className="pt-4"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-5  custom-input">
              <div
                className={`form-group 
                ${
                  assetsDetails.asset_purchase_amount == 0
                    ? "inputData"
                    : assetsDetails.asset_purchase_amount
                    ? "inputData"
                    : null
                } 
                  `}
                   style={{paddingTop : "19px"}}>
                  <input
                    type="text"
                    name="asset_purchase_amount"
                    maxLength={35}
                    value={assetsDetails.asset_purchase_amount}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/\D/g, ''); 
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_purchase_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                      });
                    }}
                    autoComplete="off"
                  />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label for="name">Purchase Amount (₹)</label>
                </div>
              </div>
            </div>

          <div className="row py-md-2">
            <div className="col-md-5 custom-input">
              <div
                className={`form-group ${
                  assetsDetails.asset_amount ? "inputData" : null
                } `}
              >
                <input
                  type="number"
                  id="asset_amount_Real_estate"
                  name="asset_amount"
                  maxLength={35}
                  value={assetsDetails.asset_amount}
                  onChange={(e) => {
                    setAssetsDetails({
                      ...assetsDetails,
                      asset_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                    });
                  }}
                  autoComplete="off"
                />
                <span class="highlight"></span>
                <span class="bar"></span>
                <label for="name">Current Market Value*</label>
              </div>
              {simpleValidator.current.message(
                "Current Balance",
                assetsDetails.asset_amount,
                "required",
                { messages: { required: "Please add asset value" } }
              )}
            </div>
            <div className="col-md-5 custom-input">
              <div
                className={`form-group ${
                  assetsDetails.asset_pin_code ? "inputData" : null
                } `}
              >
                <span>
                  <input
                    type="number"
                    id="Pincode_Real_estate"
                    name="asset_amount"
                    maxLength={35}
                    value={assetsDetails.asset_pin_code}
                    onChange={(e) => {
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_pin_code: e.target.value.slice(0, 6),
                      });
                    }}
                    autoComplete="off"
                  />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  {/* <label for="name">Pincode*</label> */}
                  <label for="name">Pincode</label>
                </span>
                <span className="info-hover-box">
                  <span className="icon">
                    <img
                      alt="More information"
                      src="https://images.minty.co.in/static/assets/img/more_information.svg"
                    />
                  </span>
                  <span className="msg">Ex: 400097</span>
                </span>
              </div>
              {/* {simpleValidator.current.message(
                "pincode",
                assetsDetails.asset_pin_code,
                "required|pincode",
                { messages: { required: "Please enter pincode" } }
              )} */}
              {assetsDetails.asset_pin_code.length >= 1 &&
                assetsDetails.asset_pin_code.length <= 6 &&
                simpleValidator.current.message(
                  "pincode",
                  assetsDetails.asset_pin_code,
                  "pincode",
                  { messages: { pincode: "Please enter a valid pincode" } }
                )}
            </div>
          </div>
          <div className="row py-md-2 mt-1">
            {assetsDetails.asset_sub_category_id != "55" && (
              <div className="col-md-5 ">
                <div className="dark-label">
                  <Form.Label>Residential premises type?*</Form.Label>
                  <div className="d-flex pt-4 " style={{ clear: "both" }}>
                    <FintooRadio2
                      checked={assetsDetails.asset_isrented == "0"}
                      onClick={(v) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_isrented: "0",
                        })
                      }
                      title="Rented"
                    />
                    <FintooRadio2
                      checked={assetsDetails.asset_isrented == "1"}
                      onClick={(v) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_isrented: "1",
                        })
                      }
                      title="Self Occupied"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-5 ">
              <div className="dark-label">
                <Form.Label>Mortgage or Freehold?*</Form.Label>
                <div className="d-flex  pt-4 " style={{ clear: "both" }}>
                  <FintooRadio2
                    checked={assetsDetails.asset_ismortgage == "0"}
                    onClick={(v) =>
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_ismortgage: "0",
                      })
                    }
                    title="Free Hold"
                  />
                  <FintooRadio2
                    checked={assetsDetails.asset_ismortgage == "1"}
                    onClick={(v) =>
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_ismortgage: "1",
                      })
                    }
                    title="Mortgage"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row py-md-2">
            {assetsDetails.asset_isrented == "0" &&
              rentalincomeData.length > 0 && (
                <div className="col-md-5 ">
                  <Form.Label>Rental Income*</Form.Label>
                  <Select
                    classNamePrefix="sortSelect"
                    isSearchable={false}
                    styles={customStyles}
                    options={rentalincomeData}
                    onChange={(e) =>
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_rental_income: e.value,
                      })
                    }
                    value={rentalincomeData.filter(
                      (v) => v.value == assetsDetails.asset_rental_income
                    )}
                  />
                  {simpleValidator.current.message(
                    "select",
                    assetsDetails.asset_rental_income,
                    "required",
                    { messages: { required: "Please add rental income" } }
                  )}
                </div>
              )}
            {assetsDetails.asset_isrented == "0" && (
              <div className="col-md-5 ">
                <div className="dark-label">
                  <Form.Label>City Type?*</Form.Label>
                  <div className="d-flex pt-4" style={{ clear: "both" }}>
                    <FintooRadio2
                      checked={assetsDetails.asset_citytype == "0"}
                      onClick={(v) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_citytype: "0",
                        })
                      }
                      title="Tier 1"
                    />
                    <FintooRadio2
                      checked={assetsDetails.asset_citytype == "1"}
                      onClick={(v) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_citytype: "1",
                        })
                      }
                      title="Tier 2"
                    />
                    <FintooRadio2
                      checked={assetsDetails.asset_citytype == "2"}
                      onClick={(v) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_citytype: "2",
                        })
                      }
                      title="Tier 3"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              marginTop: `${
                assetsDetails.asset_isrented == "0" ? "1rem" : "0"
              }`,
            }}
            className="row py-md-2"
          >
            <div className="col-md-8">
              <div className="d-md-flex">
                <Form.Label className=" ">
                  Consider This Asset In Automated Linkage*
                </Form.Label>
                <span className="info-hover-left-box ms-md-4">
                  <span Name="icon">
                    <img
                      alt="More information"
                      src="https://images.minty.co.in/static/assets/img/more_information.svg"
                    />
                  </span>
                  <span className="msg">
                    Select a goal below to map this investment with a goal of
                    your choice. Otherwise, Fintoo will link it automatically
                    with your high priority goal. In case, you do not wish to
                    utilize this investment for any goal, select "NO".
                  </span>
                </span>
                <div className="d-flex ms-md-4">
                  <div>No</div>
                  <Switch
                    onChange={(v) =>
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_islinkable: v,
                      })
                    }
                    checked={assetsDetails.asset_islinkable}
                    activeBoxShadow="0 0 2px 3px #042b62"
                    className="react-switch px-2"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={20}
                    width={40}
                    onColor="#042b62"
                    offColor="#042b62"
                  />
                  <div>Yes</div>
                </div>
              </div>
            </div>
          </div>

          {assetsDetails.asset_isrented == "0" && (
            <div className="row py-md-2">
              <div className="col-md-8">
                <div className="d-flex">
                  <Form.Label className=" ">
                    Consider This Asset In Asset Allocation*
                  </Form.Label>
                  <span className="info-hover-left-box ps-md-3">
                    <span className="icon">
                      <img
                        alt="More information"
                        src="https://images.minty.co.in/static/assets/img/more_information.svg"
                      />
                    </span>
                    <span className="msg">
                      Select a goal below to map this investment with a goal of
                      your choice. Otherwise, Fintoo will link it automatically
                      with your high priority goal. In case, you do not wish to
                      utilize this investment for any goal, select "NO".
                    </span>
                  </span>
                  <div className="d-flex ms-4">
                    <div>No</div>
                    <Switch
                      onChange={(v) => {
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_isallocation: v,
                        });
                      }}
                      checked={assetsDetails.asset_isallocation}
                      className="react-switch px-2"
                      activeBoxShadow="0 0 2px 3px #042b62"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                      onColor="#042b62"
                      offColor="#042b62"
                    />
                    <div>Yes</div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {assetsDetails.asset_islinkable == true && (
            <>
              <div className="row py-md-2">
                <div className="col-md-8 mt-md-2">
                  <div className="d-md-flex">
                    <Form.Label className="link_asset_style">
                      Link This Investment Asset to Goal
                    </Form.Label>{" "}
                    <span
                      className="ms-md-4 info-hover-left-box float-right"
                      style={{
                        position: "relative !important",
                      }}
                    >
                      <span className="icon">
                        <img
                          alt="More information"
                          src="https://images.minty.co.in/static/assets/img/more_information.svg"
                        />
                      </span>
                      <span className="msg">
                        You can only assign goals which are prior to the end
                        date of the asset
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-md-2 mt-5">
                  <div className="material">
                    <div
                      className="m-0 btn-sm default-btn gradient-btn save-btn"
                      onClick={() => setGoalSelected(true)}
                    >
                      Select Goals
                    </div>
                    <br></br>
                    <br></br>

                    {selectedGoals ? (
                      <div
                        className="d-flex"
                        style={{ textAlign: "left!important" }}
                      >
                        <div style={{ whiteSpace: "nowrap" }}>
                          <b>Selected Goals : </b>
                        </div>
                        <div className="ms-1">{selectedGoals}</div>
                      </div>
                    ) : (
                      ""
                    )}
                    {isGoalSelected ? (
                      <GoalsDropdown
                        setGoalSelected={setGoalSelected}
                        goals={goalData}
                        unchangedgoaldata={unchangedgoaldata}
                        closeModal={closeModal}
                        selectGoals={selectGoals}
                        selectedGoals={selectedGoals}
                        selectedGoalIdArray={selectedGoalIdArray}
                        selectedGoalsId={selectedGoalsId}
                        setPriorityArray={setPriorityArray}
                        selectedPriorityArray={selectedPriorityArray}
                        setAutoMatedGoal={setAutoMatedGoal}
                        isAutoMatedGoal={isAutoMatedGoal}
                        setGoalLink={setGoalLink}
                        type={"Asset"}
                        isGoalFilter={
                          assetsDetails.asset_isrecurring == "1" ? "1" : "0"
                        }
                        isAssetRecurring={
                          assetsDetails.asset_isrecurring == "1" ? "1" : "0"
                        }
                      ></GoalsDropdown>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="row py-2">
            <div className=" text-center">
              <div>
                <div className="btn-container">
                  <div className="d-flex justify-content-center">
                    <Link to={process.env.PUBLIC_URL + "/datagathering/goals"}>
                      <div className="previous-btn form-arrow d-flex align-items-center">
                        <FaArrowLeft />
                        <span className="hover-text">&nbsp;Previous</span>
                      </div>
                    </Link>

                    {props.addForm && (
                      <button
                        onClick={(e) => handleRealEstateSubmit(e)}
                        className="default-btn gradient-btn save-btn"
                      >
                        Save & Add More
                      </button>
                    )}
                    {props.updateForm && (
                      <div>
                        <button
                          onClick={(e) => handleRealEstateCancel(e)}
                          className="default-btn gradient-btn save-btn"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={(e) => handleRealEstateUpdate(e)}
                          className="default-btn gradient-btn save-btn"
                        >
                          Update
                        </button>
                      </div>
                    )}
                    <div
                      className="next-btn form-arrow d-flex align-items-center"
                      onClick={() => {
                        ScrollToTop();
                        props.setTab("tab2")
                      }}
                    >
                      <span className="hover-text" style={{ maxWidth: 100 }}>
                        Continue&nbsp;
                      </span>
                      <FaArrowRight />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      {assetsDetails.asset_sub_category_id == 125 && (
        <>
          <form noValidate="novalidate" name="goldassetform">
            <div className="row d-flex align-items-center">
              <div className="col-md-5 custom-input">
                <div
                  className={`form-group w-100 ${
                    assetsDetails.asset_name ? "inputData" : null
                  }`}
                  style={{ paddingTop: "17px" }}
                >
                  <input
                    type="text"
                    id="Asset_Class_Real_estate"
                    name="asset_name"
                    maxLength={35}
                    className="shadow-none"
                    value={"Real Estate"}
                    disabled="disabled"
                    onChange={(e) => {
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_name: e.target.value,
                      });
                    }}
                    autoComplete="off"
                  />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label for="name">Asset Class</label>
                </div>
              </div>
              <div className="col-md-5">
                <div
                  className="material"
                  style={{
                    marginTop:
                      assetsDetails.asset_sub_category_id == 73 ? "0px" : "0",
                  }}
                >
                  <Form.Label>Select Subclass *</Form.Label>
                  {props.filteredAssetsData.select_subclass && (
                    // <Select
                    //   classNamePrefix="sortSelect"
                    //   isSearchable={false}
                    //   styles={customStyles}
                    //   options={modifiedData}
                    //   onChange={(e) => {
                    //     setAssetsDetails((prev) => ({
                    //       ...prev,
                    //       asset_sub_class_id: e.value,
                    //       asset_id: e.asset_id,
                    //     }));
                    //   }}
                    //   value={props.filteredAssetsData.select_subclass.filter(
                    //     (v) => v.value == assetsDetails.asset_sub_class_id
                    //   )}
                    // />
                    <input
                    name="asset_amount"
                    id="asset_amount_Real_estate"
                    className="shadow-none "
                    disabled="disabled"
                    value={subClass}
                    onChange={(e) => {
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                      });
                    }}
                    autoComplete="off"
                  />
                  )}
                </div>
              </div>
            </div>

            <div className="row d-flex align-items-center py-md-2">
              <div className="col-md-5">
                <div className="material">
                  <Form.Label> Investment From *</Form.Label>
                  <input
                    name="asset_amount"
                    id="Investment_Real_estate"
                    className="shadow-none "
                    disabled="disabled"
                    value={investmentFrom}
                    onChange={(e) => {
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                      });
                    }}
                    autoComplete="off"
                  />
                  {/* {familySortedData && (
                    <Select
                      classNamePrefix="sortSelect"
                      isSearchable={false}
                      styles={customStyles}
                      placeholder="Select..."
                      options={familySortedData}
                      onChange={(e) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_member_id: e.value,
                        })
                      }
                      value={familySortedData.filter(
                        (v) => v.value == assetsDetails.asset_member_id
                      )}
                    />
                  )} */}
                </div>
              </div>
              <div className="col-md-5 custom-input">
                <div className={`form-group mt-1 inputData`}>
                  <input
                    type="Number"
                    id="Invested_Value_Real_estate"
                    name="asset_amount"
                    className="shadow-none "
                    disabled="disabled"
                    value={multipleTotalInvestedValue}
                    onChange={(e) => {
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                      });
                    }}
                    autoComplete="off"
                  />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label for="name">Total Invested Value</label>
                </div>
              </div>
            </div>
            <div className="row py-md-2">
              <div className="col-md-5 custom-input ">
                <div className={`form-group mt-1 inputData`}>
                  <input
                    type="Number"
                    id="Total_Current_Value_Real_estate"
                    name="asset_amount"
                    className="shadow-none "
                    disabled="disabled"
                    value={multipleTotalCurrentValue}
                    onChange={(e) => {
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                      });
                    }}
                    autoComplete="off"
                  />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label for="name">Total Current Value</label>
                </div>
              </div>
              <div className="col-md-5 custom-input">
                <div className={`form-group mt-1 inputData`}>
                  <input
                    type="Number"
                    id="Asset_Value_Linked_Real_estate"
                    name="asset_amount"
                    className="shadow-none "
                    disabled="disabled"
                    value={multipleTotalAssetValueLinked}
                    onChange={(e) => {
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                      });
                    }}
                    autoComplete="off"
                  />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label for="name">Asset Value Linked</label>
                </div>
              </div>
            </div>

            <div className="row py-md-2">
              <div className="col-md-5 custom-input">
                <div className={`form-group mt-1 inputData`}>
                  <input
                    type="Number"
                    id="Asset_Available_for_Linkages"
                    name="asset_amount"
                    className="shadow-none "
                    disabled="disabled"
                    value={multipleTotalAssetValueForLinkages}
                    onChange={(e) => {
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                      });
                    }}
                    autoComplete="off"
                  />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label for="name">Asset Available for Linkages</label>
                </div>
              </div>
              <div className="col-md-5 custom-input">
                <div className={`form-group mt-1 inputData`}>
                  <input
                    type="Number"
                    id="Recurring_Investment"
                    name="asset_amount"
                    className="shadow-none "
                    disabled="disabled"
                    value={multipleTotalRecurringInvestment}
                    onChange={(e) => {
                      setAssetsDetails({
                        ...assetsDetails,
                        asset_amount: e.target.value.replace(/[^0-9.]/g, '').split('.').map((part, i) => i === 0 ? part.slice(0, 9) : part.slice(0, 2)).join('.'),
                      });
                    }}
                    autoComplete="off"
                  />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label for="name">Recurring Investment</label>
                </div>
              </div>
            </div>

            <div className="row py-md-2 mt-md-1">
              <div className="col-md-8">
                <div className="d-md-flex">
                  <Form.Label className=" ">
                    Consider This Asset In Automated Linkage*
                  </Form.Label>
                  <span className="info-hover-left-box ms-md-4">
                    <span Name="icon">
                      <img
                        alt="More information"
                        src="https://images.minty.co.in/static/assets/img/more_information.svg"
                      />
                    </span>
                    <span className="msg">
                      Select a goal below to map this investment with a goal of
                      your choice. Otherwise, Fintoo will link it automatically
                      with your high priority goal. In case, you do not wish to
                      utilize this investment for any goal, select "NO".
                    </span>
                  </span>
                  <div className="d-flex ms-md-4">
                    <div>No</div>
                    <Switch
                      onChange={(v) =>
                        setAssetsDetails({
                          ...assetsDetails,
                          asset_islinkable: v,
                        })
                      }
                      checked={assetsDetails.asset_islinkable}
                      className="react-switch px-2"
                      activeBoxShadow="0 0 2px 3px #042b62"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                      onColor="#042b62"
                      offColor="#042b62"
                    />
                    <div>Yes</div>
                  </div>
                </div>
              </div>
            </div>

            {assetsDetails.asset_islinkable == true && (
              <>
                <div className="row py-md-2">
                  <div className="col-md-8 mt-md-2">
                    <div className="d-md-flex">
                      <Form.Label className="link_asset_style">
                        Link This Investment Asset to Goal
                      </Form.Label>
                      <span
                        className="ms-md-4 info-hover-left-box float-right"
                        style={{
                          position: "relative !important",
                        }}
                      >
                        <span className="icon">
                          <img
                            alt="More information"
                            src="https://images.minty.co.in/static/assets/img/more_information.svg"
                          />
                        </span>
                        <span className="msg">
                          You can only assign goals which are prior to the end
                          date of the asset
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-md-2 mt-5">
                    <div className="material">
                      <div
                        className="m-0 btn-sm default-btn gradient-btn save-btn"
                        onClick={() => setGoalSelected(true)}
                      >
                        Select Goals
                      </div>
                      <br></br>
                      <br></br>

                      {selectedGoals ? (
                        <div
                          className="d-flex"
                          style={{ textAlign: "left!important" }}
                        >
                          <div>
                            <b>Selected Goals : </b>
                          </div>
                          <div className="ms-1">{selectedGoals}</div>
                        </div>
                      ) : (
                        ""
                      )}
                      {isGoalSelected ? (
                        <GoalsDropdown
                          setGoalSelected={setGoalSelected}
                          goals={goalData}
                          unchangedgoaldata={unchangedgoaldata}
                          closeModal={closeModal}
                          selectGoals={selectGoals}
                          selectedGoals={selectedGoals}
                          selectedGoalIdArray={selectedGoalIdArray}
                          selectedGoalsId={selectedGoalsId}
                          setPriorityArray={setPriorityArray}
                          selectedPriorityArray={selectedPriorityArray}
                          setAutoMatedGoal={setAutoMatedGoal}
                          isAutoMatedGoal={isAutoMatedGoal}
                          setGoalLink={setGoalLink}
                          asset_maturity_date={assetsDetails?.asset_mf_end_date}
                          type={"Asset"}
                          assetEditId={assetEditId}
                          isGoalFilter={
                            assetsDetails.asset_isrecurring == "1" ? "1" : "0"
                          }
                          isAssetRecurring={
                            assetsDetails.asset_isrecurring == "1" ? "1" : "0"
                          }
                        ></GoalsDropdown>
                      ) : (
                        ""
                      )}
                      <div className=""></div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="row py-2">
              <div className=" text-center">
                <div>
                  <div className="btn-container">
                    <div className="d-flex justify-content-center">
                      <Link
                        to={process.env.PUBLIC_URL + "/datagathering/goals"}
                      >
                        <div className="previous-btn form-arrow d-flex align-items-center">
                          <FaArrowLeft />
                          <span className="hover-text">&nbsp;Previous</span>
                        </div>
                      </Link>

                      {props.addForm && (
                        <button
                          type="button"
                          onClick={(e) => handleRealEstateSubmit(e)}
                          className="default-btn gradient-btn save-btn"
                        >
                          Save & Add More
                        </button>
                      )}
                      {props.updateForm && (
                        <div>
                          <button
                            type="button"
                            onClick={(e) => handleRealEstateCancel(e)}
                            className="default-btn gradient-btn save-btn"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            onClick={(e) => handleRealEstateUpdate(e)}
                            className="default-btn gradient-btn save-btn"
                          >
                            Update
                          </button>
                        </div>
                      )}

                      <div
                        className="next-btn form-arrow d-flex align-items-center"
                        onClick={() => 
                          {
                            ScrollToTop();
                            props.setTab("tab2")}
                          }
                      >
                        <span className="hover-text" style={{ maxWidth: 100 }}>
                          Continue&nbsp;
                        </span>
                        <FaArrowRight />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default Realestate;
