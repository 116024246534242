// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_txt__V3Jc6 {\n  border: 1px solid #959191;\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n  padding-left: 0.5rem;\n  padding-right: 0.5rem;\n  border-radius: 1rem;\n}\n.style_para__wn1w7:hover, .style_paraselect__oPJ5K {\n  font-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://./src/components/StopSipReason/style.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,oBAAoB;EACpB,oBAAoB;EACpB,qBAAqB;EACrB,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".txt {\n  border: 1px solid #959191;\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n  padding-left: 0.5rem;\n  padding-right: 0.5rem;\n  border-radius: 1rem;\n}\n.para:hover, .paraselect {\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"txt": "style_txt__V3Jc6",
	"para": "style_para__wn1w7",
	"paraselect": "style_paraselect__oPJ5K"
};
export default ___CSS_LOADER_EXPORT___;
