// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Style_ExpertSection__F137N{\n    width: 100%;\n    background-color: #f6f8fc;\n    margin-top: 6rem;\n}\n.Style_MainSection__hSvt-{\n    margin-top: 1rem;\n  display: flex;\n}\n@media screen and (max-width : 768px) {\n.Style_MainSection__hSvt-{\n  display: grid;\n}\n}", "",{"version":3,"sources":["webpack://./src/Pages/Expert/Style.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,gBAAgB;AACpB;AACA;IACI,gBAAgB;EAClB,aAAa;AACf;AACA;AACA;EACE,aAAa;AACf;AACA","sourcesContent":[".ExpertSection{\n    width: 100%;\n    background-color: #f6f8fc;\n    margin-top: 6rem;\n}\n.MainSection{\n    margin-top: 1rem;\n  display: flex;\n}\n@media screen and (max-width : 768px) {\n.MainSection{\n  display: grid;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ExpertSection": "Style_ExpertSection__F137N",
	"MainSection": "Style_MainSection__hSvt-"
};
export default ___CSS_LOADER_EXPORT___;
