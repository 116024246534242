// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_section-title__PhfYL {\n    color: #060606;\n    font-size: 2.3rem;\n    font-weight: 700;\n    line-height: 3.5rem;\n    text-align: center;\n    color: #2f3436;\n    font-family: \"Poppins\", Sans-serif;\n    letter-spacing: 1px;\n}\n.style_section-title2__ER\\+Jl{\n    color: #ffff;\n    font-size: 2.3rem;\n    font-weight: 700;\n    line-height: 3.5rem;\n    text-align: center;\n}\n/* ------------------------------------------------------------------------media query----------------------------------------------------------------- */\n\n@media only screen and (max-width: 600px) {\n    .style_section-title__PhfYL {\n        font-size: 1.3rem;\n    font-weight: 600;\n    line-height: 2rem;\n        padding: 0.7rem;\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/SectionHeader/style.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;IACd,kCAAkC;IAClC,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;AACA,yJAAyJ;;AAEzJ;IACI;QACI,iBAAiB;IACrB,gBAAgB;IAChB,iBAAiB;QACb,eAAe;IACnB;AACJ","sourcesContent":[".section-title {\n    color: #060606;\n    font-size: 2.3rem;\n    font-weight: 700;\n    line-height: 3.5rem;\n    text-align: center;\n    color: #2f3436;\n    font-family: \"Poppins\", Sans-serif;\n    letter-spacing: 1px;\n}\n.section-title2{\n    color: #ffff;\n    font-size: 2.3rem;\n    font-weight: 700;\n    line-height: 3.5rem;\n    text-align: center;\n}\n/* ------------------------------------------------------------------------media query----------------------------------------------------------------- */\n\n@media only screen and (max-width: 600px) {\n    .section-title {\n        font-size: 1.3rem;\n    font-weight: 600;\n    line-height: 2rem;\n        padding: 0.7rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-title": "style_section-title__PhfYL",
	"section-title2": "style_section-title2__ER+Jl"
};
export default ___CSS_LOADER_EXPORT___;
