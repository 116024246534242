// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_section-title__aqgLc span {\n    font-size: 2.4rem;\n    font-weight: 700;\n}\n\n@media (max-width: 767px) {\n    .style_section-title__aqgLc span {\n        font-size: 2.4rem;\n    }\n    \n}", "",{"version":3,"sources":["webpack://./src/components/BoldSectionHeader/style.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI;QACI,iBAAiB;IACrB;;AAEJ","sourcesContent":[".section-title span {\n    font-size: 2.4rem;\n    font-weight: 700;\n}\n\n@media (max-width: 767px) {\n    .section-title span {\n        font-size: 2.4rem;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-title": "style_section-title__aqgLc"
};
export default ___CSS_LOADER_EXPORT___;
