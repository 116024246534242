// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fhcheader_Fhcheader__twFKS {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 3rem;\n  align-items: center;\n}\n\n.fhcheader_subscribebtn__YhUeL {\n  border-radius: 46.506px;\n  border: 0.75px solid #042b62;\n  padding: 0.6rem 2rem;\n  background: transparent;\n  color: #042b62;\n  font-weight: 500;\n  transition: 0.5s;\n}\n.fhcheader_subscribebtn__YhUeL:hover {\n  background: #042b62;\n  transition: 0.5s;\n  color: #fff;\n  border: 1px solid #042b62;\n}\n@media only screen and (max-width: 600px) {\n  .fhcheader_Fhcheader__twFKS img {\n    width: 130px;\n  }\n  .fhcheader_subscribebtn__YhUeL {\n   font-size: .9rem;\n   padding: .4rem 1.3rem;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/FHC/FHCheader/fhcheader.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,4BAA4B;EAC5B,oBAAoB;EACpB,uBAAuB;EACvB,cAAc;EACd,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,yBAAyB;AAC3B;AACA;EACE;IACE,YAAY;EACd;EACA;GACC,gBAAgB;GAChB,qBAAqB;EACtB;AACF","sourcesContent":[".Fhcheader {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 3rem;\n  align-items: center;\n}\n\n.subscribebtn {\n  border-radius: 46.506px;\n  border: 0.75px solid #042b62;\n  padding: 0.6rem 2rem;\n  background: transparent;\n  color: #042b62;\n  font-weight: 500;\n  transition: 0.5s;\n}\n.subscribebtn:hover {\n  background: #042b62;\n  transition: 0.5s;\n  color: #fff;\n  border: 1px solid #042b62;\n}\n@media only screen and (max-width: 600px) {\n  .Fhcheader img {\n    width: 130px;\n  }\n  .subscribebtn {\n   font-size: .9rem;\n   padding: .4rem 1.3rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Fhcheader": "fhcheader_Fhcheader__twFKS",
	"subscribebtn": "fhcheader_subscribebtn__YhUeL"
};
export default ___CSS_LOADER_EXPORT___;
