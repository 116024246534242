const { Link } = require("react-router-dom");
import { useEffect, useState } from "react";
import styles from "./style.module.css";
import { DMF_GET_USER_PROFILE_STATE_URL } from "../../constants";
import { fetchEncryptData, getProfilePercentage } from "../../common_utilities";
import { fetchUserData } from "../../common_utilities";
import ReactApexChart from "react-apexcharts";
import Swal from "sweetalert2";

const ProfilePercentage = () => {
  //   const [profileStatus, setProfileStatus] = useState({});
  const [percent, setPercent] = useState(100);
  const [userData, setUserData] = useState("");
  const [donutData, setDonutData] = useState({
    series: [44, 55, 41, 17, 15],
    options: {
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    userProfileState();
  }, []);

  const userProfileState = async () => {
    // setIsLoading1(true);
    console.log("here 777");
    try {
      const userdata = await fetchUserData(true);
      console.log("nil 88", userdata);
      setUserData(userdata)
      const p = await getProfilePercentage(userdata);
      console.log("nil 77", p);
      if(p) {
        setPercent(p);
      } else {
        setPercent(0);
      }
    } catch (e) {
      console.log("natu err");
      console.error("natu err", e);
    }
  };
  // console.log("pppppppppp", percent,userData);

  return (
    <>
      {Number(percent) < 100 && (
        <Link
          to={process.env.PUBLIC_URL + "/direct-mutual-fund/profile"}
          style={{ textDecoration: "none", color: "#042b62" }}
          onClick={(e)=> {
            if(userData.is_minor === 'Y' && userData.bse_reg == "Y") {
              e.preventDefault();
              Swal.fire({
                title: "Note!",
                html: "Your account verification is under process. We'll notify you by email once it's completed.",
                icon: "info",
              });
            }
          }}
        >
          <div
            className={`d-flex align-items-center my-profile-complete ${styles["my-profile-complete"]}`}
          >
            <div className={styles["mpc-1"]}>
                {(userData.is_minor === 'Y' && userData.bse_reg == "Y") ? 'Authentication In Process' : 'Complete Your Profile'}
            </div>
            <div className={` align-items-center ${styles["mpc-2"]}`}>
              {Number(percent) === 0 ? <p>&nbsp;&nbsp;</p> : <p>{percent}%</p>}
              <img
                style={{ width: "1.2rem", height: "1.2rem" }}
                src={process.env.REACT_APP_STATIC_URL + "media/DMF/next.svg"}
              />
            </div>
          </div>
        </Link>
      )}
    </>
  );
};
export default ProfilePercentage;
