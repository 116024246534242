import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getParentUserId, getItemLocal, apiCall, loginRedirectGuest, setBackgroundDivImage } from "../../../common_utilities";
import { CHECK_SESSION, BASE_API_URL, imagePath } from "../../../constants";
import Styles from "./NDA.module.css";
import commonEncode from '../../../commonEncode';
import FintooLoader from '../../../components/FintooLoader';
import HideHeader from "../../../components/HideHeader";
import HideFooter from "../../../components/HideFooter";

function NDA() {
  const [userNDA, setNDA] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {

    document.getElementById('bg-about').style.background = 'url(' + imagePath +
      '/static/assets/img/bg/about.svg)' + " no-repeat right top";

    setBackgroundDivImage();

    document.body.classList.add("NDA-Page");

  }, []);

  useEffect(() => {
    getMemberList();

  }, []);
  const getMemberList = async () => {
    setIsLoading(true)
    try {
      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };

      let session_data = await apiCall(url, data, true, false);
      if (session_data.error_code == "100") {
        let url = BASE_API_URL + "restapi/expertflow/expertnda/";
        console.log('abb', session_data.data['plan_id']);
        if (session_data.data['plan_id']) {
          setIsLoading(false)

          if (session_data.data['plan_id'] == 29) {
            var nda_payload = { user_id: getParentUserId(), skip_accept_check: 1 }

            let nda_api_call = await apiCall(url, nda_payload, true, false);

            if (nda_api_call.error_code == "100") {

              setNDA(nda_api_call.data)
            }
          }
          else if (session_data.data['plan_id'] == 31) {
            var nda_payload = { user_id: getParentUserId() }
            console.log('data', nda_payload);
            let nda_api_call = await apiCall(url, nda_payload, true, false);

            if (nda_api_call.error_code == "100") {

              setNDA(nda_api_call.data)
            }
          }
        }

        else {
          setIsLoading(false)

          var nda_payload = { user_id: getParentUserId(), skip_accept_check: 1 }
          let nda_api_call = await apiCall(url, nda_payload, true, false);

          if (nda_api_call.error_code == "100") {

            setNDA(nda_api_call.data)
          }
        }


      }
      else {
        loginRedirectGuest()
      }




    } catch (e) {
      setIsLoading(false)

      console.log("err", e);
    }
  };
  return (

    <div className={`${Styles.NDApage}`}>
      <FintooLoader isLoading={isLoading} />

      <section className="basic-details-section bg-white ">
        <div className="container-fluid">
          <div className={`${Styles.backgrounddiv} background-div`}>
            <div className={`${Styles.bg} ${Styles.active}`} id="bg-about" />
            <div className="top-wrapper">
              <Link
                to={
                  process.env.PUBLIC_URL +
                  "/userflow/payment/"
                }
                className={`${Styles.backarrow}`}
              >
                <img
                  ng-src="https://stg.minty.co.in/static/userflow/img/icons/back-arrow.svg"
                  alt="Back Arrow"
                  src="https://stg.minty.co.in/static/userflow/img/icons/back-arrow.svg"
                />
              </Link>
            </div>
          </div>

          <div className="row h-100 align-items-center  justify-content-center">
            <div className="col-md-12">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <div className={`${Styles.ndacontent}`}>
                    <div
                      className={`${Styles.rContent}`}
                      id="rcontentt"
                      style={{ textAlign: "justify", maxWidth: "100%" }}
                    >

                      <div dangerouslySetInnerHTML={{ __html: userNDA }} />

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <HideFooter />
    </div>
  );
}

export default NDA;
