// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_mymodal__79Lcw .style_modal-dialog__FYrFG {\n  position: fixed;\n  margin: auto;\n  /* width: 320px; */\n  /* width: 512px; */\n  /* width: 1000px; */\n  height: 100%;\n  right: 0px;\n  max-width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/SideModal/style.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,UAAU;EACV,eAAe;AACjB","sourcesContent":[".mymodal .modal-dialog {\n  position: fixed;\n  margin: auto;\n  /* width: 320px; */\n  /* width: 512px; */\n  /* width: 1000px; */\n  height: 100%;\n  right: 0px;\n  max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mymodal": "style_mymodal__79Lcw",
	"modal-dialog": "style_modal-dialog__FYrFG"
};
export default ___CSS_LOADER_EXPORT___;
