import React from "react";
import Bondlist from "../../components/Bonds/Bondlist";

function index() {
  return (
    <div>
      <Bondlist />
    </div>
  );
}

export default index;
