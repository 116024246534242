import React from "react";
import ClientsSlide from "react-elastic-carousel";
function Clients() {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 },
  ];
  return (
    <div className="ClientSlideImg">
      <div className="p-5 container  w-100 m-auto">
        <ClientsSlide
          itemsToShow={1}
          enableAutoPlay
          autoPlaySpeed={1500}
          autoTabIndexVisibleItems={true}
          breakPoints={breakPoints}
        >
          <div>
            <div className="">
              <div className="video-cover">
                <div className="video-slider-content video">
                  <img
                    src="https://images.minty.co.in/static/assets/img/testimonial-image-1.png"
                    alt="Deep Bhandari Director/ Consultan: Voll Sante Fucntionals Foods"
                  />
                  <div className="video-play-icon">
                    <a
                      href=""
                      className="work-show"
                      data-id="yhLERXqE990"
                      data-video-type="YouTube"
                      data-video-url="https://www.youtube.com/watch?v=yhLERXqE990?loop=1&rel=0&autoplay=1&showinfo=0"
                      tabIndex={0}
                    >
                      <span />
                    </a>
                  </div>
                </div>
                <div className="testimonial-media w-100">
                  <img
                    src="https://images.minty.co.in/static/assets/itr/assets/img/client.svg"
                    alt="Client"
                    className="client-img"
                  />
                   <div className="client-info">
                    <div className="client-Name">Deep Bhandari</div>
                    <div>Director/ Consultant: Voll Sante Functionals Foods</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="">
              <div className="video-cover">
                <div className="video-slider-content video">
                  <img
                    src="https://images.minty.co.in/static/assets/img/testimonial-image-1.png"
                    alt="Deep Bhandari Director/ Consultan: Voll Sante Fucntionals Foods"
                  />
                  <div className="video-play-icon">
                    <a
                      href=""
                      className="work-show"
                      data-id="yhLERXqE990"
                      data-video-type="YouTube"
                      data-video-url="https://www.youtube.com/watch?v=yhLERXqE990?loop=1&rel=0&autoplay=1&showinfo=0"
                      tabIndex={0}
                    >
                      <span />
                    </a>
                  </div>
                </div>
                <div className="testimonial-media w-100">
                  <img
                    src="https://images.minty.co.in/static/assets/itr/assets/img/client.svg"
                    alt="Client"
                    className="client-img"
                  />
                  <div className="client-info">
                    <div className="client-Name">Deep Bhandari</div>
                    <div>Director/ Consultant: Voll Sante Functionals Foods</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="">
              <div className="video-cover">
                <div className="video-slider-content video">
                  <img
                    src="https://images.minty.co.in/static/assets/img/testimonial-image-1.png"
                    alt="Deep Bhandari Director/ Consultan: Voll Sante Fucntionals Foods"
                  />
                  <div className="video-play-icon">
                    <a
                      href=""
                      className="work-show"
                      data-id="yhLERXqE990"
                      data-video-type="YouTube"
                      data-video-url="https://www.youtube.com/watch?v=yhLERXqE990?loop=1&rel=0&autoplay=1&showinfo=0"
                      tabIndex={0}
                    >
                      <span />
                    </a>
                  </div>
                </div>
                <div className="testimonial-media w-100">
                  <img
                    src="https://images.minty.co.in/static/assets/itr/assets/img/client.svg"
                    alt="Client"
                    className="client-img"
                  />
                   <div className="client-info">
                    <div className="client-Name">Deep Bhandari</div>
                    <div>Director/ Consultant: Voll Sante Functionals Foods</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="">
              <div className="video-cover">
                <div className="video-slider-content video">
                  <img
                    src="https://images.minty.co.in/static/assets/img/testimonial-image-1.png"
                    alt="Deep Bhandari Director/ Consultan: Voll Sante Fucntionals Foods"
                  />
                  <div className="video-play-icon">
                    <a
                      href=""
                      className="work-show"
                      data-id="yhLERXqE990"
                      data-video-type="YouTube"
                      data-video-url="https://www.youtube.com/watch?v=yhLERXqE990?loop=1&rel=0&autoplay=1&showinfo=0"
                      tabIndex={0}
                    >
                      <span />
                    </a>
                  </div>
                </div>
                <div className="testimonial-media w-100">
                  <img
                    src="https://images.minty.co.in/static/assets/itr/assets/img/client.svg"
                    alt="Client"
                    className="client-img"
                  />
                   <div className="client-info">
                    <div className="client-Name">Deep Bhandari</div>
                    <div>Director/ Consultant: Voll Sante Functionals Foods</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="">
              <div className="video-cover">
                <div className="video-slider-content video">
                  <img
                    src="https://images.minty.co.in/static/assets/img/testimonial-image-1.png"
                    alt="Deep Bhandari Director/ Consultan: Voll Sante Fucntionals Foods"
                  />
                  <div className="video-play-icon">
                    <a
                      href=""
                      className="work-show"
                      data-id="yhLERXqE990"
                      data-video-type="YouTube"
                      data-video-url="https://www.youtube.com/watch?v=yhLERXqE990?loop=1&rel=0&autoplay=1&showinfo=0"
                      tabIndex={0}
                    >
                      <span />
                    </a>
                  </div>
                </div>
                <div className="testimonial-media w-100">
                  <img
                    src="https://images.minty.co.in/static/assets/itr/assets/img/client.svg"
                    alt="Client"
                    className="client-img"
                  />
                   <div className="client-info">
                    <div className="client-Name">Deep Bhandari</div>
                    <div>Director/ Consultant: Voll Sante Functionals Foods</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ClientsSlide>
      </div>
    </div>
  );
}

export default Clients;
