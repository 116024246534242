import React from "react";

function BooKMarkStock(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "17px"}
      height={props.height || '17px'}
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
    >
      <path
        fill="#c3c3c3"
        d="M391.416 0H120.584c-17.778 0-32.242 14.464-32.242 32.242v460.413A19.345 19.345 0 00107.687 512a19.34 19.34 0 0010.169-2.882l138.182-85.312 138.163 84.693a19.307 19.307 0 0019.564.387 19.338 19.338 0 009.892-16.875V32.242C423.657 14.464 409.194 0 391.416 0zm-6.449 457.453l-118.85-72.86a19.361 19.361 0 00-20.28.032l-118.805 73.35V38.69h257.935v418.763z"
        data-original="#000000"
      ></path>
    </svg>
  );
}

export default BooKMarkStock;
