import { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import FintooBackButton from "../../HTML/FintooBackButton";
import commonEncode from "../../../commonEncode";
import * as constClass from "../../../constants";
import axios from "axios";
import moment from "moment";
import { fetchEncryptData, apiCall, getUserId, getItemLocal } from "../../../common_utilities";
import { useNavigate } from "react-router-dom";
const TwoFactorOtpModal = (props) => {
  const timer = useRef({ obj: null, counter: 120, default: 120 });
  const [count, setCount] = useState(120);
  const [useremail, setuseremail] = useState("");
  const [usermobile, setusermobile] = useState("");
  const [generateotp, setGeneratedSmsOTP] = useState("");
  const [generatedemailotp, setGeneratedEmailOTP] = useState("");
  const [otpInput, setOtpInput] = useState("");
  const [validOtp, setValidOtp] = useState(true);
  const navigate = useNavigate();
  const [nomineeflag ,setnomineeflag] = useState("");
  const [username  , SetName] =useState("");

  useEffect(() => {
    onLoadInIt();
    fetchMail();
    fetchSms();
  }, [useremail]);

  useEffect(() =>{
    var count = commonEncode.decrypt(localStorage.getItem('cart_amt'))
    console.log(count,"helooo")
  })

  const randomOTP = Math.floor(Math.random() * 90000) + 10000;
  const handleOtpChange = (e) => {
    if (e.target.value.length > 5) {
      setOtpInput("");
      setValidOtp(false);
    } else {
      setOtpInput(e.target.value);
      setValidOtp(true);
    }
  };

  const submitOtp = () => {
    if (generatedemailotp != otpInput) {
      setValidOtp(false);
    } else {
      setValidOtp(true);
      if(nomineeflag == null || nomineeflag == ""){
      updatenominee();
      }
    //  navigate(`${process.env.PUBLIC_URL}/direct-mutual-fund/paymentsucess`);
      props.onSubmit();

    }
  };
  const updatenominee = async  () => {
    try {
      var res = await apiCall(constClass.DMF_UPDATENOMINEE_API_URL, {
        user_id: getUserId(),
        is_direct: constClass.IS_DIRECT,
        is_authenticated : "1"
      });

    } catch (e) {
      console.error(e);
    }

  };

  const fetchSms = async () => {
    var sms_api_id = "fintoo_otp";
    var otp = randomOTP;
    setGeneratedSmsOTP(otp);
    var msg = "Greetings from Fintoo! Your OTP verification code is " + otp;
    var whatsapptext =
      "Greetings from Fintoo! Your OTP verification code is : " + otp;
      console.log('whatsapptext', whatsapptext);
    var urlsms = {
      mobile : usermobile,
      otp: otp,
      amount:getItemLocal('lumpsum_amount'),
      // commonEncode.decrypt(localStorage.getItem('cart_amt')),
      user_name:username,
      key:"multiple_fund_transaction",
      is_direct :constClass.IS_DIRECT,
      whatsapptext : whatsapptext,
      msg:msg
     
    };
    var data2 = commonEncode.encrypt(JSON.stringify(urlsms));
    var config1 = {
      method: "post",
      url: constClass.DMF_SENDSMS_API_URL,
      data: data2,
    };

    var res = await axios(config1);
    var response = commonEncode.decrypt(res.data);
  };

  const fetchMail = async () => {
    setOtpInput("");
    var otp = randomOTP;
    setGeneratedEmailOTP(otp);
    var urlmail = {
      userdata: {
        to: useremail,
      },
      subject: "Fintoo - Your one time password",
      template: "otp_message_template.html",
      contextvar: { otp: otp ,name:username},
    };
 
    var data = commonEncode.encrypt(JSON.stringify(urlmail));
    var config = {
      method: "post",
      url: constClass.DMF_SENDMAIL_API_URL,
      data: data,
    };

    var res = await axios(config);
    var response = commonEncode.decrypt(res.data);
  };

  const onLoadInIt = async () => {
    try {
      var userid = getUserId();
      var form_data_user = { user_id: userid };
      var data = commonEncode.encrypt(JSON.stringify(form_data_user));

      var config_list = {
        method: "post",
        url: constClass.DMF_GETUSERDETAILS_API_URL,
        data: data,
      };

      var res = await axios(config_list);
      var response = commonEncode.decrypt(res.data);
      let responseobj = JSON.parse(response);
      let user_data = responseobj.data;
      // console.log(user_data.name,"check")
      SetName(user_data.name)
      setnomineeflag(user_data.is_authenticated);
      setuseremail(user_data.email);
      setusermobile(user_data.mobile);
    } catch (e) {}
  };

  useEffect(() => {
    timer.current.counter = timer.current.default;
    startTimer();
  }, []);

  const startTimer = () => {
    timer.current.obj = setInterval(() => {
      if (timer.current.counter > 0) {
        timer.current.counter = timer.current.counter - 1;
        setCount(timer.current.counter);
      } else {
        clearInterval(timer.current.obj);
        timer.current.counter = timer.current.default;
      }
    }, 1000);
  };

  var props_data = props.value;

  return (
    <>
      <Modal.Header className="py-3">
        <FintooBackButton onClick={() => props.onBack()} />
        <div className="modal-title">Two Factor Authentication</div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <div className="modal-whitepopup-box-item grey-color border-top-0 text-center">
              <p>Please enter OTP sent to</p>
              <p><strong>{useremail}</strong></p>
              <p><strong>{usermobile}</strong></p>
            </div>

            <div className="text-center p-4">
              <input
                type="text"
                maxLength={5}
                minLength={5}
                placeholder="Enter OTP here"
                className="bottom-border-input w-50 text-center grey-color"
                value={otpInput}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => handleOtpChange(e)}
              />
            </div>

            <div className="text-center p-4 grey-color">
              {count == 0 && (
                <p
                  className="pointer blue-color"
                  onClick={() => {
                    startTimer();
                    fetchSms();
                    fetchMail();
                    setValidOtp(true);
                  }}
                >
                  Resend OTP
                </p>
              )}
              {count > 0 && (
                <p>
                  Resend OTP in{" "}
                  <strong>
                    {moment().startOf("day").seconds(count).format("mm:ss")}
                  </strong>
                </p>
              )}
              {validOtp ? <> </> : <p className="red-color">Invalid OTP</p>}
            </div>

            <div
              className="mt-3 switch-fund-btn mobile-bottom-button"
              onClick={() => {
                submitOtp();
                // localStorage.removeItem('lumpsum')
              }}
            >
              Submit
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};
export default TwoFactorOtpModal;
