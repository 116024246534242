import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const ReCaptchaComponent = forwardRef(({ onChange }, ref) => {
    const recaptchaRef = useRef();

    useImperativeHandle(ref, () => ({
        reset: () => {
            recaptchaRef.current.reset();
        }
    }));

    return (
        <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6Ler4xgqAAAAABdiBfx2GGRJTeU25xT9PAMyPkh_"
            onChange={onChange}
        />
    );
});

export default ReCaptchaComponent;
