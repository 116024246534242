// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_radio__fWf3u {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    /* padding-left: .8rem; */\n    padding-right: .8rem;\n}\n.style_radio__fWf3u img {\n    width: 16px;\n    height: 16px;\n}\n.style_radio__fWf3u div {\n    padding-left: 4px;\n    padding-top: 3px;\n    font-size: 13px;\n}", "",{"version":3,"sources":["webpack://./src/components/FintooRadio2/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,yBAAyB;IACzB,oBAAoB;AACxB;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".radio {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    /* padding-left: .8rem; */\n    padding-right: .8rem;\n}\n.radio img {\n    width: 16px;\n    height: 16px;\n}\n.radio div {\n    padding-left: 4px;\n    padding-top: 3px;\n    font-size: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio": "style_radio__fWf3u"
};
export default ___CSS_LOADER_EXPORT___;
