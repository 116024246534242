// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_section__DN5DT {\n    padding: 2rem;\n}\n\n\n@media (min-width: 768px) {\n    .style_cardBox__GHoyf {\n        width: 85%;\n        margin: auto;\n    }\n    .style_title__BV5Hu {\n        font-size: 4rem;\n        font-weight: 600;\n    }\n    .style_section__DN5DT {\n        padding: 4rem;\n    }\n    .style_cardBox__GHoyf{\n        display: grid;\n        place-items: center;\n    }\n    .style_cardBox__GHoyf img{\n        width: 800px;\n    }\n    .style_subtitle__xyEMn {\n        font-weight: 600;\n        font-size: 2rem;\n    }\n}\n@media (max-width: 480px) {\n    .style_cardBox__GHoyf{\n        width: 100%;\n    }\n    .style_cardBox__GHoyf img{\n        width: 100%;\n        height: 100%;\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/ITRHowToFile/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;;AAGA;IACI;QACI,UAAU;QACV,YAAY;IAChB;IACA;QACI,eAAe;QACf,gBAAgB;IACpB;IACA;QACI,aAAa;IACjB;IACA;QACI,aAAa;QACb,mBAAmB;IACvB;IACA;QACI,YAAY;IAChB;IACA;QACI,gBAAgB;QAChB,eAAe;IACnB;AACJ;AACA;IACI;QACI,WAAW;IACf;IACA;QACI,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".section {\n    padding: 2rem;\n}\n\n\n@media (min-width: 768px) {\n    .cardBox {\n        width: 85%;\n        margin: auto;\n    }\n    .title {\n        font-size: 4rem;\n        font-weight: 600;\n    }\n    .section {\n        padding: 4rem;\n    }\n    .cardBox{\n        display: grid;\n        place-items: center;\n    }\n    .cardBox img{\n        width: 800px;\n    }\n    .subtitle {\n        font-weight: 600;\n        font-size: 2rem;\n    }\n}\n@media (max-width: 480px) {\n    .cardBox{\n        width: 100%;\n    }\n    .cardBox img{\n        width: 100%;\n        height: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "style_section__DN5DT",
	"cardBox": "style_cardBox__GHoyf",
	"title": "style_title__BV5Hu",
	"subtitle": "style_subtitle__xyEMn"
};
export default ___CSS_LOADER_EXPORT___;
