import React, { useState, useRef } from "react";
import { useEffect } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import QuizeRadio from "../../components/HTML/QuizRadio";
import { GrEdit } from "react-icons/gr";
import { AiFillDelete } from "react-icons/ai";
import SlidingPanel from "react-sliding-side-panel";
import moment from "moment";
import Switch from "react-switch";
import { Row, Modal } from "react-bootstrap";
import DatagatherLayout from "../../components/Layout/Datagather";
import Slider from "../../components/HTML/Slider";
import "rc-slider/assets/index.css";
import { BsPencilFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import ReactDatePicker from "../../components/HTML/ReactDatePicker/ReactDatePicker";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DGstyles from "./DG.module.css";
import {
  ADVISORY_ADD_ASSETS_API,
  ADVISORY_DELETE_ASSETS_API,
  ADVISORY_GET_ASSETS_API,
  ADVISORY_GET_CRYPTO_DATA,
  ADVISORY_FETCH_CONNECTED_BROKER,
  ADVISORY_GET_EQUITY_SHARES_DATA,
  ADVISORY_GET_FP_GOALS_DATA,
  ADVISORY_GET_INCOME_DATA,
  ADVISORY_GET_SCHEME_DATA,
  ADVISORY_UPDATE_ASSETS_API,
  ADVISORY_UPDATE_MULTI_ASSETS_API,
  ADVISORY_UPLOAD_ECASH_DOCUMENT,
  BASE_API_URL,
  CHECK_SESSION,
  GET_MEMBER_LIST,
  imagePath,
  DMF_ADD_EDIT_REALESTATE,
  ADVISORY_UPDATE_MULTIPLE_GOALS_API,
  DELETE_OTHER_INVESTMENTS,
  DMF_ADD_EDIT_GOLD,
  DMF_ADD_EDIT_LIQUID,
  ADVISORY_CALCULATE_EPF_MATURITY_AMOUNT,
  ADVISORY_CHECK_PROFILE_STATUS_API_URL,
  ADVISORY_GET_US_EQUITY_API_URL,
  ADVISORY_GET_BANK_DATA,
  ADVISORY_DELETE_BANK_DATA,
} from "../../constants";
import {
  apiCall,
  getItemLocal,
  getParentUserId,
  getSchemeDataStorage,
  getUserId,
  indianRupeeFormat,
  loginRedirectGuest,
  setBackgroundDivImage,
  goalfilter,
  fv,
  fetchEncryptData,
  getprofilestatus,
  fetchData,
  getParentFpLogId,
} from "../../common_utilities";
import { CommentIcon } from "evergreen-ui";
import commonEncode from "../../commonEncode";
import { toast } from "react-toastify";
import { Buffer } from "buffer";
import * as toastr from "toastr";
import "toastr/build/toastr.css";
import CloseFilter from "../../Assets/Images/close.png";
import FintooCheckbox from "../../components/FintooCheckbox/FintooCheckbox";
import FintooSubCheckbox from "../../components/FintooCheckbox/FintooSubCheckbox";
import Others from "./AssetsLibDG/Others";
import Realestate from "./AssetsLibDG/Realestate";
import Liquid from "./AssetsLibDG/Liquid";
import Alternate from "./AssetsLibDG/Alternate";
import AssetGold from "./AssetsLibDG/AssetGold";
import AssetDebt from "./AssetsLibDG/AssetDebt";
import AssetEquity from "./AssetsLibDG/AssetEquity";
import SimpleReactValidator from "simple-react-validator";
import AssetOthers from "./AssetsLibDG/AssetOthers";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Liabilities from "./AssetsLibDG/Liabilities";
import customStyles from "../../components/CustomStyles";
import FintooLoader from "../../components/FintooLoader";
import AssetLibstyle from "./AssetLib.module.css"
import { faL } from "@fortawesome/free-solid-svg-icons";
import { IoCloseCircleOutline } from "react-icons/io5";
import GoalsDropdown from "../../components/GoalsDropdown/GoalDropdown";
import PortfolioBalance from "../../components/PortfolioBalance";
import GoalSelectionModal from "../../components/GoalsDropdown/GoalSelectionModal";
const categories = [
  {
    title: "All",
    id: 0,
    child: [],
  },
  {
    title: "Equity",
    id: 29,
    child: [
      { title: "ESOP`S", id: 34 },
      { title: "PMS", id: 32 },
      { title: "Equity Mutual Funds", id: 31 },
      { title: "Unlisted / AIF Equity", id: 33 },
      { title: "Equity Shares", id: 30 },
      { title: "US Equity", id: 123 },
      { title: "Future & Options", id: 35 },
      { title: "Others", id: 74 },
    ],
  },
  {
    title: "Shares",
    id: 1,
    child: [
      { title: "All", id: "All" },
      { title: "External", id: "External" },
      { title: "Manual", id: "Manual" },
    ],
  },
  {
    title: "Gold",
    id: 42,
    child: [
      { title: "Gold ETF", id: 70 },
      { title: "Gold Mutual Fund", id: 71 },
      { title: "Physical Gold", id: 69 },
      { title: "Sovereign Gold Bonds", id: 72 },
      { title: "Others", id: 73 },
    ],
  },
  {
    title: "Mutual Funds",
    id: 2,
    child: [
      { title: "All", id: "All" },
      { title: "Internal", id: "Internal" },
      { title: "External", id: "External" },
      { title: "Manual", id: "Manual" },
    ],
  },
  {
    title: "Liquid",
    id: 40,
    child: [
      { title: "Bank Balance", id: 61 },
      // { title: "Cash Balance", id: 60 },
      { title: "Liquid Funds", id: 62 },
      { title: "Others", id: 63 },
    ],
  },
  {
    title: "Alternate",
    id: 41,
    child: [
      { title: "Art Investment", id: 64 },
      { title: "Vintage/Luxury Cars", id: 66 },
      { title: "Commodity", id: 36 },
      { title: "Cryptocurrency", id: 119 },
      { title: "Currency", id: 37 },
      { title: "Others", id: 67 },
    ],
  },
  {
    title: "Real Estate",
    id: 39,
    child: [
      { title: "Agricultural Land", id: 53 },
      { title: "Under Construction Property", id: 55 },
      { title: "Commercial", id: 52 },
      { title: "Land", id: 56 },
      { title: "Residential Premises", id: 51 },
      { title: "Others", id: 59 },
    ],
  },
  {
    title: "Debt",
    id: 38,
    child: [
      { title: "Debentures", id: 80 },
      { title: "Debt Mutual Funds", id: 77 },
      { title: "EPF", id: 117 },
      { title: "Fixed Deposit", id: 75 },
      { title: "Gratuity", id: 82 },
      { title: "Govt Bonds", id: 79 },
      { title: "Govt. Schemes", id: 76 },
      { title: "NPS", id: 118 },
      { title: "NSC/KVP", id: 84 },
      { title: "Pension Schemes", id: 85 },
      { title: "Post Office Scheme", id: 78 },
      { title: "PPF/GPF/VPF", id: 81 },
      { title: "Sukanya Samriddhi Yojana", id: 86 },
      { title: "Recurring Deposit", id: 120 },
      { title: "Others", id: 87 },
    ],
  },
  {
    title: "Others",
    id: 115,
    child: [],
  },
];

const upload_options = [
  {
    title: "CDSL",
    img:
      imagePath +
      "/static/assets/img/assets-liabilities/alternate/assets_alternate_wine_investment.svg",
  },
  {
    title: "NSDL",
    img:
      imagePath +
      "/static/assets/img/assets-liabilities/alternate/assets_alternate_wine_investment.svg",
  },
];

const AssetsLiabilities = () => {
  const [assetsData, setAssetsData] = useState([]);
  const asset_data = useRef([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tab, setTab] = useState("tab1");
  const [subtab, setSubTab] = useState("individual");
  const [category, setCategory] = useState(0);
  const [selectedSubcategory, setSelectedSubcategory] = useState(["All"]);
  const [selectedMember, setSelectedMember] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Alternate");
  const [selectedOption1, setSelectedOption1] = useState("Business Loan");
  const [selectedSubOption, setSelectedSubOption] = useState("Art Investment");
  const [sliderValue, setSliderValue] = useState(7);
  const cntRef = useRef(null);
  const [openPanel, setOpenPanel] = useState(false);
  const [sidePanelWidth, setSidePanelWidth] = useState(30);
  const [showview, setShowView] = useState(true);
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState("");
  const [, setForceUpdate] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const userid = getParentUserId();
  const fpLogId = getParentFpLogId();
  const assetsUpdated = useSelector((state) => state.assetsUpdated);
  const [fetched, setFetched] = useState(false);
  const [viewmore1, setViewMore1] = useState(0);
  const [viewmore2, setViewMore2] = useState(0);
  const [assetIsLinkable, setAssetIsLinkable] = useState({});
  const [selectedgoalsdata, setSelectedGoalsData] = useState(false);
  const [selectedgoalsmodal, setSelectedGoalsmodal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [deletetoggle, setDeleteToggle] = useState(false)
  const [filteredAssetsDataCheck, setfilteredAssetsDataCheck] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const [isOpenGoalSelectionModal, setIsOpenGoalSelectionModal] = useState(false);
  // const removefixheader = useRef(null);
  const [scroll, setScroll] = useState(false);
  const { v4: uuidv4 } = require("uuid");
  const [cdslBrokers, setCDSLBrokers] = useState([]);
  const [selectedBrokers, setSelectedBrokers] = useState([]);
  const [showBrokers, setShowBrokers] = useState(false);
  const [equityHoldingsData, setEquityHoldingsData] = useState([]);
  const [equityFilteredHoldings, setEquityFilteredHoldings] = useState([]);
  const [filterBroker, setFilterBroker] = useState(false);


  // const [removefixheaderVisible, setRemovefixheaderVisible] = useState(false);
  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     setScroll(window.scrollY > 116);
  //   });
  // }, []);

  useEffect(() => {
    const handleScroll2 = () => {
      const removefixheader = document.querySelector('.removefixheaderasset');
      const FixdgsubHeader = document.querySelector('.FixdgHeaderasset');
      const FixdgmainHeader = document.querySelector('.DGheaderFix');
      const scrollPosition = window.scrollY;
      if (removefixheader && FixdgsubHeader && FixdgmainHeader) {
        const removefixheaderRect = removefixheader.getBoundingClientRect();
        const threshold = 70;
        if (removefixheaderRect.top > 170 && scrollPosition > 50) {
          setScroll(true)
          FixdgsubHeader.classList.add("DGsubheaderFixasset");
          if (removefixheaderRect.top <= threshold) {
            FixdgmainHeader.classList.remove("DGmainHeaderFix");
          }
          else {
            FixdgmainHeader.classList.add("DGmainHeaderFix");
          }
        } else {
          setScroll(false);
          FixdgsubHeader.classList.remove("DGsubheaderFixasset")
          FixdgmainHeader.classList.remove("DGmainHeaderFix");
        }
      }
    };

    window.addEventListener('scroll', handleScroll2);

    return () => {
      window.removeEventListener('scroll', handleScroll2);

    };
  }, []);
  useEffect(() => {
    const handleScroll3 = () => {
      const removefixheader2 = document.querySelector('.removefixheaderasset2');
      const FixdgsubHeader2 = document.querySelector('.FixdgHeaderasset2');
      const FixdgmainHeader2 = document.querySelector('.DGheaderFix2');
      const scrollPosition = window.scrollY;
      if (removefixheader2 && FixdgsubHeader2 && FixdgmainHeader2) {
        const removefixheaderRect2 = removefixheader2.getBoundingClientRect();
        const threshold2 = 70;
        if (removefixheaderRect2.top > 180 && scrollPosition > 50) {
          setScroll(true)
          FixdgsubHeader2.classList.add("DGsubheaderFixasset");

          if (removefixheaderRect2.top <= threshold2) {
            FixdgmainHeader2.classList.remove("DGmainHeaderFix");
          }
          else {
            FixdgmainHeader2.classList.add("DGmainHeaderFix");
          }
        } else {
          setScroll(false);
          FixdgsubHeader2.classList.remove("DGsubheaderFixasset");
          FixdgmainHeader2.classList.remove("DGmainHeaderFix");
        }
      }
    };

    window.addEventListener('scroll', handleScroll3);

    return () => {
      window.removeEventListener('scroll', handleScroll3);

    };
  }, []);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('success');
    const myEpfParam = urlParams.get('isepf');
    const myStockParam = urlParams.get('isstocks');
    const myLiabilityParam = urlParams.get('isliability');
    // const myCreditScoreParam = urlParams.get('isCreditScore');
    if (myParam == 1) {
      setIsOpen(true);
    }
    if (myEpfParam == 1) {
      if (urlParams.get('epfamount')) {
        setModalData(prev => ({ ...prev, epfamount: Number(urlParams.get('epfamount')) }));
      }
    }
    else if (myStockParam == 1) {
      if (urlParams.get('stocksamount')) {
        setModalData(prev => ({ ...prev, stocksamount: Number(urlParams.get('stocksamount')) }));
      }
    }
    else if (myLiabilityParam == 1) {
      if (urlParams.get('liabilityamount')) {
        setModalData(prev => ({ ...prev, liabilityamount: Number(urlParams.get('liabilityamount')) }));
        setIsFetched(true);
        setTab("tab2");
        // setSelectedOption("Fetch Loan");
      }
    }
    else {
      if (urlParams.get('amount')) {
        setModalData(prev => ({ ...prev, amount: Number(urlParams.get('amount')) }));
      }
    }

    document.body.classList.add("dg-layout");
    document.getElementById("bg-assets").style.background =
      "url(" +
      imagePath +
      "/static/assets/img/bg/assets.svg)" +
      " no-repeat right top";

    setBackgroundDivImage();

    return () => {
      document.body.classList.remove("dg-layout");
    };
  }, []);

  useEffect(() => {
    if (assetsUpdated) {
      getAssetData(fpLogId, 0);
      setAddForm(true);
      setUpdateForm(false);
      dispatch({
        type: "ASSETS_UPDATE",
        payload: false,
      });
    }
  }, [assetsUpdated]);

  useEffect(() => {
    if (selectedSubcategory.indexOf(30) > -1 && cdslBrokers.length > 0) {
      setShowBrokers(true);
    } else {
      setShowBrokers(false);
    }
  }, [selectedSubcategory]);

  const handleFilterBroker = (value) => {
    setFilterBroker(value);
  }

  useEffect(() => {
    setTimeout(() => {
      setCurrentUrl(location.pathname);
    }, 100);
  }, [location]);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (!userid) {
      loginRedirectGuest();
    }
    // getCDSLBrokers();    
  }, []);

  const getCDSLBrokers = async (assetsData) => {

    const rid = uuidv4();
    const ts = new Date().toISOString();
    const loginPayload = {
      header: {
        rid: rid,
        ts: ts,
        channelId: "finsense",
      },
      body: {
        userId: "channel@fintoo",
        password: "85a333fb49044c7e91611a0d962ff8ba",
      },
    };

    const url =
      "https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/User/Login";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginPayload),
    };

    const loginResponse = await fetch(url, options);

    if (loginResponse.status === 200) {
      const responseData = await loginResponse.json();
      const token = responseData.body.token;
      try {
        // let fir_request_payload = {
        //   header: {
        //     rid: rid,
        //     ts: ts,
        //     channelId: "finsense",
        //   },
        //   body: {
        //     custId: cust_id,
        //     consentId: consent_id,
        //     consentHandleId: consent_handle,
        //     dateTimeRangeFrom: daterange_from,
        //     dateTimeRangeTo: daterange_to,
        //   },
        // };

        // const requestBody = JSON.stringify(fir_request_payload);

        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          // body: requestBody,
        };

        let brokersResponse = await fetch(
          "https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/fips/brokers",
          requestOptions
        )

        if (brokersResponse.status === 200) {
          brokersResponse = await brokersResponse.json();
          let brokersList = brokersResponse.body;
          // let brokersList = brokersResp;
          let assetBrokerList = [];
          let assetList = assetsData;

          assetList.forEach((item) => {
            if ((item.asset_ecas_type == "cdsldemat") && item.asset_broker_id != 0) {
              // slice demat id from 3 to 7
              // let dematId = assetList['demat_id'].slice(3, 8);
              // let brok = brokersList.filter(v => console.log("bbb", v, v["brokerId"], item.asset_broker_id, typeof item.asset_broker_id));
              let broker = brokersList.filter(v => parseInt(v["brokerId"]) == item.asset_broker_id);
              // console.log("broker", broker[0], broker[0].brokerId);
              let existBroker = assetBrokerList.filter(v => v.brokerId == broker[0]["brokerId"]);
              if (existBroker.length == 0) {
                assetBrokerList.push({ 'brokerId': broker[0]["brokerId"], "brokerName": broker[0]["brokerName"] });
              }
            }
          });
          setCDSLBrokers(assetBrokerList);
        }
        else {
          toastr.options.positionClass = "toast-bottom-left";
          toastr.error("Could not fetch brokers list");
        }

        // .then((response) => response.json())
        // .then((data) => {
        //   let brokersList = data;
        //   let assetList = [];
        //   // if (data.body && data.body.sessionId) {
        //   //   setUserSessionId(data.body.sessionId);
        //   //   FIStatus(
        //   //     data.body.sessionId,
        //   //     cust_id,
        //   //     consent_handle,
        //   //     consent_id,
        //   //     token,
        //   //     daterange_from,
        //   //     daterange_to
        //   //   );
        //   // } else {
        //   //   // setIsLoading(false);
        //   //   toastr.options.positionClass = "toast-bottom-left";
        //   //   toastr.error("Could not fetch brokers list");
        //   // }
        // })
        // .catch((error) => {
        //   console.log("Error:", error);
        // });
      } catch (e) {
        console.log("Error", e);
      }
    }
  }


  const options = [
    {
      title: "Alternate",
      id: 41,
      img:
        imagePath +
        "	/static/assets/img/assets-liabilities/assets_alternate.svg",
      options: [
        {
          title: "Art Investment",
          id: 64,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/alternate/assets_alternate_art_investment.svg",
        },
        {
          title: "Commodity",
          id: 36,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/equity/assets_equity_commodity.svg",
        },
        {
          title: "Cryptocurrency",
          id: 119,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_others.svg",
        },
        {
          title: "Currency",
          id: 37,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/equity/assets_equity_currency.svg",
        },
        {
          title: "Vintage/ Luxury Cars",
          id: 66,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/alternate/assets_alternate_vintage_car.svg",
        },
        {
          title: "Others",
          id: 67,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_others.svg",
        },
        // {
        //   title: "Multi Assets Linkage",
        //   id: 125,
        //   img:
        //     imagePath +
        //     "/static/assets/img/assets-liabilities/debt/assets_debt_others.svg",
        // },
      ],
    },
    {
      title: "Debt",
      id: 38,
      img:
        imagePath +
        "/static/assets/img/assets-liabilities/assets_debt.svg",
      options: [
        {
          title: "Debentures",
          id: 80,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_debenture.svg",
        },
        {
          title: "Debt Mutual Funds",
          id: 77,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_mutual_fund.svg",
        },
        {
          title: "EPF",
          id: 117,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_PPF_EPF.svg",
        },
        {
          title: "Fixed Deposit",
          id: 75,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_fixed_deposit.svg",
        },
        {
          title: "Gratuity",
          id: 82,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_Gratuity.svg",
        },
        {
          title: "Govt Bonds",
          id: 79,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_govt_bond.svg",
        },
        {
          title: "Govt. Schemes",
          id: 76,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_govt_schemes.svg",
        },
        {
          title: "NPS",
          id: 118,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_PPF_EPF.svg",
        },
        {
          title: "NSC/ KVP",
          id: 84,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_NSC_KVP.svg",
        },
        {
          title: "Pension Schemes",
          id: 85,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_pension_scheme.svg",
        },
        {
          title: "Post Office Scheme",
          id: 78,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_post_office_scheme.svg",
        },
        {
          title: "PPF/ GPF/ VPF",
          id: 81,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_PPF_EPF.svg",
        },
        {
          title: "Sukanya Samriddhi Yojana",
          id: 86,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_capital_gain_bonds.svg",
        },
        {
          title: "Recurring Deposit",
          id: 120,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/recurring_deposit.svg",
        },
        {
          title: "Others",
          id: 87,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_others.svg",
        },
      ],
    },
    {
      title: "Equity",
      id: 29,
      img:
        imagePath +
        "/static/assets/img/assets-liabilities/assets_equity.svg",
      options: [
        {
          title: "ESOP's",
          id: 34,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/equity/assets_equity_ESOPs.svg",
        },
        {
          title: "Equity Mutual Funds",
          id: 31,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/equity/assets_equity_mutual_unds.svg",
        },
        {
          title: "Equity Shares",
          id: 30,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/equity/assets_equity_shares.svg",
          options: [
            {
              title: "CDSL",
              img:
                imagePath +
                "/static/assets/img/assets-liabilities/alternate/assets_alternate_wine_investment.svg",
            },
            {
              title: "NSDL",
              img:
                imagePath +
                "/static/assets/img/assets-liabilities/alternate/assets_alternate_wine_investment.svg",
            },
          ],
        },
        {
          title: "Future & Options",
          id: 35,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/equity/assets_equity_future_and_options.svg",
        },
        {
          title: "PMS",
          id: 32,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/equity/assets_equity_PMS.svg",
        },
        {
          title: "Unlisted / AIF Equity",
          id: 33,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/equity/assets_equity_unlisted_equity.svg",
        },
        {
          title: "Others",
          id: 74,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_others.svg",
        },
        {
          title: "US Equity",
          id: 123,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_others.svg",
        },
        // {
        //   title: "Multi Assets Linkage",
        //   id: 125,
        //   img:
        //     imagePath +
        //     "/static/assets/img/assets-liabilities/debt/assets_debt_others.svg",
        // },
      ],
    },
    {
      title: "Gold",
      id: 42,
      img:
        imagePath +
        "/static/assets/img/assets-liabilities/assets_gold.svg",
      options: [
        {
          title: "Gold ETF",
          id: 70,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/gold/assets_gold_ETF.svg",
        },
        {
          title: "Gold Mutual Fund",
          id: 71,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/gold/assets_gold_mutual_fund.svg",
        },
        {
          title: "Physical Gold",
          id: 69,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/gold/assets_gold_physical.svg",
        },
        {
          title: "Sovereign Gold Bonds",
          id: 72,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/gold/assets_gold_soverign_bond.svg",
        },
        {
          title: "Others",
          id: 73,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_others.svg",
        },
        // {
        //   title: "Multi Assets Linkage",
        //   id: 125,
        //   img:
        //     imagePath +
        //     "/static/assets/img/assets-liabilities/debt/assets_debt_others.svg",
        // },
      ],
    },
    {
      title: "Liquid",
      id: 40,
      img:
        imagePath +
        "/static/assets/img/assets-liabilities/assets_liquid.svg",
      options: [
        {
          title: "Bank Balance",
          id: 61,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/liquid/assets_liquid_bank_balance.svg",
        },
        // {
        //   title: "Cash Balance",
        //   id: 60,
        //   img:
        //     imagePath +
        //     "/static/assets/img/assets-liabilities/liquid/assets_liquid_cash_balance.svg",
        // },
        {
          title: "Liquid Funds",
          id: 62,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/liquid/assets_liquid_funds.svg",
        },
        {
          title: "Others",
          id: 63,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_others.svg",
        },
        // {
        //   title: "Multi Assets Linkage",
        //   id: 125,
        //   img:
        //     imagePath +
        //     "/static/assets/img/assets-liabilities/debt/assets_debt_others.svg",
        // },
      ],
    },
    {
      title: "Real Estate",
      id: 39,
      img:
        imagePath +
        "/static/assets/img/assets-liabilities/assets_real_estate.svg",
      options: [
        {
          title: "Agricultural Land",
          id: 53,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/real-estate/assets_real_estate_agricultural_land.svg",
        },
        {
          title: "Commercial",
          id: 52,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/real-estate/assets_real_estate_commercial.svg",
        },
        {
          title: "Land",
          id: 56,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/real-estate/assets_real_estate_land.svg",
        },
        {
          title: "Residential Premises",
          id: 51,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/real-estate/assets_real_estate_residenial_premises.svg",
        },
        {
          title: "Under Construction Property",
          id: 55,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/real-estate/assets_real_estate_under_construction.svg",
        },
        {
          title: "Others",
          id: 59,
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/debt/assets_debt_others.svg",
        },
        // {
        //   title: "Multi Assets Linkage",
        //   id: 125,
        //   img:
        //     imagePath +
        //     "/static/assets/img/assets-liabilities/debt/assets_debt_others.svg",
        // },
      ],
    },
    {
      title: "Upload",
      img:
        imagePath +
        "/static/assets/img/assets-liabilities/debt/assets_debt_others.svg",
      options: [
        {
          title: "CDSL",
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/alternate/assets_alternate_wine_investment.svg",
        },
        {
          title: "NSDL",
          img:
            imagePath +
            "/static/assets/img/assets-liabilities/alternate/assets_alternate_wine_investment.svg",
        },
      ],
    },
    {
      title: "Others",
      id: 115,
      img:
        imagePath +
        "/static/assets/img/assets-liabilities/debt/assets_debt_others.svg",
      options: [
        // {
        //   title: "Others",
        //   img: "/static/assets/img/assets-liabilities/debt/assets_debt_others.svg",
        // }
      ],
    },
  ];

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    document.body.classList.add("dg-layout");
    return () => {
      document.body.classList.remove("rp-layout");
    };
  }, []);

  const sortOptions = [
    { value: "1", label: "Self" },
    { value: "2", label: "Family" },
    { value: "3", label: "Dipti Sharma" },
  ];
  const sortOptionsGoal = [
    { value: "1", label: " Select Option " },
    { value: "2", label: " Retirement goal " },
    { value: "3", label: "Son Marriage" },
  ];
  const sortOptionsCryptyo = [
    { value: "1", label: "1INCH" },
    { value: "2", label: "AAVE" },
  ];
  const sortOptionsGoalInsurance = [
    { value: "1", label: "Education" },
    { value: "2", label: "Emergency" },
    { value: "3", label: "Marriage" },
    { value: "4", label: "Property" },
    { value: "5", label: "Vacation" },
    { value: "6", label: "Vehicle" },
    { value: "7", label: "Others" },
    // { value: "3", label: "Half Yearly" },
  ];
  const sortOptionsFrequency = [
    { value: "1", label: "Monthly" },
    { value: "2", label: "Quaterly" },
    { value: "3", label: "Half Yearly" },
    { value: "4", label: "Yearly" },
  ];
  const sortOptionsPayment = [
    { value: "1", label: "Yearly" },
    { value: "2", label: "Once in 1 Years" },
    { value: "3", label: "Once in 2 Years" },
    { value: "4", label: " Once in 3 Years" },
    { value: "5", label: "  Once in 4 Years" },
    { value: "6", label: "  Once in 5 Years" },
    // { value: "3", label: "Half Yearly" },
  ];
  const sortOptionsTypeInsu = [
    { value: "1", label: "Cycle Insurance" },
    { value: "2", label: "Fire Insurance" },
    { value: "3", label: " Home Insurance " },
    { value: "4", label: "  Marine Insurance " },
    { value: "5", label: " Mobile insurance " },
    { value: "6", label: " Motor Insurance " },
    { value: "6", label: "  Property insurance  " },
    { value: "6", label: " Travel Insurance  " },
    // { value: "3", label: "Half Yearly" },
  ];
  const sortOptionsULIP = [
    { value: "1", label: " Aditya Birla Sun Life - Capped Nifty Index Fund" },
    { value: "2", label: "  Aditya Birla Sun Life - Capped Nifty Index Fund" },
    { value: "3", label: "  Aditya Birla Sun Life - Capped Nifty Index Fund" },
    // { value: "3", label: "Half Yearly" },
  ];
  // const customStyles = {
  //   option: (base, { data, isDisabled, isFocused, isSelected }) => {
  //     return {
  //       ...base,
  //       backgroundColor: isFocused ? "#ffff" : "#042b62",
  //       color: isFocused ? "#042b62" : "#fff",
  //       cursor: "pointer",
  //     };
  //   },
  //   menuList: (base) => ({
  //     ...base,
  //     height: "100px",
  //     overflowY: "scroll",
  //     scrollBehavior: "smooth",
  //     "::-webkit-scrollbar": {
  //       width: "4px",
  //       height: "0px",
  //     },
  //     "::-webkit-scrollbar-track": {
  //       background: "#fff",
  //     },
  //     "::-webkit-scrollbar-thumb": {
  //       background: "#042b62",
  //     },
  //     "::-webkit-scrollbar-thumb:hover": {
  //       background: "#555",
  //     },
  //   }),
  // };

  // Assets
  const defaultAssetDetails = {
    Created_By: 0,
    Updated_By: 0,
    asset_amount: "",
    asset_abreturn: "0",
    annual_growth_rate: "10",
    asset_broker_id: 0,
    asset_category_id: 41,
    asset_citytype: "0",
    asset_current_unit_price: "",
    asset_currency: false,
    asset_ecas_type: "manual",
    asset_epf_ismanual: "1",
    asset_folio_number: null,
    asset_footnote: null,
    asset_frequency: "1",
    asset_goal_link_id: 0,
    asset_goalname: null,
    asset_gold_karat: 0,
    asset_isActive: "1",
    asset_ismortgage: "0",
    asset_isperpetual: "3",
    asset_isallocation: false,
    asset_iselss: "1",
    asset_islinkable: true,
    asset_isrecurring: false,
    asset_isrented: "1",
    asset_maturity_amt: 0,
    asset_maturity_date: null,
    asset_member_id: 0,
    asset_mf_end_date: null,
    asset_name: "Art Investment",
    asset_pan: null,
    asset_payout_type: "1",
    asset_pin_code: "",
    asset_purchase_amount: "",
    asset_purchase_date: null,
    asset_rental_amount: "",
    asset_rental_income: null,
    asset_ror: "0",
    asset_sub_category_id: 64,
    asset_unique_code: "",
    asset_units: "",
    categorydetail: "Alternate",
    created_datetime: moment().format("DD/MM/YYYY"),
    employee_contribution: "",
    employer_contribution: "",
    fp_log_id: 0,
    installment_ispaid: "1",
    membername1: "",
    stock_mf: null,
    stock_name: null,
    subcategorydetail: "",
    totalinvestedvalue: "",
    totalpurchasevalue: "",
    totalmaturtiyamount: "",
    updated_datetime: moment().format("DD/MM/YYYY"),
    user_id: 0,
    scheme_equityshare: {},
  };

  const [assetForMember, setAssetForMember] = useState("");
  const [assetName, setAssetName] = useState("");
  const [categoryDetail, setCategoryDetail] = useState("");
  const [subCategoryDetails, setSubCategoryDetails] = useState("");
  const [assetLinkGoal, setAssetLinkGoal] = useState("");
  const [unfilteredAssetsData, setUnfilteredAssetsData] = useState("");
  const [alternateFilteredAssetsData, setAlternateFilteredAssetsData] = useState("");
  const [goldFilteredAssetsData, setGoldFilteredAssetsData] = useState("");
  const [liquidFilteredAssetsData, setLiquidFilteredAssetsData] = useState("");
  const [realEstateFilteredAssetsData, setRealEstateFilteredAssetsData] = useState("");
  const [equityFilteredAssetsData, setEquityFilteredAssetsData] = useState("");
  const [goalData, setGoalData] = useState([]);
  const [multiLinkageGoalData, setMultiLinkageGoalData] = useState([]);
  const [unchangedgoaldata, setUnchangedGoalData] = useState([]);
  const [rentalincomeData, setRentalIncomeData] = useState([]);
  const [eqfunds, setEQFunds] = useState([]);
  const [equityShares, setEquityShares] = useState([]);
  const [debtfunds, setDebtfunds] = useState([]);
  const [goldfunds, setGoldfunds] = useState([]);
  const [liquidfunds, setLiquidfunds] = useState([]);
  // set US equity data
  const [USEquity, setUSEquity] = useState([]);
  const [assetsDetails, setAssetsDetails] = useState(defaultAssetDetails);
  const [filterText, setFilterText] = useState("");
  const [filterName, setFilterName] = useState("");
  const [totalacivesip, setTotalActiveSIP] = useState(0);
  const [schemedata, setSchemeData] = useState([]);
  const [cryptodata, setCryptoData] = useState([]);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [isLoading, setIsLoading] = useState(false);
  const [assetEditId, setAssetEditId] = useState("");
  const [isGoalSelected, setGoalSelected] = useState(false);
  const [selectedGoals, setSelectedGoals] = useState("");
  const [selectedGoalsId, setSelectedGoalsId] = useState([]);
  const [selectedPriorityArray, setSelectedPriorityArray] = useState([]);
  const [isAutoMatedGoal, setAutoMatedGoal] = useState(true);
  const [retirementGoalID, setRetirementGoalId] = useState(0);
  // const [checkboxShouldExcludel, setCheckboxShouldExcludel] = useState(false);

  const categoryEditIdRef = useRef();
  const [assetsArrayInModal, setAssetsArrayInModal] = useState([]);
  const [categoryEditId, setCategoryEditId] = useState(null);
  const [viewAllGoalsData, setViewAllGoalsData] = useState([]);
  const handleDoneInModal = (v) => {
    console.log(v);
  }
  const defaultGoal = { label: "Automated Linkage", value: 0 };

  const renderGoalNames = (ar) => {
    if (ar.length == 0) ar.push(0);
    let b = [];
    const otherArray = [defaultGoal, ...multiLinkageGoalData];
    for (const v of ar) {
      for (const x of otherArray) {
        if (Number(v) == Number(x.value)) {
          if (x.member_name) {
            b.push(x.label + " - " + x.member_name);
          }
          else {
            b.push(x.label);
          }
          break;
        }
      }
    }
    return b.join(", ");
  }

  const openViewAllModal = (ar) => {
    let b = [];
    for (const v of ar) {
      for (const x of multiLinkageGoalData) {
        if (Number(v) == Number(x.value)) {
          // const goalLabelParts = x.label.split('-');
          // const member = `${x.label}`+` - `+`${x.member_name}`;
          const member = `${x.member_name}`;
          b.push({ member, goal: x.label });
          break;
        }
      }
    }
    setViewAllGoalsData([...b]);
    setSelectedGoalsData(true);
    // return b.join(", ");
  }


  useEffect(() => {
    if (window.location.href.includes('openLinkYourStatement=1')) {
      setTimeout(() => {
        setAssetsDetails(prev => ({ ...prev, asset_category_id: 40 }));
        setSelectedOption('Liquid');
      }, 1000);
    }
  }, []);

  const getBrokersList = async () => {
    const rid = uuidv4();
    const ts = new Date().toISOString();
    const loginPayload = {
      header: {
        rid: rid,
        ts: ts,
        channelId: "finsense",
      },
      body: {
        userId: "channel@fintoo",
        password: "85a333fb49044c7e91611a0d962ff8ba",
      },
    };

    const url =
      "https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/User/Login";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginPayload),
    };

    const loginResponse = await fetch(url, options);

    if (loginResponse.status === 200) {
      const responseData = await loginResponse.json();
      const token = responseData.body.token;

      // try {
      //   let fir_request_payload = {
      //     header: {
      //       rid: rid,
      //       ts: ts,
      //       channelId: "finsense",
      //     },
      //     body: {
      //       custId: cust_id,
      //       consentId: consent_id,
      //       consentHandleId: consent_handle,
      //       dateTimeRangeFrom: daterange_from,
      //       dateTimeRangeTo: daterange_to,
      //     },
      //   };

      //   const requestBody = JSON.stringify(fir_request_payload);

      //   const requestOptions = {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${token}`,
      //     },
      //     body: requestBody,
      //   };

      //   fetch(
      //     "https://fintoo.fiulive.finfactor.co.in/finsense/API/V2/FIRequest",
      //     requestOptions
      //   )
      //     .then((response) => response.json())
      //     .then((data) => {
      //       if (data.body && data.body.sessionId) {
      //         setUserSessionId(data.body.sessionId);
      //         FIStatus(
      //           data.body.sessionId,
      //           cust_id,
      //           consent_handle,
      //           consent_id,
      //           token,
      //           daterange_from,
      //           daterange_to
      //         );
      //       } else {
      //         setIsLoading(false);
      //         toastr.options.positionClass = "toast-bottom-left";
      //         toastr.error("Could not fetch session id from the bank");
      //       }
      //     })
      //     .catch((error) => {
      //       console.log("Error:", error);
      //     });
      // } catch (e) {
      //   console.log("Error", e);
      // }
    }
  }

  const getfpgoalsdata = async (fplogid) => {
    try {
      let fpgoal_data = {
        user_id: getParentUserId(),
        fp_log_id: fplogid,
      };

      var fpgoals_res = await apiCall(
        ADVISORY_GET_FP_GOALS_DATA,
        fpgoal_data,
        true,
        false
      );

      if (fpgoals_res["error_code"] == "100") {
        var family_array = [];
        var family__multi_linkage_array = [];
        var family_array1 = [];

        // Add the automated linkage field at the beginning
        // family_array.push({
        //   value: 0,
        //   label: "Automated Linkage",
        // });

        var family = fpgoals_res["data"];
        function hasUniqueIds(arr) {
          const idSet = new Set();
          for (const obj of arr) {
            if (idSet.has(obj.id)) {
              return false; // Non-unique id found
            }
            idSet.add(obj.id);
          }
          return true; // All ids are unique
        }
        if (hasUniqueIds(family)) {
          family.map((goal) => {
            if (goal.goal_category_id == "96") {
              setRetirementGoalId(goal.id);
            }
            family_array.push({
              value: goal.id,
              label: goal.goal_name + " - " + goal.goalforname,
              goal_end_date: goal.goal_end_date,
              goal_start_date: goal.goal_start_date,
              goal_isRecurring: goal.goal_isRecurring ? "1" : "0",
            });
            family__multi_linkage_array.push({
              value: goal.id,
              label: goal.goal_name,
              goal_end_date: goal.goal_end_date,
              goal_start_date: goal.goal_start_date,
              goal_isRecurring: goal.goal_isRecurring ? "1" : "0",
              member_name: goal.goalforname
            });
            family_array1.push({
              value: goal.id,
              label: goal.goal_name + " - " + goal.goalforname,
              goal_end_date: goal.goal_end_date,
              goal_start_date: goal.goal_start_date,
              goal_isRecurring: goal.goal_isRecurring ? "1" : "0",
            });
          });
        }
        setGoalData(family_array);
        setMultiLinkageGoalData(family__multi_linkage_array);
        setUnchangedGoalData(family_array1);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getRentalIncome = async (fplogid) => {
    try {
      let url = ADVISORY_GET_INCOME_DATA;
      let payload = {
        income_forretirement: "0",
        isrented: 1,
        user_id: getParentUserId(),
        fp_log_id: fplogid,
      };
      let getIncomeData = await apiCall(url, payload, true, false);
      if (getIncomeData["error_code"] == "100") {
        var rental_income = [];
        var incomedata = getIncomeData["data"];

        incomedata.map((v) => {
          rental_income.push({ value: v.id, label: v.income_amount });
        });
        setRentalIncomeData(rental_income);
      }
    } catch (err) {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const [assetsId, setAssetsId] = useState("");
  const [assetType, setAssetType] = useState("");
  const addAssetsSubmit = async (e, fplogid) => {
    e.preventDefault();
    if (assetsDetails.fp_log_id == 0 && assetsDetails.user_id == 0) {
      var fp_log_id = fplogid;
      var user_id = getParentUserId();
      setAssetsDetails({
        ...assetsDetails,
        user_id: user_id,
        fp_log_id: fp_log_id,
      });
    }
    if (simpleValidator.current.allValid()) {
      addAssets(e);
    } else {
      // Form is not valid, handle or display error messages
      simpleValidator.current.showMessages();
    }
  };

  const updateAssetsSubmit = async (e) => {
    e.preventDefault();

    if (simpleValidator.current.allValid()) {
      updateAssets(e);
    } else {
      // Form is not valid, handle or display error messages
      simpleValidator.current.showMessages();
    }
  };
  const deleteDMFAsset = async (id) => {
    try {
      if (assetType == "realestate") {
        var payload = {
          inv_type: "real_estate",
          inv_id: id.toString(),
          user_id: getUserId(),
          is_direct: "1",
        };
      } else if (assetType == "liquid") {
        var payload = {
          inv_type: "liquid",
          inv_id: id.toString(),
          user_id: getUserId(),
          is_direct: "1",
        };
      } else {
        var payload = {
          inv_type: "gold",
          inv_id: id.toString(),
          user_id: getUserId(),
          is_direct: "1",
        };
      }
      let url = DELETE_OTHER_INVESTMENTS;

      let deleteassetData = await apiCall(url, payload, true, false);
      if (deleteassetData["error_code"] == "100") {
        setAssetType("");
        setShow(false);
        var msg = assetName ? " - " + assetName : "";
        toastr.options.positionClass = "toast-bottom-left";
        toastr.success(
          categoryDetail +
          "-" +
          subCategoryDetails +
          msg +
          " Data Deleted Successfully"
        );
        getAssetData(fpLogId, 0);
        dispatch({ type: "RELOAD_SIDEBAR", payload: true });
        scrollToList();
        setAssetsDetails(defaultAssetDetails);
        setSelectedOption("Alternate");
        setSelectedSubOption("Art Investment");
        setAssetsDetails({
          ...defaultAssetDetails,
          user_id: session["data"]["user_details"]["user_id"],
          fp_log_id: session["data"]["user_details"]["fp_log_id"],
          asset_member_id: familyData["0"].value,
        });
        setSelectedGoals("Automated Linkage");
        setSelectedGoalsId([]);
        setSelectedPriorityArray([]);
        setAutoMatedGoal(true);
      } else {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
      }
    } catch (err) {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };
  let filteredIds = selectedCategories.filter(id => assetsData.find(asset => asset.id === id && asset.asset_ecas_type === "ecas"));
  let external_data = [];
  filteredIds.forEach(id => {
    let asset = assetsData.find(asset => asset.id === id);
    if (asset) {
      external_data.push({
        asset_unique_code: asset.asset_unique_code,
        asset_member_id: asset.asset_member_id,
        asset_folio_number: asset.asset_folio_number
      });
    }
  });

  const ecasData = assetsData.filter(entry => entry.asset_ecas_type === 'ecas');
  const data_count_by_id = ecasData.reduce((accumulator, entry) => {
    accumulator[entry.asset_member_id] = (accumulator[entry.asset_member_id] || 0) + 1;
    return accumulator;
  }, {});

  const delete_all_data_keys = [];
  for (const memberId in data_count_by_id) {
    const countInExternalData = external_data.filter(entry => entry.asset_member_id === Number(memberId)).length;
    if (countInExternalData === data_count_by_id[memberId]) {
      delete_all_data_keys.push(memberId);
    }
  }

  const deleteAssetData = async (id) => {
    const filteredData = assetsData.filter(
      (item) => item.asset_ecas_type === "cdsldemat"
    );
    const lengthOfFilteredData = filteredData.length;
    if (assetType) {
      deleteDMFAsset(id);
    } else {
      try {
        let url = ADVISORY_DELETE_ASSETS_API;
        let payload = {
          // id: selectedCategories,
          id: selectedCategories.length > 0 ? selectedCategories : id,
          external_data: external_data,
          delete_all_data_keys: delete_all_data_keys,
          user_id: getParentUserId(),
          fp_log_id: fpLogId,
          lengthOfFilteredData: lengthOfFilteredData,
        };
        setIsLoading(true);
        let deleteassetData = await apiCall(url, payload, true, false);
        if (deleteassetData["error_code"] == "100") {
          let payload = {
            url: ADVISORY_DELETE_BANK_DATA,
            data: {
              user_id: session["data"]["user_details"]["user_id"],
              asset_id: id,
            },
            method: "post",
          };
          const r = await fetchData(payload);

          setShow(false);
          setIsLoading(false);
          var msg = assetName ? " - " + assetName : "";
          toastr.options.positionClass = "toast-bottom-left";
          toastr.success("Asset Data Deleted Successfully");
          setSelectedCategories([]);
          dispatch({ type: "ASSETS_UPDATE", payload: true });
          // toastr.success(
          //   categoryDetail +
          //   "-" +
          //   subCategoryDetails +
          //   msg +
          //   " Data Deleted Successfully"
          // );
          dispatch({ type: "TRIGGER_EQUITY_HOLDING", payload: true });
          // getAssetData(fpLogId, 0);
          if (selectedCategories.length > 0) {
            let updatedAssetData = assetsData.filter(asset => !selectedCategories.includes(asset.id))
            asset_data.current = updatedAssetData;
            setAssetsData(updatedAssetData);
          } else {
            let updatedAssetData = assetsData.filter(asset => asset.id !== id);
            asset_data.current = updatedAssetData;
            setAssetsData(updatedAssetData);
          }
          dispatch({ type: "RELOAD_SIDEBAR", payload: true });
          scrollToList();
          setAssetsDetails(defaultAssetDetails);
          setSelectedOption("Alternate");
          setSelectedSubOption("Art Investment");
          setAssetsDetails({
            ...defaultAssetDetails,
            user_id: session["data"]["user_details"]["user_id"],
            fp_log_id: session["data"]["user_details"]["fp_log_id"],
            asset_member_id: familyData["0"].value,
          });
          setSelectedGoals("Automated Linkage");
          setSelectedGoalsId([]);
          setSelectedPriorityArray([]);
          setAutoMatedGoal(true);
        } else {
          setIsLoading(false);
          toastr.options.positionClass = "toast-bottom-left";
          toastr.error("Something went wrong");
        }
      } catch (err) {
        setIsLoading(false);
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
      }
    }
  };

  const addAssets = async (e) => {
    e.preventDefault();
    if (assetsDetails.subcategorydetail != "Multi Assets Linkage") {
      try {
        let url = ADVISORY_ADD_ASSETS_API;
        let payload = assetsDetails;
        if (payload.asset_sub_category_id == 123) {
          if (payload.asset_currency == false) {
            payload.asset_currency = 0;
          } else {
            payload.asset_currency = 1;
          }
        }

        payload.user_id = getParentUserId();
        payload.fp_log_id = fpLogId;
        payload.asset_source = "1";

        if (assetsDetails.asset_sub_category_id == "82") {
          if (retirementGoalID) {
            payload.asset_goal_link_id = retirementGoalID.toString();
          } else {
            payload.asset_goal_link_id = "";
          }
        }

        if (assetsDetails.asset_sub_category_id == "30") {
          payload.asset_unique_code = payload.scheme_equityshare.isin;
        }

        setIsLoading(true);

        let addassetData = await apiCall(url, payload, true, false);
        if (addassetData["error_code"] == "100") {
          var checkFirstSave = 1;
          var msg = assetsDetails.subcategorydetail
            ? " - " + assetsDetails.asset_name
            : "";
          var addedAsset = addassetData["asset"];
          let existingAssetsData = assetsData;
          setAssetsData((prev) => {
            const newState = [...prev, addedAsset];
            let assetLength = newState.length;
            if (checkFirstSave == 1 && assetLength == 1) {
              localStorage.setItem("assetLiabilityCookie", 1);
              dispatch({ type: "RELOAD_SIDEBAR", payload: true });
            } else {
              localStorage.removeItem("assetLiabilityCookie");
              dispatch({ type: "RELOAD_SIDEBAR", payload: true });
            }
            return newState
          });
          asset_data.current = [...existingAssetsData, ...[addedAsset]];
          // await getAssetData(fpLogId, checkFirstSave);
          scrollToList();
          toastr.options.positionClass = "toast-bottom-left";
          toastr.success(
            assetsDetails.categorydetail + msg + " added succesfully"
          );
          // dispatch({ type: "RELOAD_SIDEBAR", payload: true });
          setAssetsDetails({
            ...defaultAssetDetails,
            user_id: session["data"]["user_details"]["user_id"],
            fp_log_id: session["data"]["user_details"]["fp_log_id"],
            asset_member_id: familyData["0"].value,
          });
          setSelectedOption(defaultAssetDetails.categorydetail);
          setSelectedSubOption("Art Investment");
          setAddForm(true);
          setUpdateForm(false);
          setGoalSelected(false);
          setSelectedGoals("Automated Linkage");
          setSelectedGoalsId([]);
          setSelectedPriorityArray([]);
          setAutoMatedGoal(true);
          setIsLoading(false);
          getAssetData(fpLogId, 0);

        } else {
          setIsLoading(false);
          toastr.options.positionClass = "toast-bottom-left";
          toastr.error("Something went wrong");
        }
      } catch (err) {
        setIsLoading(false);
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
      }
    }
  };

  const updateDMFAsset = async (e, asset_type) => {
    e.preventDefault();
    try {
      if (asset_type == "realestate") {
        var updateassetData = await apiCall(DMF_ADD_EDIT_REALESTATE, {
          user_id: getUserId(),
          property_name: assetsDetails.asset_name,
          property_type: assetsDetails.subcategorydetail,
          purchase_rate: assetsDetails.asset_purchase_amount.toString(),
          current_rate: assetsDetails.asset_amount.toString(),
          purchase_date: moment(assetsDetails.asset_purchase_date).format(
            "YYYY/MM/DD"
          ),
          pincode: assetsDetails.asset_pin_code,
          residential_type:
            assetsDetails.asset_isrented == "1" ? "self occupied" : "rented",
          ownership:
            assetsDetails.asset_isMortgage == "1" ? "mortgage" : "freehold",
          is_active: "1",
          update: "",
          realestate_id: assetsDetails.id,
          city: assetsDetails.asset_citytype,
          asset_rental_income: assetsDetails.asset_rental_income,
          asset_islinkable: assetsDetails.asset_islinkable ? "1" : "0",
          asset_isallocation: assetsDetails.asset_isallocation ? "1" : "0",
        });
      } else if (asset_type == "gold") {
        if (assetsDetails.asset_sub_category_id == "73") {
          var updateassetData = await apiCall(DMF_ADD_EDIT_GOLD, {
            user_id: getUserId(),
            gold_type: assetsDetails.asset_name,
            units: parseInt(assetsDetails.asset_units),
            purchase_date: moment(
              assetsDetails.asset_purchase_date,
              "DD/MM/YYYY"
            ).format("YYYY/MM/DD"),
            current_price: parseInt(assetsDetails.asset_current_unit_price),
            purchase_price: parseInt(assetsDetails.asset_purchase_amount),
            invested_value: parseInt(assetsDetails.totalpurchasevalue),
            number_of_gms: parseInt(assetsDetails.asset_units),
            current_amount: parseInt(assetsDetails.totalinvestedvalue),
            karat: assetsDetails.asset_gold_karat,
            asset_islinkable: assetsDetails.asset_islinkable ? "1" : "0",
            gold_id: assetsDetails.id,
            update: "1",
          });
        } else if (
          assetsDetails.asset_sub_category_id == "73" ||
          assetsDetails.asset_sub_category_id == "69"
        ) {
          var updateassetData = await apiCall(DMF_ADD_EDIT_GOLD, {
            user_id: getUserId(),
            gold_type: assetsDetails.asset_name,
            units: parseInt(assetsDetails.asset_units),
            purchase_date: moment(
              assetsDetails.asset_purchase_date,
              "DD/MM/YYYY"
            ).format("YYYY/MM/DD"),
            current_price: parseInt(assetsDetails.asset_current_unit_price),
            purchase_price: parseInt(assetsDetails.asset_purchase_amount),
            invested_value: parseInt(assetsDetails.totalpurchasevalue),
            number_of_gms: parseInt(assetsDetails.asset_units),
            current_amount: parseInt(assetsDetails.totalinvestedvalue),
            asset_islinkable: assetsDetails.asset_islinkable ? "1" : "0",
            gold_id: assetsDetails.id,
            update: "1",
          });
        } else {
          var updateassetData = await apiCall(DMF_ADD_EDIT_GOLD, {
            user_id: getUserId(),
            gold_type: assetsDetails.asset_name,
            units: parseInt(assetsDetails.asset_units),
            current_price: parseInt(assetsDetails.asset_current_unit_price),
            purchase_price: parseInt(assetsDetails.asset_purchase_amount),
            invested_value: parseInt(assetsDetails.totalpurchasevalue),
            number_of_gms: parseInt(assetsDetails.asset_units),
            current_amount: parseInt(assetsDetails.totalinvestedvalue),
            asset_islinkable: assetsDetails.asset_islinkable ? "1" : "0",
            gold_id: assetsDetails.id,
            update: "1",
          });
        }
      } else if (asset_type == "liquid") {
        var updateassetData = await apiCall(DMF_ADD_EDIT_LIQUID, {
          user_id: getUserId(),
          asset_type: assetsDetails.asset_name,
          purchase_date: assetsDetails.asset_purchase_date,
          current_balance: assetsDetails.asset_amount,
          asset_islinkable: assetsDetails.asset_islinkable ? "1" : "0",
        });
      }

      if (updateassetData["error_code"] == "100") {
        var msg = assetsDetails.subcategorydetail
          ? " - " + assetsDetails.subcategorydetail
          : "";
        getAssetData(fpLogId, 0);
        scrollToList();
        toastr.options.positionClass = "toast-bottom-left";
        toastr.success(
          assetsDetails.categorydetail + msg + " updated succesfully"
        );
        // dispatch({ type: "RELOAD_SIDEBAR", payload: true });
        setAssetsDetails({
          ...defaultAssetDetails,
          user_id: session["data"]["user_details"]["user_id"],
          fp_log_id: session["data"]["user_details"]["fp_log_id"],
          asset_member_id: familyData["0"].value,
        });
        setSelectedOption(defaultAssetDetails.categorydetail);
        setSelectedSubOption("Art Investment");
        setAddForm(true);
        setUpdateForm(false);
        setGoalSelected(false);
        // setSelectedGoals('Automated Linkage')
        setSelectedGoalsId([]);
        setSelectedPriorityArray([]);
        setAutoMatedGoal(true);
      } else {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
      }
      const updatemultiplegoals = await apiCall(
        ADVISORY_UPDATE_MULTIPLE_GOALS_API,
        {
          asset_goal_link_id: selectedGoalsId,
          inv_id: assetsDetails.id,
          asset_type: assetsDetails.asset_type,
        }
      );
    } catch (err) {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };
  const updateAssets = async (e) => {
    if (assetsDetails.asset_type && assetsDetails.asset_type != "none") {
      updateDMFAsset(e, assetsDetails.asset_type);
    } else {
      e.preventDefault();
      try {
        let url = ADVISORY_UPDATE_ASSETS_API;
        let payload = assetsDetails;
        payload.user_id = getParentUserId();
        payload.fp_log_id = fpLogId;
        setIsLoading(true);

        // if(assetsDetails.asset_sub_category_id == "30")
        // {
        //   payload.asset_unique_code = payload.scheme_equityshare.isin;
        // }

        if (assetsDetails.asset_sub_category_id == "30") {
          if (assetsDetails.asset_ecas_type == "cdsl" || assetsDetails.asset_ecas_type == "manual" ||
            assetsDetails.asset_ecas_type == "broker") {
            payload.asset_unique_code = assetsDetails.asset_unique_code;
          } else {
            payload.asset_unique_code = payload.scheme_equityshare.isin;
          }
        }

        let updateassetData = await apiCall(url, payload, true, false);
        if (updateassetData["error_code"] == "100") {
          setIsLoading(false);
          var msg = assetsDetails.subcategorydetail
            ? " - " + assetsDetails.subcategorydetail
            : "";
          let id = updateassetData["updated_id"];
          let asset = updateassetData["asset"];
          const index = assetsData.findIndex(item => item.id === id);
          if (index !== -1) {
            assetsData[index] = asset;
            asset_data.current[index] = asset;
          }
          // getAssetData(fpLogId, 0);
          scrollToList();
          toastr.options.positionClass = "toast-bottom-left";
          toastr.success(
            assetsDetails.categorydetail + msg + " updated succesfully"
          );
          // dispatch({ type: "RELOAD_SIDEBAR", payload: true });
          setAssetsDetails({
            ...defaultAssetDetails,
            user_id: session["data"]["user_details"]["user_id"],
            fp_log_id: session["data"]["user_details"]["fp_log_id"],
            asset_member_id: familyData["0"].value,
          });
          dispatch({ type: "ASSETS_UPDATE", payload: true });
          setSelectedOption(defaultAssetDetails.categorydetail);
          setSelectedSubOption("Art Investment");
          setAddForm(true);
          setUpdateForm(false);
          setGoalSelected(false);
          setSelectedGoals("Automated Linkage");
          setSelectedGoalsId([]);
          setSelectedPriorityArray([]);
          setAutoMatedGoal(true);
        } else {
          setIsLoading(false);
          toastr.options.positionClass = "toast-bottom-left";
          toastr.error("Something went wrong");
        }
      } catch (err) {
        setIsLoading(false);
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
      }
    }
  };

  const editAssetData = async (id, asset_type) => {
    setAutoMatedGoal(false);
    try {
      // let url = ADVISORY_GET_ASSETS_API;
      // let payload = {
      //   id: id,
      //   user_id: getParentUserId(),
      //   fp_log_id: fpLogId,
      //   asset_type: asset_type,
      // };
      // let editassetData = await apiCall(url, payload, true, false);
      let editassetData = assetsData.filter(asset => asset.id == id);
      if (editassetData.length > 0) {
        var msg = editassetData[0]["subcategorydetail"]
          ? " - " + editassetData[0]["subcategorydetail"]
          : "";

        var editData = editassetData[0];
        editData.asset_member_id = editData["asset_member_id"]
        editData.membername1 = editData["membername1"]
        editData.asset_ismortgage = editData["asset_isMortgage"];
        editData.asset_isperpetual = editData["asset_isPerpetual"];
        editData.asset_isrecurring = editData["asset_isrecurring"];
        editData.asset_payout_type = editData["asset_payout_type"];
        editData.asset_frequency = editData["asset_frequency"];
        editData.annual_growth_rate = editData["asset_annual_growth_rate"];
        if (editData["asset_islinkable"] == "1") {
          editData.asset_islinkable = true;
        } else {
          editData.asset_islinkable = false;
        }
        if (editData["asset_isrecurring"] == "1") {
          editData.asset_isrecurring = true;
        } else {
          editData.asset_isrecurring = false;
        }

        // set asset currency
        if (editData["asset_currency"] == "1") {
          editData.asset_currency = true;
        } else {
          editData.asset_currency = false;
        }

        if (editData["installment_ispaid"] == "1") {
          editData.installment_ispaid = true;
        } else {
          editData.installment_ispaid = false;
        }
        if (editData["asset_isallocation"] == "1") {
          editData.asset_isallocation = true;
        } else {
          editData.asset_isallocation = false;
        }
        goalData.forEach(resetPriorityKey);
        unchangedgoaldata.forEach(resetPriorityKey);
        if (editassetData[0]["linked_goals"].length > 0) {
          var linkedGoals = editassetData[0]["linked_goals"];

          var selectedGoals = [];
          var goalIds = [];
          var priorityArray = [];
          linkedGoals.forEach((goal) => {
            if (goal.goalforname == "") {
              goal.goalforname = "Family";
            }
            selectedGoals.push(goal.goal_id.goal_name + "-" + goal.goalforname);
            goalIds.push(goal.goal_id.id);
            priorityArray.push(goal.priority);
            goalData.forEach((goal_obj) => {
              if (goal_obj.value == goal.goal_id.id) {
                goal_obj.priority = parseInt(goal.priority);
              }
            });
            unchangedgoaldata.forEach((goal_obj) => {
              if (goal_obj.value == goal.goal_id.id) {
                goal_obj.priority = parseInt(goal.priority);
              }
            });
          });
          setGoalData(goalData);
          setUnchangedGoalData(unchangedgoaldata);
          setSelectedGoals(selectedGoals.toString());
          setSelectedGoalsId(goalIds);
          setSelectedPriorityArray(priorityArray);
          setAutoMatedGoal(false);
        } else {
          setAutoMatedGoal(true);
          setSelectedGoals("Automated Linkage");
          setSelectedGoalsId([]);
        }

        editData.asset_amount = Number(editData.asset_amount);
        editData.asset_purchase_amount = Number(editData.asset_purchase_amount);
        editData.asset_current_unit_price = Number(
          editData.asset_current_unit_price
        );
        editData.asset_units = Number(editData.asset_units);
        editData.asset_maturity_amt = Number(editData.asset_maturity_amt);
        editData.totalinvestedvalue = Number(editData.totalinvestedvalue);
        editData.totalpurchasevalue = Number(editData.totalpurchasevalue);
        editData.totalmaturtiyamount = Number(editData.totalmaturtiyamount);
        editData.employee_contribution = Number(
          editData["employee_monthly_contrib"]
        );
        editData.employer_contribution = Number(
          editData["employeer_monthly_contrib"]
        );
        editData.asset_pin_code = editData.asset_pin_code
          ? editData.asset_pin_code.toString()
          : "";
        setAssetsDetails(editData);
        setSelectedOption(editassetData[0]["categorydetail"]);
        setSelectedSubOption(editassetData[0]["subcategorydetail"]);
        toastr.options.positionClass = "toast-bottom-left";
        toastr.success(
          "You can now edit details for " +
          editassetData[0]["categorydetail"] +
          msg
        );
        // dispatch({ type: "RELOAD_SIDEBAR", payload: true });
        scrollToForm();

        if (editassetData[0]["subcategorydetail"] == "61") {
          if (editAssetData[0]["asset_ecas_type"] == "broker") {
            setFetched(true);
          } else {
            setFetched(false);
          }
        }
      } else {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
      }
    } catch (err) {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const resetPriorityKey = (obj) => {
    if ('priority' in obj) {
      delete obj.priority;
    }
  }

  const cancelAssetForm = async (e) => {
    e.preventDefault();
    setAssetsDetails({
      ...defaultAssetDetails,
      user_id: session["data"]["user_details"]["user_id"],
      fp_log_id: session["data"]["user_details"]["fp_log_id"],
      asset_member_id: familyData["0"].value,
    });
    setSelectedOption(defaultAssetDetails.categorydetail);
    setSelectedSubOption("Art Investment");
    setAssetEditId(" ");
    setAddForm(true);
    setUpdateForm(false);
    setGoalSelected(false);
    setSelectedGoals("Automated Linkage");
    setSelectedGoalsId([]);
    setSelectedPriorityArray([]);
    setAutoMatedGoal(true);
    if (session["data"]["user_details"]["fp_log_id"]) {
      getfpgoalsdata(session["data"]["user_details"]["fp_log_id"]);
    }
  };

  // Liability

  const liability_option = [
    {
      id: 24,
      title: "Business Loan",
      img:
        imagePath +
        "/static/assets/img/assets-liabilities/liability_business.svg",
    },
    {
      id: 23,
      title: "Car Loan",
      img:
        imagePath +
        "/static/assets/img/assets-liabilities/liability_car.svg",
    },
    {
      id: 27,
      title: "Consumer Loan",
      img:
        imagePath +
        "/static/assets/img/assets-liabilities/liability_consumer.svg",
    },
    {
      id: 22,
      title: "Credit Card",
      img:
        imagePath +
        "/static/assets/img/assets-liabilities/liability_mortgage.svg",
    },
    {
      id: 21,
      title: "Housing",
      img:
        imagePath +
        "/static/assets/img/assets-liabilities/liability_housing.svg",
    },
    {
      id: 28,
      title: "Loan From Family And Friends",
      img:
        imagePath +
        "/static/assets/img/assets-liabilities/liability_famil_friends.svg",
    },
    {
      id: 25,
      title: "Other Property Loan",
      img:
        imagePath +
        "/static/assets/img/assets-liabilities/liability_other_property_loan.svg",
    },
    {
      id: 26,
      title: "Personal Loan",
      img:
        imagePath +
        "/static/assets/img/assets-liabilities/liability_personal.svg",
    },
  ];

  const [liabilityName, setLiabilityName] = useState("Business Loan");
  const [liabilityNameError, setLiabilityNameError] = useState("");
  const [currentOutStandingAmount, setCurrentOutStandingAmount] = useState("");
  const [currentOutStandingAmountError, setCurrentOutStandingAmountError] =
    useState("");
  const [liabilityForMember, setLiabilityForMember] = useState("");
  const [familyData, setFamilyData] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [liabilityEndDate, setLiabilityEndDate] = useState(null);
  const [liabilityEndDateError, setLiabilityEndDateError] = useState("");
  const [liabilityEmiIntRateIncrease, setLiabilityEmiIntRateIncrease] =
    useState(7);
  const [liabilityCurrentEmi, setLiabilityCurrentEmi] = useState("");
  const [liabilityCurrentEmiError, setLiabilityCurrentEmiError] = useState("");
  const [liabilityFrequency, setLiabilityFrequency] = useState("1");
  const [liabilityRemarks, setLiabilityRemarks] = useState("");
  const [liabilityCatId, setLiabilityCatId] = useState(24);
  const [liabilityId, setLiabilityId] = useState("");
  const [liabGetData, setLiabGetData] = useState("");

  const [liabilityData, setLiabilityData] = useState("");
  const [session, setSession] = useState("");

  const [not_calculated, setNotCalculated] = useState("0");
  const [check_liabilityoutstandingamount, setCheckLiabilityoutstandingamount] =
    useState("");
  const [check_liabilityenddate, setCheckLiabilityenddate] = useState("");
  const [check_emi_rate, setCheckEmiRate] = useState("");
  const [check_emi, setCheckEmi] = useState("");
  const [retirmentdate, setRetirmentDate] = useState("");
  const [lifeexpectancydate, setLifeExpentancyDate] = useState("");

  const [addForm, setAddForm] = useState(true);
  const [updateForm, setUpdateForm] = useState(false);
  const frequencylist = {
    1: "Monthly",
    2: "Quarterly",
    3: "Half Yearly",
    4: "Yearly",
  };

  useEffect(() => {
    checksession();
    // getBrokersList();

  }, []);


  // bank balance  
  // {console.log("updateForm: ", updateForm)}


  const getbankdata = async () => {
    try {
      const payload = {
        user_id: props.session.data.id,
      };
      const r = await apiCall(ADVISORY_GET_BANK_DATA, payload, true, false);

      if (r["error_code"] == "100") {
        console.log("OTP data", r);
      }
      console.log("OTP bank balance", r);
    } catch (e) {
      console.log("OTP Error", e);
    }
  };

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };
  const checkprofile = async (sessionData) => {
    try {
      let api_data = {
        user_id: sessionData["data"]["id"],
        fp_log_id: sessionData["data"]["user_details"]["fp_log_id"],
        web: 1,
      };
      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
      var res = await apiCall(
        ADVISORY_CHECK_PROFILE_STATUS_API_URL,
        payload_data,
        false,
        false
      );
      let decoded_res = JSON.parse(commonEncode.decrypt(res));
      if (decoded_res["error_code"] == "100") {
        dispatch({ type: "UPDATE_PROFILE", payload: decoded_res["data"] });
        const profile_completed_mapping = {
          17: 117.496,
          50: 70.4973,
          67: 46.9982,
          83: 23.4991,
          100: 0,
        };

        const profile_completed =
          decoded_res["data"][13]["profile_completed"] === 66
            ? 67
            : decoded_res["data"][13]["profile_completed"];
        const sectionIdsToCheck = [1, 3, 5, 6, 7, 8];
        const allConditionsMet = sectionIdsToCheck.every((sectionId) => {
          const matchingEntry = decoded_res["data"].find(
            (entry) => entry.section_id === sectionId
          );
          return matchingEntry && matchingEntry.total > 0;
        });

        const sectionIdsToCheckk = [1, 3];
        const allConditionsMett = sectionIdsToCheckk.every((sectionId) => {
          const matchingEntryy = decoded_res["data"].find(
            (entry) => entry.section_id === sectionId
          );
          return matchingEntryy && matchingEntryy.total > 0;
        });

        let newNumber;
        if (allConditionsMet) {
          newNumber = "1";
        } else {
          newNumber = "0";
        }

        let newNumberr;
        if (allConditionsMett) {
          newNumberr = "1";
        } else {
          newNumberr = "0";
        }
        const sectionTextMap = {
          1: "About You",
          3: "About You",
          5: "Goals",
          6: "Income & Expenses",
          7: "Income & Expenses",
          8: "Assets & Liabilities",
        };

        const filteredData = decoded_res["data"].filter((item) =>
          [1, 3, 5, 6, 7, 8].includes(item.section_id)
        );

        const sectionsWithTotalZeroTextArray = filteredData
          .filter((item) => item.total === 0)
          .map((item) => sectionTextMap[item.section_id]);

        const uniqueSectionsWithTotalZeroTextArray = [
          ...new Set(sectionsWithTotalZeroTextArray),
        ];
        const sectionsWithTotalZeroText =
          uniqueSectionsWithTotalZeroTextArray.join(", ");

        if (
          uniqueSectionsWithTotalZeroTextArray.includes("About You") &&
          uniqueSectionsWithTotalZeroTextArray.includes("Income & Expenses") &&
          uniqueSectionsWithTotalZeroTextArray.includes("Assets & Liabilities")
        ) {
          sessionStorage.setItem("showAboutYouToast", "1");
          window.location.href =
            process.env.PUBLIC_URL + "/datagathering/about-you";
        } else if (uniqueSectionsWithTotalZeroTextArray.includes("About You")) {
          sessionStorage.setItem("showAboutYouToast", "1");
          window.location.href =
            process.env.PUBLIC_URL + "/datagathering/about-you";
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
  const setDate = (date, dateType) => {
    setLiabilityEndDate(moment(date).format("DD/MM/YYYY"));
    if (dateType == "assetPurchaseDate") {
      assetsDetails.asset_purchase_date = moment(date).format("DD/MM/YYYY");
    }
    if (dateType == "assetendDate") {
      assetsDetails.asset_mf_end_date = moment(date).format("DD/MM/YYYY");
    }
    if (dateType == "maturityDate") {
      assetsDetails.asset_maturity_date = moment(date).format("DD/MM/YYYY");
    }
    setAssetsDetails({
      ...assetsDetails,
      asset_isperpetual: "0",
      isEditable: true,
    });
  };

  function dateDiff(date) {
    date = date.split("/");
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();

    const yy = parseInt(date[2]);
    const mm = parseInt(date[1]);
    const dd = parseInt(date[0]);

    let years, months, days;

    // months
    months = mm - month;
    if (day > dd) {
      months = months - 1;
    }

    // years
    years = yy - year;
    if (mm * 100 + dd < month * 100 + day) {
      years = years - 1;
      months = months + 12;
    }

    // days
    days = Math.floor(
      new Date(year + years, month + months - 1, day).getTime() /
      (24 * 60 * 60 * 1000) -
      today.getTime()
    );

    months = months + years * 12;

    return months;
  }

  function PMT(ir, np, pv, fv, type) {
    if (np != "") {
      np = dateDiff(np);
      np = np + 1;
      if (np == 1) {
        np = 2;
      }
      var pmt, pvif;

      fv || (fv = 0);
      type || (type = 0);

      if (ir === 0) return (pv + fv) / np;

      pvif = Math.pow(1 + ir, np);
      pmt = (-ir * pv * (pvif + fv)) / (pvif - 1);

      if (type === 1) pmt /= 1 + ir;

      return parseFloat(-pmt.toFixed(2)) == Infinity
        ? 0
        : parseFloat(-pmt.toFixed(2));
    }
  }

  useEffect(() => {
    setLiabilityCurrentEmi("");
    let emi_amt = 0;
    if (
      check_liabilityoutstandingamount !== currentOutStandingAmount ||
      check_liabilityenddate !== liabilityEndDate ||
      check_emi_rate !== liabilityEmiIntRateIncrease
    ) {
      setNotCalculated("0");
    }
    if (
      liabilityEmiIntRateIncrease &&
      liabilityEndDate &&
      currentOutStandingAmount &&
      not_calculated === "0"
    ) {
      emi_amt = PMT(
        (liabilityEmiIntRateIncrease * 0.01) / 12,
        liabilityEndDate,
        currentOutStandingAmount
      );

      if (`${emi_amt}`.length > 9) {
        emi_amt = Math.round(emi_amt);
      }
      setLiabilityCurrentEmi(emi_amt);
      setCheckEmi(emi_amt);
    }
  }, [
    liabilityEmiIntRateIncrease,
    liabilityEndDate,
    currentOutStandingAmount,
    not_calculated,
  ]);



  const checksession = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };
      let session_data = await apiCall(url, data, true, false);
      getFamilyMembers();
      getSchemeData();
      getEquitySharesData();
      getUSEquityData();
      // getbankdata();
      getConnectedBroker(session_data);

      if (session_data.error_code == "100") {
        setSession(session_data);
        checkprofile(session_data);
        var liability_payload = await apiCall(
          BASE_API_URL +
          "restapi/getuserliabilities/" +
          "?user_id=" +
          Buffer.from(
            commonEncode.encrypt(session_data.data.id.toString())
          ).toString("base64") +
          "&fp_log_id=" +
          Buffer.from(commonEncode.encrypt(fpLogId.toString())).toString(
            "base64"
          ) +
          "&web=1"
        );
        getfpgoalsdata(fpLogId);
        getRentalIncome(fpLogId);
        getCryptoData();
        var retirement_date = moment(session_data.data.user_details.dob)
          .add(session_data.data.user_details.retirement_age, "y")
          .format("DD/MM/YYYY");
        var life_expectancy_date = moment(session_data.data.user_details.dob)
          .add(session_data.data.user_details.life_expectancy, "y")
          .format("DD/MM/YYYY");
        setRetirmentDate(retirement_date);
        setLifeExpentancyDate(life_expectancy_date);
        var liability_res = liability_payload;
        getAssetData(fpLogId, 0);
        setAddForm(true);
        setUpdateForm(false);
        if (liability_res["error_code"] == "100") {
          setLiabilityData(liability_res.data);
        } else {
          setLiabilityData([]);
        }
      } else {
        loginRedirectGuest();
      }
    } catch (error) {
      console.log(error);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something Went Wrong1");
    }
  };

  const getSchemeData = async () => {
    try {
      let url = ADVISORY_GET_SCHEME_DATA;
      let getschemeData = await getSchemeDataStorage();
      if (getschemeData["error_code"] == "100") {
        setSchemeData(getschemeData["data"]["Liquid"]);

        setDebtfunds(getschemeData.data.Debt);
        setGoldfunds(getschemeData.data.Gold);
        setLiquidfunds(getschemeData.data.Liquid);
        setEQFunds(getschemeData.data.Equity);
      } else {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
      }
    } catch (err) {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  // get US quity data from api
  const getUSEquityData = async () => {
    try {
      const payload = {
        url: ADVISORY_GET_US_EQUITY_API_URL,
        data: "",
        method: "get",
      };
      let resp = await fetchData(payload);

      if (resp["error_code"] == "100") {
        setUSEquity(resp.data);
      }
      // else {
      //   toastr.options.positionClass = "toast-bottom-left";
      //   toastr.error("Something went wrong");
      // }
    } catch (err) {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const getCryptoData = async () => {
    try {
      let url = ADVISORY_GET_CRYPTO_DATA;
      let getcryptoData = await apiCall(url, {}, false, false);
      if (getcryptoData["status"] == "success") {
        var cryptolist = [];
        var crypto_data = Object.values(getcryptoData["data"]);
        var price = 0;
        crypto_data.map((v) => {
          if (v.hasOwnProperty("INR")) {
            if (v.INR.hasOwnProperty("sellPrice")) {
              price = parseFloat(v.INR.sellPrice).toFixed(2);
            }
          }
          cryptolist.push({ label: v.name, value: price });
        });
        setCryptoData(cryptolist);
      } else {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const getEquitySharesData = async () => {
    try {
      let url = ADVISORY_GET_EQUITY_SHARES_DATA;
      let getequitysharesdata = await apiCall(url, "", true, false);
      if (getequitysharesdata["error_code"] == "100") {
        setEquityShares(getequitysharesdata);
      } else {
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
      }
    } catch (err) {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const showToastMessage = async () => {
    toastr.options.positionClass = "toast-bottom-left";
    toastr.error("As there is no value available for Linkages, you cannot link any goal");
  }

  const getAssetData = async (fplogid, check_first_save = 0) => {
    try {
      let url = ADVISORY_GET_ASSETS_API;
      let log_id = await getParentFpLogId();
      let payload = {
        update_share: "1",
        filter_id: "0",
        user_id: getParentUserId(),
        fp_log_id: log_id,
      };
      setIsDataLoading(true);
      let getassetData = await apiCall(url, payload, true, false);
      await new Promise((resolve, reject) => setTimeout(resolve, 1000));
      setIsDataLoading(false);

      getCDSLBrokers(getassetData["data"]);

      if (getassetData["error_code"] == "100") {
        asset_data.current = getassetData["data"];
        var assetLength = getassetData["data"].length;
        setAssetsData(getassetData["data"]);
        const checkboxfilteredData = getassetData["data"].filter(item => item.asset_sub_category_id !== 125);
        // const shouldExclude = checkboxfilteredData.length > 0 ? checkboxfilteredData.every(item => ['cams', 'karvy'].includes(item.asset_ecas_type)) : false;
        // setCheckboxShouldExcludel(shouldExclude)
        setUnfilteredAssetsData(getassetData["data"]);
        const totasset = getassetData["data"].filter(asset => asset.asset_sub_category_id !== 125);
        setfilteredAssetsDataCheck(totasset)

        // This is for those Alternate assets, which are linkable i.e user has selected consider this in automated linkage as yes
        const subCategoryIds = [119, 125];
        const alternateFilteredData = getassetData["data"].filter(item =>
          item.asset_category_id === 41 &&
          item.asset_islinkable == "1" &&
          item.asset_member_id != 0 &&
          !subCategoryIds.includes(item.asset_sub_category_id)
        );
        var resultAlternateArray = alternateFilteredData.map(item => ({
          asset_id: item.id,
          value: item.asset_sub_category_id,
          label: item.subcategorydetail,
          asset_recurring: item.asset_isrecurring,
          asset_amount: item.asset_isrecurring === '1' || item.asset_isrecurring === true ? item.asset_amount : 0,
          asset_member_id: item.asset_member_id,
          total_invested_value: item.totalpurchasevalue,
          total_current_value: item.totalinvestedvalue,
          total_linked_goals_value: item.linked_goals_id && item.linked_goals_id.length > 0 ? 1 : 0
        }));
        var newResultAlternateArray = [];
        for (var i = 0; i < resultAlternateArray.length; i++) {
          if (newResultAlternateArray.findIndex(v => v.asset_id == resultAlternateArray[i].asset_id) == -1) {
            newResultAlternateArray.push(resultAlternateArray[i]);
          }
        }
        setAlternateFilteredAssetsData((prev) => ({ ...prev, select_subclass: newResultAlternateArray, all_subclass: resultAlternateArray }))

        // This is for those Gold assets, which are linkable i.e user has selected consider this in automated linkage as yes
        const goldFilteredData = getassetData["data"].filter(item =>
          item.asset_category_id === 42 &&
          item.asset_islinkable == "1" &&
          item.asset_member_id != 0 &&
          !subCategoryIds.includes(item.asset_sub_category_id)
        );

        var resultGoldArray = goldFilteredData.map(item => ({
          asset_id: item.id,
          value: item.asset_sub_category_id,
          label: item.subcategorydetail,
          asset_recurring: item.asset_isrecurring,
          asset_amount: item.asset_isrecurring === '1' || item.asset_isrecurring === true ? item.asset_amount : 0,
          asset_member_id: item.asset_member_id,
          total_invested_value: item.totalpurchasevalue,
          total_current_value: item.totalinvestedvalue,
          total_linked_goals_value: item.linked_goals_id && item.linked_goals_id.length > 0 ? 1 : 0
        }));
        var newResultGoldArray = [];
        for (var i = 0; i < resultGoldArray.length; i++) {
          if (newResultGoldArray.findIndex(v => v.asset_id == resultGoldArray[i].asset_id) == -1) {
            newResultGoldArray.push(resultGoldArray[i]);
          }
        }
        setGoldFilteredAssetsData((prev) => ({ ...prev, select_subclass: newResultGoldArray, all_subclass: resultGoldArray }))


        // This is for those Liquid assets, which are linkable i.e user has selected consider this in automated linkage as yes
        const liquidFilteredData = getassetData["data"].filter(item =>
          item.asset_category_id === 40 &&
          item.asset_islinkable == "1" &&
          item.asset_member_id != 0 &&
          !subCategoryIds.includes(item.asset_sub_category_id)
        );

        var resultLiquidArray = liquidFilteredData.map(item => ({
          asset_id: item.id,
          value: item.asset_sub_category_id,
          label: item.subcategorydetail,
          asset_recurring: item.asset_isrecurring,
          asset_amount: item.asset_isrecurring === '1' || item.asset_isrecurring === true ? item.asset_amount : 0,
          asset_member_id: item.asset_member_id,
          total_invested_value: item.totalpurchasevalue,
          total_current_value: item.totalinvestedvalue,
          total_linked_goals_value: item.linked_goals_id && item.linked_goals_id.length > 0 ? 1 : 0
        }));
        var newResultLiquidArray = [];
        for (var i = 0; i < resultLiquidArray.length; i++) {
          if (newResultLiquidArray.findIndex(v => v.asset_id == resultLiquidArray[i].asset_id) == -1) {
            newResultLiquidArray.push(resultLiquidArray[i]);
          }
        }
        setLiquidFilteredAssetsData((prev) => ({ ...prev, select_subclass: newResultLiquidArray, all_subclass: resultLiquidArray }))


        // This is for those Real Estate assets, which are linkable i.e user has selected consider this in automated linkage as yes
        const realEstateFilteredData = getassetData["data"].filter(item =>
          item.asset_category_id === 39 &&
          item.asset_islinkable == "1" &&
          item.asset_member_id != 0 &&
          !subCategoryIds.includes(item.asset_sub_category_id)
        );

        var resultRealEstateArray = realEstateFilteredData.map(item => ({
          asset_id: item.id,
          value: item.asset_sub_category_id,
          label: item.subcategorydetail,
          asset_recurring: item.asset_isrecurring,
          asset_amount: item.asset_isrecurring === '1' || item.asset_isrecurring === true ? item.asset_amount : 0,
          asset_member_id: item.asset_member_id,
          total_invested_value: item.totalpurchasevalue,
          total_current_value: item.totalinvestedvalue,
          total_linked_goals_value: item.linked_goals_id && item.linked_goals_id.length > 0 ? 1 : 0
        }));
        var newResultRealEstateArray = [];
        for (var i = 0; i < resultRealEstateArray.length; i++) {
          if (newResultRealEstateArray.findIndex(v => v.asset_id == resultRealEstateArray[i].asset_id) == -1) {
            newResultRealEstateArray.push(resultRealEstateArray[i]);
          }
        }
        setRealEstateFilteredAssetsData((prev) => ({ ...prev, select_subclass: newResultRealEstateArray, all_subclass: resultRealEstateArray }))


        // This is for those Equity assets, which are linkable i.e user has selected consider this in automated linkage as yes
        const equityFilteredData = getassetData["data"].filter(item =>
          item.asset_category_id === 29 &&
          item.asset_islinkable == "1" &&
          item.asset_member_id != 0 &&
          !subCategoryIds.includes(item.asset_sub_category_id)
        );

        var resultEquityArray = equityFilteredData.map(item => ({
          asset_id: item.id,
          value: item.asset_sub_category_id,
          label: item.subcategorydetail,
          asset_recurring: item.asset_isrecurring,
          asset_amount: item.asset_isrecurring === '1' || item.asset_isrecurring === true ? item.asset_amount : 0,
          asset_member_id: item.asset_member_id,
          total_invested_value: item.totalpurchasevalue,
          total_current_value: item.totalinvestedvalue,
          total_linked_goals_value: item.linked_goals_id && item.linked_goals_id.length > 0 ? 1 : 0
        }));
        var newResultEquityArray = [];
        for (var i = 0; i < resultEquityArray.length; i++) {
          if (newResultEquityArray.findIndex(v => v.asset_id == resultEquityArray[i].asset_id) == -1) {
            newResultEquityArray.push(resultEquityArray[i]);
          }
        }
        setEquityFilteredAssetsData((prev) => ({ ...prev, select_subclass: newResultEquityArray, all_subclass: resultEquityArray }))
        // setAddForm(true);
        // setUpdateForm(false);
        setAssetEditId("");
        simpleValidator.current.hideMessages();
        setForceUpdate((v) => ++v);
        if (check_first_save == 1 && assetLength == 1) {
          localStorage.setItem("assetLiabilityCookie", 1);
          dispatch({ type: "RELOAD_SIDEBAR", payload: true });
        } else {
          localStorage.removeItem("assetLiabilityCookie");
          dispatch({ type: "RELOAD_SIDEBAR", payload: true });
        }
      } else {
        dispatch({ type: "RELOAD_SIDEBAR", payload: true });
        toastr.options.positionClass = "toast-bottom-left";
        toastr.error("Something went wrong");
      }
    } catch (err) {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error(err);
    }
  };

  const getFamilyMembers = async () => {
    try {
      let data = {
        user_id: getParentUserId(),
        is_direct: "1",
      };

      let member_data = await apiCall(GET_MEMBER_LIST, data, true, false);

      if (member_data.error_code == "100") {
        var member_array = [];
        var filter_member = [];
        var members = member_data["data"];
        members.map((member) => {
          if (member.parent_user_id == 0) {
            member_array.push({ value: member.fp_user_id, label: "Self", retirement_age: member.retirement_age, dob: member.dob, life_expectancy: member.life_expectancy, isdependent: member.isdependent });
            filter_member.push({ value: member.fp_user_id, label: "Self" });
            setLiabilityForMember(member.fp_user_id);
            setAssetForMember(member.fp_user_id);
            setAssetsDetails({
              ...assetsDetails,
              asset_member_id: member.fp_user_id,
            });
            if (
              [36, 119, 37, 77, 34, 31, 30, 35, 32, 33, 74, 123, 70, 71, 72, 62, 53, 55, 52, 56, 51, 59].includes(assetsDetails.asset_sub_category_id)
            ) {
              member_array.push({ value: 0, label: "Family", retirement_age: member.retirement_age, dob: member.dob, life_expectancy: member.life_expectancy, isdependent: member.isdependent });
            }
            filter_member.push({ value: 0, label: "Family" });
          } else {
            member_array.push({
              value: member.fp_user_id,
              label: member.NAME + " " + member.last_name,
              retirement_age: member.retirement_age,
              dob: member.dob,
              life_expectancy: member.life_expectancy,
              isdependent: member.isdependent
            });
            filter_member.push({
              value: member.fp_user_id,
              label: member.NAME + " " + member.last_name,
            });
          }
        });

        setFamilyData(member_array);
        setMemberData(filter_member)
      } else {
        setFamilyData([]);
        setMemberData([]);
      }
    } catch { }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdateForm(false);
    if (liabilityName == "") {
      setLiabilityNameError("Please enter the liabiity name");
    }
    if (currentOutStandingAmount == "") {
      setCurrentOutStandingAmountError(
        "Please enter current outstanding amount"
      );
    }
    if (liabilityCurrentEmi == "") {
      setLiabilityCurrentEmiError("Please enter the liability current emi");
    }
    if (liabilityCurrentEmi <= 0) {
      setLiabilityCurrentEmiError("Please enter EMI amount");
    }
    if (liabilityCurrentEmi.toString().length >= 10) {
      setLiabilityCurrentEmiError("Please enter less than 10 digits");
    }
    if (liabilityEndDate == null) {
      setLiabilityEndDateError("Please enter liability end date");
    }

    if (
      liabilityName != "" &&
      currentOutStandingAmount != "" &&
      liabilityCurrentEmi != "" &&
      liabilityEndDate != null &&
      liabilityCurrentEmi.toString().length < 10 &&
      liabilityCurrentEmi != 0 &&
      liabilityCurrentEmi > 0
    ) {
      saveliability();
    }
  };

  const updateFormData = async (e) => {
    setUpdateForm(true);
    e.preventDefault();
    if (liabilityName == "") {
      setLiabilityNameError("Please enter the liabiity name");
    }
    if (currentOutStandingAmount == "") {
      setCurrentOutStandingAmountError(
        "Please enter current outstanding amount"
      );
    }
    if (liabilityCurrentEmi == "") {
      setLiabilityCurrentEmiError("Please enter the liability current emi");
    }
    if (liabilityCurrentEmi <= 0) {
      setLiabilityCurrentEmiError("Please enter EMI amount");
    }
    if (liabilityCurrentEmi.toString().length >= 10) {
      setLiabilityCurrentEmiError("Please enter less than 10 digits");
    }
    if (liabilityEndDate == null) {
      setLiabilityEndDateError("Please enter liability end date");
    }

    if (
      liabilityName != "" &&
      currentOutStandingAmount != "" &&
      liabilityCurrentEmi != "" &&
      liabilityEndDate != null &&
      liabilityCurrentEmi.toString().length < 10 &&
      liabilityCurrentEmi != 0 &&
      liabilityCurrentEmi > 0
    ) {
      updateliability();
    }
  };

  const saveliability = async () => {
    try {
      let session_data = session;

      var payload = {
        liability_category_id: liabilityCatId,
        liability_frequency: liabilityFrequency,
        liability_emi_rate: liabilityEmiIntRateIncrease,
        liability_name: liabilityName,
        user_id: session_data.data.id,
        fp_log_id: session_data.data.fp_log_id,
        liability_member_id: liabilityForMember,
        liability_asset_id: "",
        current_emi: liabilityCurrentEmi,
        liability_emi: "",
        liability_outstanding_amount: currentOutStandingAmount,
        liability_end_date: liabilityEndDate,
        liability_footnote: liabilityRemarks,
      };

      var payload = commonEncode.encrypt(JSON.stringify(payload));
      var res = await apiCall(
        BASE_API_URL + "restapi/saveupdateliability/",
        payload,
        false,
        false
      );

      let decodes_res = JSON.parse(commonEncode.decrypt(res));
      if ((decodes_res.error_code = "100")) {
        scrollToList();
        getfpfamilydata();
        var filteredCategories = liability_option.filter((lib) => {
          return lib.id === liabilityCatId;
        });
        var msg = liabilityName ? " - " + liabilityName : " ";
        toastr.options.positionClass = "toast-bottom-left";
        toastr.success(
          filteredCategories[0]["title"] + msg + " saved successfully"
        );

        setCurrentOutStandingAmount("");
        setSliderValue(7);
        setLiabilityCurrentEmi("");
        setLiabilityEndDate(null);
        setLiabilityFrequency("1");
        setLiabilityForMember(session_data.data.fp_user_id);
        setLiabilityRemarks("");
        setLiabilityEmiIntRateIncrease(7);
        setLiabilityCatId("");
        setLiabilityCurrentEmiError("");
        setLiabilityEndDateError("");
        setLiabilityNameError("");
        setLiabilityName("Business Loan");
        setSelectedOption1("Business Loan");
        setCurrentOutStandingAmountError("");
        setAddForm(true);
        setUpdateForm(false);
      } else {
        toast.error("Oops something went wrong!!", {
          position: "bottom-left",
        });
      }
    } catch (e) {
      console.log(e);
      toast.error("Something Went Wrong", {
        position: "bottom-left",
      });
    }
  };

  const updateliability = async () => {
    try {
      let session_data = session;

      var payload = {
        liability_member_id: liabilityForMember,
        liability_category_id: liabilityCatId,
        liability_frequency: liabilityFrequency,
        liability_emi_rate: liabilityEmiIntRateIncrease,
        liability_name: liabilityName,
        user_id: liabGetData.user_id,
        fp_log_id: liabGetData.fp_log_id,
        liability_emi: liabGetData.liability_emi,
        Created_By: liabGetData.Created_By,
        Created_Datetime: liabGetData.Created_Datetime,
        Updated_By: liabGetData.Updated_By,
        Updated_Datetime: liabGetData.Updated_Datetime,
        categoryname: liabGetData.categoryname,
        id: liabGetData.id,
        liability_asset_id: liabGetData.liability_asset_id,
        liability_end_date: liabilityEndDate,
        liability_footnote: liabGetData.liability_footnote,
        liability_isActive: liabGetData.liability_isActive,
        liability_outstanding_amount: currentOutStandingAmount,
        liability_principle: liabGetData.liability_principle,
        liability_start_date: liabGetData.liability_start_date,
        liability_years_remaining: liabGetData.liability_years_remaining,
        membername: liabGetData.membername,
        related_asset_name: liabGetData.related_asset_name,
        current_emi: liabilityCurrentEmi,
        liability_id: liabGetData.id,
      };
      var payload = commonEncode.encrypt(JSON.stringify(payload));
      var res = await apiCall(
        BASE_API_URL + "restapi/saveupdateliability/",
        payload,
        false,
        false
      );

      let decodes_res = JSON.parse(commonEncode.decrypt(res));
      if ((decodes_res.error_code = "100")) {
        getfpfamilydata();
        scrollToList();
        var category_name = [liabGetData].filter((lib) => {
          return lib.id === decodes_res["data"]["liability_id"];
        });

        var msg = category_name[0]["liability_name"]
          ? " - " + category_name[0]["liability_name"]
          : "";
        toastr.success(
          category_name[0]["categoryname"] + msg + " updated successfully"
        );

        setCurrentOutStandingAmount("");
        setSliderValue(7);
        setLiabilityCurrentEmi("");
        setLiabilityEndDate(null);
        setLiabilityFrequency("1");
        setLiabilityForMember(session_data.data.fp_user_id);
        setLiabilityRemarks("");
        setLiabilityEmiIntRateIncrease(7);
        setLiabilityCatId("");
        setLiabilityCurrentEmiError("");
        setLiabilityEndDateError("");
        setLiabilityNameError("");
        setCurrentOutStandingAmountError("");
        setAddForm(true);
        setUpdateForm(false);
      } else {
        toast.error("Oops something went wrong!!", {
          position: "bottom-left",
        });
      }
    } catch (e) {
      console.log(e);
      toast.error("Something Went Wrong", {
        position: "bottom-left",
      });
    }
  };

  const getfpfamilydata = async () => {
    try {
      let session_data = session;
      var family_payload = await apiCall(
        BASE_API_URL +
        "restapi/getfpfamilydata/" +
        "?parent_user_id=" +
        Buffer.from(
          commonEncode.encrypt(session_data.data.id.toString())
        ).toString("base64") +
        "&fp_log_id=" +
        Buffer.from(
          commonEncode.encrypt(session_data.data.fp_log_id.toString())
        ).toString("base64") +
        "&web=1"
      );
      getfpuserdata();
    } catch (e) {
      console.log(e);
    }
  };

  const getfpuserdata = async () => {
    try {
      let session_data = session;

      var user_payload = await apiCall(
        BASE_API_URL +
        "restapi/getfpuserdata/" +
        "?fp_user_id=" +
        Buffer.from(
          commonEncode.encrypt(session_data.data.fp_user_id.toString())
        ).toString("base64") +
        "&fp_log_id=" +
        Buffer.from(
          commonEncode.encrypt(session_data.data.fp_log_id.toString())
        ).toString("base64") +
        "&user_id=" +
        Buffer.from(
          commonEncode.encrypt(session_data.data.id.toString())
        ).toString("base64") +
        "&web=1"
      );
      getuserliability();
    } catch (e) {
      console.log(e);
    }
  };

  const getuserliability = async () => {
    try {
      let session_data = session;

      var liability_payload = await apiCall(
        BASE_API_URL +
        "restapi/getuserliabilities/" +
        "?user_id=" +
        Buffer.from(
          commonEncode.encrypt(session_data.data.id.toString())
        ).toString("base64") +
        "&fp_log_id=" +
        Buffer.from(
          commonEncode.encrypt(session_data.data.fp_log_id.toString())
        ).toString("base64") +
        "&web=1"
      );

      var liability_res = liability_payload;
      if (liability_res["error_code"] == "100") {
        setLiabilityData(liability_res.data);
      } else {
        setLiabilityData([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getConnectedBroker = async (session_data) => {
    try {
      var paylod = {
        url: ADVISORY_FETCH_CONNECTED_BROKER,
        method: "post",
        data: {
          user_id: session_data.data.id,
          fp_log_id: session_data.data.fp_log_id,
        },
      };
      const fetchdata = await fetchEncryptData(paylod);
      if (fetchdata.error_code == "100") {
        // setBrokerData(fetchdata.data);
        setEquityHoldingsData(fetchdata.equity_holdings);
        dispatch({
          type: "ASSETS_UPDATE",
          payload: true,
        });
      }

      // if (!onload) makeconnection(fp_holding_auth_id);
    } catch (e) {
      console.log(e);
    }
  };

  const getuserliabilities = async (id) => {
    try {
      let liability_id = id;
      setLiabilityId(id);

      var getliab_payload = await apiCall(
        BASE_API_URL +
        "restapi/getuserliabilities/" +
        "?liability_id= " +
        Buffer.from(commonEncode.encrypt(liability_id.toString())).toString(
          "base64"
        ) +
        "&web=1"
      );

      setLiabilityCurrentEmiError("");
      setLiabilityEndDateError(null);
      setCurrentOutStandingAmountError("");

      var lib_res = getliab_payload;
      if (lib_res["error_code"] == "100") {
        setLiabGetData(lib_res["data"]["0"]);
        if (lib_res.data.length > 0) {
          toastr.options.positionClass = "toast-bottom-left";
          var msg = lib_res["data"]["0"]["liability_name"]
            ? " - " + lib_res["data"]["0"]["liability_name"]
            : " ";
          toastr.success(
            " You can now edit details for " +
            lib_res["data"]["0"]["categoryname"] +
            msg
          );
          scrollToForm();
          setLiabilityName(lib_res["data"]["0"]["liability_name"]);
          setCurrentOutStandingAmount(
            lib_res["data"]["0"]["liability_outstanding_amount"]
          );
          setLiabilityForMember(lib_res["data"]["0"]["liability_member_id"]);
          setLiabilityEndDate(lib_res["data"]["0"]["liability_end_date"]);
          setLiabilityEmiIntRateIncrease(
            lib_res["data"]["0"]["liability_emi_rate"]
          );
          setLiabilityCurrentEmi(lib_res["data"]["0"]["liability_emi"]);
          setLiabilityFrequency(lib_res["data"]["0"]["liability_frequency"]);
          setSelectedOption1(lib_res["data"]["0"]["categoryname"]);
          setSliderValue(lib_res["data"]["0"]["liability_emi_rate"]);
          setLiabilityCatId(lib_res["data"]["0"]["liability_category_id"]);
        }
      } else {
        setLiabilityData([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCategoryClick = (liab, type) => {
    let session_data = session;
    if (type == "liability") {
      setLiabilityName(liab.title);
      setLiabilityCatId(liab.id);
      setLiabilityEndDate(null);
      setCurrentOutStandingAmount("");
      setLiabilityCurrentEmi("");
      setLiabilityRemarks("");
      setLiabilityForMember(session_data.data.fp_user_id);
      setLiabilityEmiIntRateIncrease(7);
      setLiabilityFrequency("1");
      setAddForm(true);
      setUpdateForm(false);
      setSliderValue(7);
      setLiabilityCurrentEmiError("");
      setLiabilityEndDateError("");
      setLiabilityNameError("");
      setCurrentOutStandingAmountError("");
    }
  };

  const cancelFormData = (e) => {
    e.preventDefault();
    var filteredCategories = liability_option.filter((lib) => {
      return lib.id === liabilityCatId;
    });
    toastr.success(
      "Liabilities - " +
      filteredCategories[0]["title"] +
      " Cancelled Successfully"
    );
    let session_data = session;
    setLiabilityName(e.title);
    setLiabilityCatId(e.id);
    setLiabilityEndDate(null);
    setCurrentOutStandingAmount("");
    setLiabilityCurrentEmi("");
    setLiabilityRemarks("");
    setSliderValue(7);
    setLiabilityForMember(session_data.data.fp_user_id);
    setLiabilityEmiIntRateIncrease(7);
    setLiabilityFrequency("1");
    setUpdateForm(false);
    setAddForm(true);
  };

  const handleClose = (type, form) => {
    if (type == "yes" && form == "liability") {
      removeliability(liabilityId);
      // deleteassets(assetsId);
    } else if (type == "yes" && form == "asset") {
      deleteAssetData(assetsId);
    } else {
      setShow(false);
    }
  };

  const removeliability = async (id) => {
    try {
      let session_data = session;

      var remove_payload = {
        liability_id: id,
        user_id: session_data.data.id,
        fp_log_id: session_data.data.fp_log_id,
      };

      var rem_paylod = commonEncode.encrypt(JSON.stringify(remove_payload));
      var rem_res = await apiCall(
        BASE_API_URL + "restapi/removeliability/",
        rem_paylod,
        false,
        false
      );
      let decoded_res = JSON.parse(commonEncode.decrypt(rem_res));
      if (decoded_res) {
        if (decoded_res.error_code == "100") {
          toastr.options.positionClass = "toast-bottom-left";
          var msg = liabilityName ? " - " + liabilityName : " ";
          toastr.success(
            selectedOption1 + msg + " has been deleted successfully"
          );
          getfpfamilydata();
          setShow(false);
          setCurrentOutStandingAmount("");
          setSliderValue(7);
          setLiabilityCurrentEmi("");
          setLiabilityEndDate(null);
          setLiabilityFrequency("1");
          setLiabilityForMember(session_data.data.fp_user_id);
          setLiabilityRemarks("");
          setLiabilityEmiIntRateIncrease(7);
          setLiabilityCatId("");
          setLiabilityCurrentEmiError("");
          setLiabilityEndDateError("");
          setLiabilityNameError("");
          setLiabilityName("Business Loan");
          setSelectedOption1("Business Loan");
          setCurrentOutStandingAmountError("");
          setAddForm(true);
          setUpdateForm(false);
        } else {
          toastr.options.positionClass = "toast-bottom-left";
          toastr.error(decoded_res.data);
          setShow(false);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const fetchFilter = () => {
    setOpenPanel(true);
  };
  function handleResize() {
    if (window.innerWidth < 768) {
      setSidePanelWidth(100);
    } else {
      setSidePanelWidth(30);
    }
  }

  const closeFilter = () => {
    setCategory(0)
    setFilterName("")
    setOpenPanel(false);
    setSelectedBrokers([]);
  }
  console.log("equityHoldingsDataequityHoldingsData", equityHoldingsData);

  function applyFilter(e) {


    if (subtab == "individual") {
      setOpenPanel(false);
      $("#no-result-msg").text("");
      var filter_name = "";
      var filterdata = [];
      // var asset_datas = asset_data.current;
      if (asset_data.current && Array.isArray(asset_data.current)) {
        var asset_datas = asset_data.current.filter(item => item.subcategorydetail != "Multi Assets Linkage");
        // Filter the array to separate objects with asset_ecas_type equal to "cams" or "karvy"
        let camsAndKarvyAssets = asset_datas.filter(item => item.asset_ecas_type === "cams" || item.asset_ecas_type === "karvy");
        // Filter the array to exclude objects with asset_ecas_type equal to "cams" or "karvy"
        let otherAssets = asset_datas.filter(item => item.asset_ecas_type !== "cams" && item.asset_ecas_type !== "karvy");
        // Concatenate the arrays to combine them with cams and karvy assets at the end
        let sortedData = [...otherAssets, ...camsAndKarvyAssets];
        var asset_datas = sortedData
      }
      if (category == 0) {
        filterdata = selectedMember.length
          ? asset_datas.filter(
            (v) => selectedMember.indexOf(v.asset_member_id) > -1
          )
          : asset_datas;
        if (filterdata.length == 0) {
          $("#no-result-msg").text("No Results Found");
        }
        filter_name = selectedMember.length ? "Member" : "";
        setFilterName(filter_name);
      } else if (category == 1) {
        var shares = [30]; // for shares
        setFilterName(filterText);
        var filterAfterCategory = asset_datas.filter(
          (v) => shares.indexOf(v.asset_sub_category_id) > -1
        );
        var afterFilterData = selectedMember.length
          ? filterAfterCategory.filter(
            (v) => selectedMember.indexOf(v.asset_member_id) > -1
          )
          : filterAfterCategory;
        if (selectedSubcategory.length > 0) {
          filterdata = afterFilterData.filter((v) => {
            if (
              selectedSubcategory.includes("External") &&
              (v.asset_ecas_type == "cdsl" || v.asset_ecas_type == "nsdl" || v.asset_ecas_type == "cdsldemat" || v.asset_ecas_type == "broker")
            ) {
              return v;
            } else if (
              selectedSubcategory.includes("Manual") &&
              v.asset_ecas_type == "manual" &&
              (v.asset_sub_category_id == "30")
            ) {
              return v;
            } else if (
              selectedSubcategory.includes("All") &&
              v.asset_sub_category_id == "30"
            ) {
              return v;
            }

          });
        } else {
          filterdata = afterFilterData;
        }
        if (filterdata.length == 0) {
          $("#no-result-msg").text("No Results Found");
        }
      } else if (category == 2) {
        var mf = [77, 31, 71, 62]; // for Mutual funds
        setFilterName(filterText);
        filterAfterCategory = asset_datas.filter(
          (v) => mf.indexOf(v.asset_sub_category_id) > -1
        );

        var afterFilterData = selectedMember.length
          ? filterAfterCategory.filter(
            (v) => selectedMember.indexOf(v.asset_member_id) > -1
          )
          : filterAfterCategory;

        if (selectedSubcategory.length > 0) {
          filterdata = afterFilterData.filter((v) => {
            if (
              selectedSubcategory.includes("Internal") &&
              (v.asset_ecas_type == "cams" || v.asset_ecas_type == "karvy")
            ) {
              return v;
            } else if (
              selectedSubcategory.includes("External") &&
              (v.asset_ecas_type == "ecas" || v.asset_ecas_type == "cdsldemat")
            ) {
              return v;
            } else if (
              selectedSubcategory.includes("Manual") &&
              v.asset_ecas_type == "manual" &&
              (v.asset_sub_category_id == "31" ||
                v.asset_sub_category_id == "77" ||
                v.asset_sub_category_id == "71" ||
                v.asset_sub_category_id == "62")
            ) {
              return v;
            } else if (
              selectedSubcategory.includes("All") &&
              (v.asset_sub_category_id == "31" ||
                v.asset_sub_category_id == "77" ||
                v.asset_sub_category_id == "71" ||
                v.asset_sub_category_id == "62")
            ) {
              return v;
            }
          });
        } else {
          filterdata = afterFilterData;
        }

        if (filterdata.length == 0) {
          $("#no-result-msg").text("No Results Found");
        }
      } else {
        filterAfterCategory = asset_datas.filter(
          (v) => v.asset_category_id == category
        );
        setFilterName(filterText);

        var filterAfterSubCategory = selectedSubcategory.length
          ? filterAfterCategory.filter(
            (v) => selectedSubcategory.indexOf(v.asset_sub_category_id) > -1
          )
          : filterAfterCategory;

        // filterdata = selectedMember.length
        //   ? filterAfterSubCategory.filter(
        //     (v) => selectedMember.indexOf(v.asset_member_id) > -1
        //   )
        //   : filterAfterSubCategory;
        // if (filterdata.length == 0) {
        //   $("#no-result-msg").text("No Results Found");
        // }
        let filterAfterMembers = selectedMember.length
          ? filterAfterSubCategory.filter(
            (v) => selectedMember.indexOf(v.asset_member_id) > -1
          )
          : filterAfterSubCategory;
        console.log("selectedBrokers", selectedBrokers, selectedBrokers.length, selectedSubcategory);
        console.log("filterAfterMembers", filterAfterMembers);

        // filter by broker ID
        var filterdata = selectedBrokers.length
          ? filterAfterMembers.filter(
            (v) => selectedBrokers.indexOf(v.asset_broker_id) > -1
          )
          : filterAfterMembers;

        console.log("filterdata", filterdata);

        setFilterBroker(true);
        console.log("equityHoldingsData", equityHoldingsData);
        var equityFilteredData = equityHoldingsData.length
          ? equityHoldingsData.filter(
            (v) => {
              var brokerIds = v.broker_ids ? v.broker_ids.split(",") : [];
              if (selectedBrokers.length > 0) {
                for (let i = 0; i < selectedBrokers.length; i++) {
                  if (brokerIds.indexOf(selectedBrokers[i]) > -1) {
                    return true;
                  }
                }
                return false;
              } else {
                return v;
              }

            }
          ) : equityHoldingsData;

        console.log("equityFilteredData", equityFilteredData);

        var equityMemberData = selectedMember.length
          ? equityFilteredData.filter(
            (v) => {
              var member_id = v.member_id;
              return selectedMember.indexOf(v.member_id) > -1;
            }
          )
          : equityFilteredData;

        // setEquityHoldingsData(equityFilteredholdings);
        setEquityFilteredHoldings(equityMemberData);

        if (filterdata.length == 0) {
          $("#no-result-msg").text("No Results Found");
        }
      }

      var activesip = 0;
      for (var i = 0; i < filterdata.length; i++) {
        if (
          filterdata[i]["asset_isrecurring"] == "1" &&
          (filterdata[i]["asset_sub_category_id"] == "31" ||
            filterdata[i]["asset_sub_category_id"] == "77" ||
            filterdata[i]["asset_sub_category_id"] == "71" ||
            filterdata[i]["asset_sub_category_id"] == "62")
        ) {
          activesip = Number(filterdata[i]["asset_amount"]) + activesip;
        }
      }
      var totalactivesip = activesip;
      setTotalActiveSIP(totalactivesip);
      setAssetsData(filterdata);
    }
    else {
      setOpenPanel(false);
      $("#no-result-msgg").text("");
      var filter_name = "";
      var filterdata = [];
      if (asset_data.current && Array.isArray(asset_data.current)) {
        var asset_datas = asset_data.current.filter(item => item.subcategorydetail === "Multi Assets Linkage");
      }
      const assetTypeMappings = {
        "Art Investment": "64",
        "Commodity": "36",
        "Currency": "37",
        "Vintage/ Luxury Cars": "66",
        "Others (Category 1)": "67",
        "Gold ETF": "70",
        "Gold Mutual Fund": "71",
        "Physical Gold": "69",
        "Sovereign Gold Bonds": "72",
        "Others (Category 2)": "73",
        "Bank Balance": "61",
        "Liquid Funds": "62",
        "Others (Category 3)": "63",
        "Agricultural Land": "53",
        "Commercial": "52",
        "Land": "56",
        "Residential Premises": "51",
        "Under Construction Property": "55",
        "Others (Category 4)": "59",
        "ESOPs": "34",
        "Equity Mutual Funds": "31",
        "Equity Shares": "30",
        "Future & Options": "35",
        "PMS": "32",
        "Unlisted / AIF Equity": "33",
        "Others (Category 5)": "74",
        "US Equity": "123",
      };

      asset_datas.forEach(asset => {
        const assetName = asset.asset_name;
        if (assetTypeMappings.hasOwnProperty(assetName)) {
          asset.asset_sub_category_id = assetTypeMappings[assetName];
        }
      });
      // var asset_datas = asset_data.current;
      if (category == 0) {
        filterdata = selectedMember.length
          ? asset_datas.filter(
            (v) => selectedMember.indexOf(v.asset_member_id) > -1
          )
          : asset_datas;
        if (filterdata.length == 0) {
          $("#no-result-msgg").text("No Results Found");
        }
        filter_name = selectedMember.length ? "Member" : "";
        setFilterName(filter_name);
      } else if (category == 1) {
        var shares = ["30"]; // for shares
        setFilterName(filterText);
        var filterAfterCategory = asset_datas.filter(
          (v) => shares.indexOf(v.asset_sub_category_id) > -1
        );

        filterdata = selectedMember.length
          ? filterAfterCategory.filter(
            (v) => selectedMember.indexOf(v.asset_member_id) > -1
          )
          : filterAfterCategory;
        if (filterdata.length == 0) {
          $("#no-result-msgg").text("No Results Found");
        }
      } else if (category == 2) {
        var mf = ["31", "71", "62"]; // for Mutual funds
        setFilterName(filterText);
        filterAfterCategory = asset_datas.filter(
          (v) => mf.indexOf(v.asset_sub_category_id) > -1
        );

        var afterFilterData = selectedMember.length
          ? filterAfterCategory.filter(
            (v) => selectedMember.indexOf(v.asset_member_id) > -1
          )
          : filterAfterCategory;

        if (selectedSubcategory.length > 0) {
          filterdata = afterFilterData.filter((v) => {
            if (
              selectedSubcategory.includes("Internal") &&
              (v.asset_ecas_type == "cams" || v.asset_ecas_type == "karvy")
            ) {
              return v;
            } else if (
              selectedSubcategory.includes("External") &&
              (v.asset_ecas_type == "ecas" || v.asset_ecas_type == "cdsldemat")
            ) {
              return v;
            } else if (
              selectedSubcategory.includes("Manual") &&
              v.asset_ecas_type == "manual" &&
              (v.asset_sub_category_id == "31" ||
                // v.asset_sub_category_id == "77" ||
                v.asset_sub_category_id == "71" ||
                v.asset_sub_category_id == "62")
            ) {
              return v;
            } else if (
              selectedSubcategory.includes("All") &&
              (v.asset_sub_category_id == "31" ||
                // v.asset_sub_category_id == "77" ||
                v.asset_sub_category_id == "71" ||
                v.asset_sub_category_id == "62")
            ) {
              return v;
            }
          });
        } else {
          filterdata = afterFilterData;
        }

        if (filterdata.length == 0) {
          $("#no-result-msgg").text("No Results Found");
        }
      } else {
        filterAfterCategory = asset_datas.filter(
          (v) => v.asset_category_id == category
        );
        setFilterName(filterText);
        var filterAfterSubCategory = selectedSubcategory.length
          ? filterAfterCategory.filter(
            (v) => selectedSubcategory.indexOf(v.asset_sub_category_id) > -1
          )
          : filterAfterCategory;

        filterdata = selectedMember.length
          ? filterAfterSubCategory.filter(
            (v) => selectedMember.indexOf(v.asset_member_id) > -1
          )
          : filterAfterSubCategory;
        if (filterdata.length == 0) {
          $("#no-result-msgg").text("No Results Found");
        }
      }


      var activesip = 0;
      for (var i = 0; i < filterdata.length; i++) {
        if (
          filterdata[i]["asset_isrecurring"] == "1" &&
          (filterdata[i]["asset_sub_category_id"] == "31" ||
            // filterdata[i]["asset_sub_category_id"] == "77" ||
            filterdata[i]["asset_sub_category_id"] == "71" ||
            filterdata[i]["asset_sub_category_id"] == "62")
        ) {
          activesip = Number(filterdata[i]["asset_sip_amount"]) + activesip;
        }
      }
      var totalactivesip = activesip;
      setTotalActiveSIP(totalactivesip);
      setAssetsData(filterdata);
    }
  }

  function resetFilter(e) {
    setFilterBroker(false);
    setSelectedBrokers([]);
    setSelectedCategories([])
    setOpenPanel(false);
    setCategory(0);
    setSelectedSubcategory([]);
    setSelectedMember([]);
    setFilterText("");
    setFilterName("");
    setAssetsData(asset_data.current);
    $("#no-result-msg").text("");
    $("#no-result-msgg").text("");
    var activesip = 0;
    var data = asset_data.current;
    for (var i = 0; i < data.length; i++) {
      if (
        data[i]["asset_isrecurring"] == "1" &&
        (data[i]["asset_sub_category_id"] == "31" ||
          data[i]["asset_sub_category_id"] == "77" ||
          data[i]["asset_sub_category_id"] == "71" ||
          data[i]["asset_sub_category_id"] == "62")
      ) {
        activesip = Number(data[i]["asset_amount"]) + activesip;
      }
    }
    var totalactivesip = activesip;
    setTotalActiveSIP(totalactivesip);
  }

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setSidePanelWidth(100);
      } else {
        setSidePanelWidth(30);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    applyFilter();
    setSelectedCategories([])
  }, [asset_data.current, subtab]);

  useEffect(() => {
    setSelectedSubcategory([]);
    setSelectedMember([]);
  }, [category]);

  // function calculateTotalAssets() {
  //   if (!assetsData) return 0;

  //   return assetsData.reduce((total, asset) => {
  //     const investedValue = parseFloat(asset.totalinvestedvalue);
  //     return total + investedValue;
  //   }, 0);
  // }


  function calculateTotalAssets() {
    if (subtab == "individual") {
      if (!assetsData) return 0;
      return assetsData.filter(v => v.subcategorydetail != "Multi Assets Linkage").reduce((total, asset) => {
        const investedValue = parseFloat(asset.totalinvestedvalue);
        return total + investedValue;
      }, 0);
    }
    else {
      if (!assetsData) return 0;
      return assetsData.filter(v => v.subcategorydetail == "Multi Assets Linkage").reduce((total, asset) => {
        const investedValue = parseFloat(asset.asset_amount);
        return total + investedValue;
      }, 0);
    }
  }

  const scrollToForm = () => {
    // const { offsetTop } = document.getElementById("formBox");
    var body = document.body,
      html = document.documentElement;

    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    window.scroll(0, height);
  };

  const scrollToList = () => {
    window.scroll({ top: 0 });
  };

  // const scrolltoSubCategory = () => {
  //   const {offsetTop} = cntRef.current;
  //   window.scroll({top: offsetTop + 80});
  // }

  function calculateTotalLiabilities() {
    if (!liabilityData) return 0;

    return liabilityData.reduce((total, liab) => {
      const outstandingamount = parseFloat(liab.liability_outstanding_amount);
      return total + outstandingamount;
    }, 0);
  }

  useEffect(() => {
    var calculatePurchase = 0.0;
    var calculatecurrent = 0.0;
    var calculateror = 0;

    if (document.getElementById("unitPrice-error")) {
      document.getElementById("unitPrice-error").innerHTML = "";
    }

    if (
      assetsDetails.asset_units != "" &&
      assetsDetails.asset_purchase_amount != ""
    ) {
      if (assetsDetails.asset_sub_category_id == "119") {
        calculatePurchase =
          parseFloat(assetsDetails.asset_units).toFixed(4) *
          parseFloat(assetsDetails.asset_purchase_amount).toFixed(4);
      } else {
        calculatePurchase =
          parseFloat(assetsDetails.asset_units).toFixed(2) *
          parseFloat(assetsDetails.asset_purchase_amount).toFixed(2);
      }
    }
    if (
      assetsDetails.asset_current_unit_price != "" &&
      assetsDetails.asset_units != ""
    ) {
      if (assetsDetails.asset_sub_category_id == "119") {
        calculatecurrent =
          parseFloat(assetsDetails.asset_units).toFixed(4) *
          parseFloat(assetsDetails.asset_current_unit_price).toFixed(4);
      } else {
        calculatecurrent =
          parseFloat(assetsDetails.asset_units).toFixed(2) *
          parseFloat(assetsDetails.asset_current_unit_price).toFixed(2);
      }
    }

    if (
      assetsDetails.asset_units != "" &&
      assetsDetails.asset_purchase_amount != ""
    ) {
      if (assetsDetails.asset_sub_category_id == "123") {
        calculatePurchase =
          parseFloat(assetsDetails.asset_units).toFixed(4) *
          parseFloat(assetsDetails.asset_purchase_amount).toFixed(4);
      } else {
        // calculatePurchase = parseFloat(assetsDetails.asset_units).toFixed(2) * parseFloat(assetsDetails.asset_purchase_amount).toFixed(2);
      }
    }
    if (
      assetsDetails.asset_current_unit_price != "" &&
      assetsDetails.asset_units != ""
    ) {
      if (assetsDetails.asset_sub_category_id == "123") {
        calculatecurrent =
          parseFloat(assetsDetails.asset_units).toFixed(4) *
          parseFloat(assetsDetails.asset_current_unit_price).toFixed(4);
      } else {
        calculatecurrent =
          parseFloat(assetsDetails.asset_units).toFixed(2) *
          parseFloat(assetsDetails.asset_current_unit_price).toFixed(2);
      }
    }

    if (Number.isNaN(calculatePurchase)) {
      calculatePurchase = 0.0;
    }
    if (Number.isNaN(calculatecurrent)) {
      calculatecurrent = 0.0;
    }
    if (calculatePurchase && calculatecurrent) {
      calculateror =
        ((parseFloat(calculatecurrent).toFixed(2) -
          parseFloat(calculatePurchase).toFixed(2)) /
          parseFloat(calculatePurchase).toFixed(2)) *
        100;
    }
    if (
      assetsDetails.asset_isrecurring == true &&
      assetsDetails.asset_purchase_amount &&
      assetsDetails.asset_amount
    ) {
      calculateror =
        ((calculatecurrent - assetsDetails.asset_purchase_amount) /
          assetsDetails.asset_purchase_amount) *
        100;
    }

    if (Number.isNaN(calculateror)) {
      calculateror = 0.0;
    }

    if (assetsDetails.asset_sub_category_id == "119") {
      assetsDetails.totalpurchasevalue =
        parseFloat(calculatePurchase).toFixed(2);
      assetsDetails.totalinvestedvalue =
        parseFloat(calculatecurrent).toFixed(2);
    } else {
      assetsDetails.totalpurchasevalue =
        parseFloat(calculatePurchase).toFixed(2);
      assetsDetails.totalinvestedvalue =
        parseFloat(calculatecurrent).toFixed(2);
    }

    // if (
    //   (assetsDetails.asset_category_id == 38 &&
    //     assetsDetails.asset_sub_category_id == 77) ||
    //   assetsDetails.asset_category_id != 38
    // ) {
    //   if (assetsDetails.asset_sub_category_id != 119) {
    //     var asset_abretrun = parseFloat(calculateror).toFixed(2);
    //     assetsDetails.asset_ror = parseFloat(asset_abretrun).toFixed(2);
    //   }
    // }
    if (
      assetsDetails.asset_sub_category_id == 36 ||
      assetsDetails.asset_sub_category_id == 37
    ) {
      assetsDetails.totalpurchasevalue = calculatePurchase.toFixed(2);
      assetsDetails.totalinvestedvalue = calculatecurrent.toFixed(2);
    }

    setAssetsDetails({
      ...assetsDetails,
      totalpurchasevalue: assetsDetails.totalpurchasevalue,
      totalinvestedvalue: assetsDetails.totalinvestedvalue,
    });
  }, [
    assetsDetails.asset_units,
    assetsDetails.asset_purchase_amount,
    assetsDetails.asset_current_unit_price,
  ]);

  useEffect(() => {
    if (session.data) {

      let calculatematurityamount = 0.0;
      const assetMaturityDate =
        assetsDetails.asset_maturity_date ||
        moment(session.data.user_details.dob)
          .add(session.data.user_details.retirement_age, "y")
          .format("DD/MM/YYYY");

      if (
        assetsDetails.asset_sub_category_id === 75 ||
        assetsDetails.asset_sub_category_id === 76 ||
        assetsDetails.asset_sub_category_id === 78 ||
        assetsDetails.asset_sub_category_id === 79 ||
        assetsDetails.asset_sub_category_id === 80 ||
        assetsDetails.asset_sub_category_id === 84 ||
        assetsDetails.asset_sub_category_id === 87
      ) {
        if (
          assetsDetails?.asset_maturity_date &&
          assetsDetails?.asset_purchase_date
        ) {
          const mf_yr_f = assetsDetails.asset_maturity_date.split("/");
          const c_yr = assetsDetails?.asset_purchase_date;
          const c_yr_f = c_yr.split("/");
          const firstDate = new Date(
            parseInt(mf_yr_f[2]),
            parseInt(mf_yr_f[1]) - 1,
            parseInt(mf_yr_f[0])
          );
          const secondDate = new Date(
            parseInt(c_yr_f[2]),
            parseInt(c_yr_f[1]) - 1,
            parseInt(c_yr_f[0])
          );
          const timeDiff = Math.abs(firstDate.getTime() - secondDate.getTime());
          const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
          const nper = parseFloat((diffDays / 365.2425).toFixed(2));

          calculatematurityamount = fv(
            assetsDetails.asset_ror,
            nper,
            0,
            assetsDetails.asset_purchase_amount
          );

          if (assetsDetails.asset_payout_type === "2" || assetsDetails.asset_payout_type === 2) {
            calculatematurityamount = assetsDetails.asset_purchase_amount;
          } else {
            calculatematurityamount =
              calculatematurityamount > 999999999
                ? Math.round(calculatematurityamount)
                : calculatematurityamount.toFixed(2);
          }
        }
      } else if (
        assetsDetails.asset_sub_category_id === 81 ||
        assetsDetails.asset_sub_category_id === 85 ||
        assetsDetails.asset_sub_category_id === 86 ||
        assetsDetails.asset_sub_category_id === 118 ||
        assetsDetails.asset_sub_category_id === 120
      ) {
        if (assetMaturityDate) {
          const c_yr = session["data"]["plan_date"];
          const c_yr_date = new Date(c_yr);
          const mf_yr_f = assetMaturityDate.split("/");
          const adatetosplit = assetMaturityDate;
          const firstDate = new Date(
            parseInt(mf_yr_f[2]),
            parseInt(mf_yr_f[1]) - 1,
            parseInt(mf_yr_f[0])
          );
          const secondDate = c_yr_date;
          const timeDiff = Math.abs(firstDate.getTime() - secondDate.getTime());
          const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
          const nper = parseFloat((diffDays / 365.2425).toFixed(2));
          const month_var = [0, 12, 4, 2, 1];

          if (assetsDetails.asset_purchase_amount) {
            const pmt =
              parseInt(assetsDetails.asset_purchase_amount) *
              month_var[assetsDetails.asset_frequency];

            calculatematurityamount = fv(
              assetsDetails.asset_ror,
              nper,
              pmt,
              assetsDetails.asset_current_unit_price
            );

            calculatematurityamount =
              calculatematurityamount > 999999999
                ? Math.round(calculatematurityamount)
                : calculatematurityamount.toFixed(2);
            if (parseInt(assetsDetails.asset_purchase_amount) == 0) {
              calculatematurityamount = 0;
            }
          }
        }
      } else if (assetsDetails.asset_sub_category_id === 82) {
        if (assetsDetails.asset_current_unit_price) {
          calculatematurityamount =
            (15 *
              assetsDetails.asset_current_unit_price *
              assetsDetails.annual_growth_rate) /
            26;

          calculatematurityamount =
            calculatematurityamount > 999999999
              ? Math.round(calculatematurityamount)
              : calculatematurityamount.toFixed(2);
        }
      } else if (assetsDetails.asset_sub_category_id === 117) {
        if (assetMaturityDate) {
          const c_yr = session["data"]["plan_date"];
          const c_yr_date = new Date(c_yr);

          if (typeof c_yr === "undefined") {
            c_yr_date = new Date("d/m/Y");
          }

          calculateMaturityAmount();
        }
      }
      if (assetsDetails.asset_purchase_amount == "") {
        setAssetsDetails((prev) => ({
          ...prev,
          asset_maturity_amt: Number(0),
        }));
      }

      if (
        calculatematurityamount !== 0 &&
        calculatematurityamount !== null &&
        calculatematurityamount !== undefined
      ) {
        setAssetsDetails((prev) => ({
          ...prev,
          asset_maturity_amt: Number(calculatematurityamount),
        }));
      } else {
        setAssetsDetails((prev) => ({
          ...prev,
          asset_maturity_amt: Number(0),
        }));
      }


      if (assetEditId) {
        if (selectedGoals === "Automated Linkage") {
          setAutoMatedGoal(true);
          setSelectedGoals("Automated Linkage");
        } else {
          setAutoMatedGoal(false);
        }
      } else {
        setAutoMatedGoal(true);
        setSelectedGoals("Automated Linkage");
      }

      if (session && !assetEditId) {
        setGoalSelected(false);
        setSelectedGoalsId([]);
        setSelectedPriorityArray([]);
        getfpgoalsdata(session.data.fp_log_id);
      }
    }
  }, [
    assetsDetails?.asset_purchase_amount,
    assetsDetails?.asset_purchase_date,
    assetsDetails?.asset_maturity_date,
    assetsDetails?.asset_payout_type,
    assetsDetails?.asset_ror,
    assetsDetails?.asset_frequency,
    assetsDetails?.asset_current_unit_price,
    assetsDetails?.annual_growth_rate,
    assetsDetails?.employee_contribution,
    assetsDetails?.employer_contribution,
    assetsDetails?.isEditable,
  ]);

  useEffect(() => {
    if (liabilityName != "") {
      setLiabilityNameError("");
    }
    if (currentOutStandingAmount != "") {
      setCurrentOutStandingAmountError("");
    }
    if (liabilityCurrentEmi != "") {
      setLiabilityCurrentEmiError("");
    }
    if (liabilityEndDate != null) {
      setLiabilityEndDateError("");
    }
    if (liabilityCurrentEmi.toString().length >= 10) {
      setLiabilityCurrentEmiError("Please enter less than 10 digits");
    }
    if (liabilityCurrentEmi.toString().length < 10) {
      setLiabilityCurrentEmiError("");
    }
    if (liabilityCurrentEmi.toString() == "0") {
      setLiabilityCurrentEmiError("Please enter EMI amount");
    }
  }, [
    liabilityName,
    currentOutStandingAmount,
    liabilityCurrentEmi,
    liabilityEndDate,
  ]);

  const calculateMaturityAmount = async () => {
    try {
      const payload = {
        url: ADVISORY_CALCULATE_EPF_MATURITY_AMOUNT,
        data: {
          user_id: session["data"]["id"],
          fp_log_id: fpLogId,
          fp_user_id: assetsDetails.asset_member_id,
          asset_current_unit_price: assetsDetails.asset_current_unit_price,
          asset_ror: assetsDetails.asset_ror,
          employee_monthly_contrib:
            assetsDetails.employee_contribution != ""
              ? assetsDetails.employee_contribution
              : 0,
          employer_monthly_contrib:
            assetsDetails.employer_contribution != ""
              ? assetsDetails.employer_contribution
              : 0,
          annual_growth_rate: assetsDetails.annual_growth_rate,
          asset_maturity_date: assetsDetails.asset_maturity_date,
        },
        method: "post",
      };

      console.log("Payy: ", payload);
      const res = await fetchEncryptData(payload);
      if (res["error_code"] == "100") {

        var calculatematuarityamount = Math.round(JSON.parse(res["data"], 2));

        if (calculatematuarityamount != 0) {
          setAssetsDetails((prev) => ({
            ...prev,
            asset_maturity_amt: calculatematuarityamount,
            totalmaturityamount: calculatematuarityamount,
          }));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const closeModal = () => {
    setGoalSelected(false);
    setSelectedGoalsmodal(false)
  };
  const selectGoals = (goals) => {
    // if (goals.length > 1) {
    //   const nameIndex = goals.indexOf("Automated Linkage");
    //   if (nameIndex != -1) {
    //     setSelectedGoals(goals.splice(nameIndex, 1))
    //   }
    // }
    // if (selectedGoalsId.length > 0) {
    //   const index = selectedGoalsId.indexOf('')
    //   if (index != -1) {
    //     selectedGoalIdArray(selectedGoalsId.splice(index, 1))
    //   }
    // }
    // setSelectedGoals(goals.toString());
    let defaultGoal = "Automated Linkage";
    goals = goals.filter(v => v && v != defaultGoal);
    if (goals.length == 0) {
      goals.push(defaultGoal);
    }
    if (categoryEditId) {
      let a = assetsData.map((v) => {
        if (v.id == categoryEditId) {
          v.selectedGoals = goals.join(", ");
        }
        return v;
      });
      setCategoryEditId(null);
      setAssetsData([...a]);
    } else {
      setSelectedGoals(goals.join(", "));
    }
  };

  const goalsInAssetsData = () => {
    let newArray = "";
    for (let i = 0; i < assetsData.length; i++) {
      if (assetsData[i]['id'] == categoryEditId) {
        newArray = assetsData[i]['selectedGoals'];
        // break;
      }
    }
    return newArray;
    // return "";
  }

  const selectedGoalIdArray = (goalIds) => {
    setSelectedGoalsId(goalIds);
  };

  const switchToggle = async (assetId, assetIsLinkable) => {
    try {
      let url = ADVISORY_UPDATE_MULTI_ASSETS_API;
      let payload = { "asset_islinkable": assetIsLinkable ? '1' : '0', "id": assetId };
      let updateMultiLinkageDataData = await apiCall(url, payload, true, false);
    } catch (err) {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const setPriorityArray = (priorityArray) => {
    setSelectedPriorityArray(priorityArray);
  };
  const setGoalLink = (goalIds) => {
    if (goalIds.length > 0) {
      const idIndex = selectedGoalsId.indexOf('')
      if (idIndex != -1) {
        goalIds.splice(idIndex, 1);
      }
      setAssetsDetails({
        ...assetsDetails,
        asset_goal_link_id: goalIds.join(","),
      });
    }
  };

  useEffect(() => {
    if (!assetsDetails.id) {
      getFamilyMembers();
    }
  }, [assetsDetails.asset_sub_category_id]);

  const Amounttxt = 340000;
  const selectedgoalsdatahandleClose = () => {
    setSelectedGoalsData(false)
  }
  // const selectedgoalsModalhandleClose = () => {
  //   setSelectedGoalsmodal(false)
  // }

  useEffect(() => {
    if (selectedCategories.length === 0) {
      setDeleteToggle(false);
    }
  }, [selectedCategories]);

  const updateMultiAssetLinkageData = async (goal_id, asset_id) => {

    function findMultiLinkageDataById(id) {
      const asset = assetsData.find(asset => asset.id === id);
      return asset ? asset.multi_linkage_data : null;
    }
    const multiLinkageData = findMultiLinkageDataById(asset_id);
    try {
      let url = ADVISORY_UPDATE_MULTI_ASSETS_API;
      let payload = { "asset_goal_link_id": goal_id, "id": asset_id, "multiLinkageData": multiLinkageData };
      let updateMultiLinkageDataData = await apiCall(url, payload, true, false);
    } catch (err) {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  return (
    <DatagatherLayout>
      <FintooLoader isLoading={isLoading} />
      <div className="AssetLib">
        <div className="background-div">
          <div
            className={`bg ${currentUrl.indexOf("datagathering/assets-liabilities") > -1
              ? "active"
              : ""
              }`}
            id="bg-assets"
          ></div>
        </div>
        <div className="white-box">
          <div className={`d-flex justify-content-md-center tab-box DGheaderFix DGheaderFix2 DGheaderFix3`} >
            <div className="d-flex top-tab-menu m-0">
              <div
                className={`tab-menu-item ${tab == "tab1" ? "active" : ""}`}
                onClick={() => {
                  setTab("tab1");
                  setSubTab("individual");
                }
                }
              >
                <div className="tab-menu-title">ASSETS</div>
              </div>
              <div
                className={`tab-menu-item ${tab == "tab2" ? "active" : ""}`}
                onClick={() => setTab("tab2")}
              >
                <div className="tab-menu-title">LIABILITIES</div>
              </div>
            </div>
          </div>

          <div>
            <div className={tab == "tab1" ? "d-block" : "d-none"}>
              <div className="row">
                <div className="col-md-10">
                  <div className="inner-box">
                    <div className="shimmercard br hide" id="assets-shimmer">
                      <div className="wrapper">
                        <div className="comment br animate w80" />
                        <div className="comment br animate" />
                      </div>
                    </div>
                    {/* For Total Assetsss */}
                    <div className={`${subtab == "Category" ? "FixdgHeaderasset2" : "FixdgHeaderasset"}`}>
                      {asset_data.current && asset_data.current.length > 0 && (
                        <div className="mb-2 d-flex align-items-center justify-content-between assetsLiab">
                          <div className="d-flex">
                            <h4 className="total-amt">
                              <span
                                style={{
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                Total Assets:{" "}
                                {/* <br className="d-md-none d-block"/> */}
                                <span className="ms-md-2">
                                  {indianRupeeFormat(calculateTotalAssets())}{" "}
                                </span>
                              </span>
                            </h4>
                            <h4 className="total-amt ms-md-5 ms-2">
                              <span
                                style={{
                                  fontWeight: "600",
                                }}
                              >
                                Active SIP: {indianRupeeFormat(totalacivesip)}
                              </span>
                            </h4>
                          </div>
                          <div className="sorting d-inline-block">
                            {filterName != "" && (
                              <span
                                style={{
                                  // marginLeft: "26rem",
                                  fontWeight: "bold",
                                  marginRight: "1.5rem",
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                {filterName}
                                <img
                                  alt="filter"
                                  onClick={resetFilter}
                                  width={28}
                                  className="ps-2"
                                  src={process.env.REACT_APP_STATIC_URL + "media/DMF/cancel.svg"}
                                />
                              </span>
                            )}
                            <a className="color-blue font-bold sort-by" onClick={fetchFilter} style={{ whiteSpace: "nowrap" }}>
                              Filter By
                              <img
                                alt="filter"
                                width={32}
                                className="ps-2"
                                // src="https://images.minty.co.in/static/assets/img/filter.svg"
                                src="https://images.minty.co.in/static/assets/img/filter.svg"
                              />
                            </a>
                          </div>
                        </div>
                      )}
                      {asset_data.current.length == 1 &&
                        session?.["data"]?.["fp_lifecycle_status"] == 2 && (
                          <div>
                            <p style={{ color: "#F0806D", paddingRight: "20px" }}>
                              Note : In asset, there should be at least one
                              self/spouse's asset.
                            </p>
                          </div>
                        )}
                      <hr style={{
                        padding: "6px 0"
                      }} className="mt-0" />
                      <div className="d-flex align-items-center justify-content-between">
                        {
                          // assetsData && assetsData.length > 0 &&
                          <div className={`d-flex align-items-center top-tab-menu `}>
                            {
                              subtab == "individual" ? <>
                                {/* checkboxShouldExcludel == false && subtab == "individual" ? <> */}
                                <FintooSubCheckbox
                                  checked={selectedCategories.length === assetsData.length}
                                  onChange={() => {
                                    if (selectedCategories.length === assetsData.length) {
                                      setSelectedCategories([]);
                                      setDeleteToggle(false);
                                    } else {
                                      const allIds = assetsData.map(asset => asset.id);
                                      setSelectedCategories(allIds);
                                      setDeleteToggle(true);
                                    }
                                  }}
                                />
                              </> : null
                            }
                            <div
                              className={`tab-menu-item ${subtab == "individual" ? "active" : ""}`}
                              onClick={() => {
                                setSubTab("individual");
                              }
                              }
                            >
                              <div className="tab-menu-title">Individual</div>
                            </div>
                            <div
                              className={`tab-menu-item ${subtab == "Category" ? "active" : ""}`}
                              onClick={() => setSubTab("Category")}
                            >
                              <div className="tab-menu-title">Category</div>
                            </div>
                          </div>
                        }
                        {
                          selectedCategories.length > 0 &&
                          deletetoggle == true && (
                            <div>             {unfilteredAssetsData.length > 1 &&
                              session?.["data"]?.["fp_lifecycle_status"] ==
                              2 && (
                                <span
                                  onClick={() => {
                                    handleShow();
                                    // setAssetsId(asset.id);
                                    // setAssetName(asset.name);
                                    // setCategoryDetail(asset.categorydetail);
                                    // setSubCategoryDetails(
                                    //   asset.subcategorydetail
                                    // );
                                    // setAssetType(asset.asset_type);
                                  }}
                                  style={{
                                    marginRight: "2rem",
                                    cursor: "pointer"
                                  }}
                                  className="opt-options-2 pointer"
                                >
                                  <MdDelete style={{ color: "#042b62", fontSize: "1.6rem" }} />
                                </span>
                              )}
                              {session?.["data"]?.["fp_lifecycle_status"] ==
                                1 && (
                                  <span
                                    onClick={() => {
                                      handleShow();
                                      // setAssetsId(asset.id);
                                      // setAssetName(asset .name);
                                      // setCategoryDetail(asset.categorydetail);
                                      // setSubCategoryDetails(
                                      //   asset.subcategorydetail
                                      // );
                                      // setAssetType(asset.asset_type);
                                    }}
                                    className="opt-options-2 pointer"
                                  >
                                    <MdDelete style={{ color: "#042b62", fontSize: "1.6rem" }} />
                                  </span>
                                )}             </div>
                          )
                        }
                      </div>
                    </div>
                    {/* For Inner Box */}
                    <div className={subtab == "individual" ? "d-block" : "d-none"}>
                      <div>
                        {isDataLoading && (
                          <div>
                            <div className=" inner-container mt-4 pt-4">
                              <div
                                className="shine w-25 mb-1"
                                style={{ height: ".7rem" }}
                              ></div>
                              <div
                                className="shine w-100"
                                style={{ height: ".7rem" }}
                              ></div>
                            </div>
                            <div className=" inner-container mt-4 pt-4">
                              <div
                                className="shine w-25 mb-1"
                                style={{ height: ".7rem" }}
                              ></div>
                              <div
                                className="shine w-100"
                                style={{ height: ".7rem" }}
                              ></div>
                            </div>
                          </div>
                        )}
                        {isDataLoading == false &&
                          assetsData &&
                          assetsData.filter((asset) => asset.subcategorydetail != "Multi Assets Linkage").filter((asset, i) => {
                            if (viewmore1 == 0) {
                              if (i < 10) {
                                return true;
                              } else {
                                return false;
                              }
                            } else {
                              return true;
                            }
                          }).map((asset, index) => (
                            <div className="d-flex align-items-center" key={index}>
                              <div>
                                {asset.asset_ecas_type !== 'cams' && asset.asset_ecas_type !== 'karvy' && (
                                  <FintooSubCheckbox
                                    id={asset.id}
                                    checked={selectedCategories.includes(asset.id)}
                                    title={asset.title}
                                    onChange={() => {
                                      setSelectedCategories((prevSelected) => {
                                        if (prevSelected.includes(asset.id)) {
                                          const updatedSelection = prevSelected.filter((id) => id !== asset.id);
                                          setDeleteToggle(updatedSelection.length > 0); // Check if any checkbox is still selected
                                          return updatedSelection;
                                        } else {
                                          setDeleteToggle(true);
                                          return [...prevSelected, asset.id];
                                        }
                                      });
                                    }}
                                  />
                                )}
                              </div>
                              <div style={{
                                marginLeft: asset.asset_ecas_type !== 'cams' && asset.asset_ecas_type !== 'karvy' ? "0rem" : "2rem"
                              }} key={asset.id} className="inner-container mt-4 w-100">
                                <h4>
                                  {asset.categorydetail}{" "}
                                  {asset.asset_name ? " - " + asset.asset_name : ""}{" "}
                                  <span style={{ fontWeight: "500" }}>
                                    {(asset.asset_ecas_type == "ecas") && "(External)"}
                                    {(asset.asset_ecas_type == "cams" ||
                                      asset.asset_ecas_type == "karvy") &&
                                      "(Internal)"}
                                    {asset.asset_sub_category_id == "30" && (asset.asset_ecas_type == "broker" ||
                                      asset.asset_ecas_type == "cdsl" ||
                                      asset.asset_ecas_type == "nsdl" ||
                                      asset.asset_ecas_type == "cdsldemat") && "(External)"}
                                    {asset.asset_ecas_type == "manual" &&
                                      (asset.asset_sub_category_id == "31" ||
                                        asset.asset_sub_category_id == "77" ||
                                        asset.asset_sub_category_id == "71" ||
                                        asset.asset_sub_category_id == "62" ||
                                        asset.asset_sub_category_id == "30") &&
                                      "(Manual)"}
                                  </span>
                                </h4>
                                <div className="row ">
                                  <div className="col-md-4">
                                    <div className="display-style">
                                      <span>Value:</span>
                                      <p
                                        className="invest-show"
                                        title={indianRupeeFormat(
                                          parseFloat(
                                            asset.totalinvestedvalue ||
                                            asset.totalpurchasevalue
                                          )
                                        )}
                                      >
                                        {indianRupeeFormat(
                                          parseFloat(
                                            asset.totalinvestedvalue ||
                                            asset.totalpurchasevalue
                                          )
                                        )}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="display-style">
                                      <span>Type: </span>
                                      <p>
                                        {asset.subcategorydetail
                                          ? asset.subcategorydetail
                                          : "Not added"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="display-style">
                                      <span>Member: </span>
                                      <p>
                                        {asset.membername1
                                          ? asset.membername1
                                          : "Not added"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="opt-options">
                                      <span>
                                        <BsPencilFill
                                          onClick={() => {
                                            editAssetData(
                                              asset.id,
                                              asset.asset_type
                                                ? asset.asset_type
                                                : "none"
                                            );
                                            scrollToForm();
                                            // getAssetData(fpLogId, 0);
                                            setAddForm(false);
                                            setUpdateForm(true);
                                            setAssetEditId(asset.id);
                                          }}
                                        />
                                      </span>

                                      {/* {unfilteredAssetsData.length > 1 &&
                                   session?.["data"]?.["fp_lifecycle_status"] ==
                                   2 && (
                                     <span
                                       onClick={() => {
                                         handleShow();
                                         setAssetsId(asset.id);
                                         setAssetName(asset.name);
                                         setCategoryDetail(asset.categorydetail);
                                         setSubCategoryDetails(
                                           asset.subcategorydetail
                                         );
                                         setAssetType(asset.asset_type);
                                       }}
                                       className="opt-options-2"
                                     >
                                       <MdDelete />
                                     </span>
                                   )}
                                 {session?.["data"]?.["fp_lifecycle_status"] ==
                                   1 && (
                                     <span
                                       onClick={() => {
                                         handleShow();
                                         setAssetsId(asset.id);
                                         setAssetName(asset.name);
                                         setCategoryDetail(asset.categorydetail);
                                         setSubCategoryDetails(
                                           asset.subcategorydetail
                                         );
                                         setAssetType(asset.asset_type);
                                       }}
                                       className="opt-options-2"
                                     >
                                       <MdDelete />
                                     </span>
                                   )} */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        {(isDataLoading == false &&
                          assetsData &&
                          assetsData.filter((asset) => asset.subcategorydetail != "Multi Assets Linkage").filter((asset, i) => {
                            if (viewmore1 == 0) {
                              if (i < 10) {
                                return true;
                              } else {
                                return false;
                              }
                            } else {
                              return true;
                            }
                          }).length === 0) && subtab == "individual" && (
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: "17px",
                                fontWeight: "bold",
                              }}
                            >No records found</div>
                          )}

                        {asset_data.current && asset_data.current.length > 0 && (
                          <div
                            id="no-result-msg"
                            style={{
                              textAlign: "center",
                              fontSize: "17px",
                              fontWeight: "bold",
                              display: 'none'
                            }}
                          >
                          </div>
                        )}
                        <div className="DataGridViewform" style={{
                          padding: "0"
                        }}>
                          {assetsData &&
                            assetsData.filter((asset) => asset.subcategorydetail != "Multi Assets Linkage").length > 10 && (
                              <button onClick={() => setViewMore1(viewmore1 === 0 ? 1 : 0)}>
                                {viewmore1 === 0 ? "View All" : "View Less"}
                              </button>
                            )}
                        </div>
                      </div>
                      <div className="mt-4 removefixheaderasset">
                        <div>
                          <div className="accordion">
                            <div className="accordion-panel active" id="assetbox">
                              <div className="accordion-header d-flex justify-content-between">
                                <h4 className="accordion-heading">
                                  <img
                                    alt="Asset"
                                    className="accordian-img"
                                    src={
                                      imagePath +
                                      "/static/assets/img/assets-liabilities/asset-top.svg"
                                    }
                                  />
                                  <span
                                    style={
                                      {
                                        // fontWeight: "500",
                                        // paddingTop: "2em",
                                      }
                                    }
                                  >
                                    Add Assets
                                  </span>
                                </h4>
                                <div
                                  onClick={() => setShowView(!showview)}
                                  className={`${DGstyles.HideSHowicon} hideShowIconCustom`}
                                >
                                  {showview == true ? <>-</> : <>+</>}
                                </div>
                              </div>
                              {showview && (
                                <div
                                  className={`accordion-content  family ${DGstyles.bgAssetLib}`}
                                >
                                  <div className="">
                                    <div className="container-fluid ">
                                      <div className="row">
                                        <div className="col-md-10">
                                          <label className="category-label-title">
                                            Category
                                          </label>
                                          <ul className="card-list ">
                                            {options.map((v, i) => (
                                              <React.Fragment key={i}>
                                                <li
                                                  onClick={() => {
                                                    setGoalSelected(false);
                                                    setSelectedGoals(
                                                      "Automated Linkage"
                                                    );
                                                    setSelectedGoalsId([]);
                                                    setSelectedPriorityArray([]);
                                                    setAutoMatedGoal(true);

                                                    setAssetsDetails({
                                                      ...defaultAssetDetails,
                                                      categorydetail: v.title,
                                                      asset_category_id: v.id,
                                                      asset_name: v.options["0"]
                                                        ? v.options["0"].title
                                                        : v.title,
                                                      subcategorydetail:
                                                        v.options["0"]?.title,
                                                      asset_sub_category_id: v.options[
                                                        "0"
                                                      ]
                                                        ? v.options["0"].id
                                                        : v.id,
                                                      user_id:
                                                        session["data"]["user_details"][
                                                        "user_id"
                                                        ],
                                                      fp_log_id:
                                                        session["data"]["user_details"][
                                                        "fp_log_id"
                                                        ],
                                                      asset_member_id:
                                                        familyData["0"].value,
                                                    });
                                                    simpleValidator.current.hideMessages();
                                                    setForceUpdate((v) => ++v);
                                                    setSelectedOption(v.title);

                                                    if (v.options.length === 0) {
                                                      setSelectedSubOption("");
                                                    } else {
                                                      setSelectedSubOption(
                                                        v.options["0"].title
                                                      );
                                                    }

                                                    setAddForm(true);
                                                    setUpdateForm(false);
                                                    getfpgoalsdata(
                                                      session["data"]["user_details"][
                                                      "fp_log_id"
                                                      ]
                                                    );
                                                  }}
                                                  className={`li-options ${selectedOption == v.title
                                                    ? "active"
                                                    : ""
                                                    }`}
                                                >
                                                  <label title={v.title}>
                                                    <img src={v.img} />
                                                    <span>{v.title}</span>
                                                  </label>
                                                </li>
                                              </React.Fragment>
                                            ))}
                                          </ul>
                                          <hr />
                                          <span>
                                            <label className="">{selectedOption}</label>
                                          </span>
                                          <ul
                                            className="card-list child "
                                            id="alternate"
                                          >
                                            {options
                                              .filter((v) => v.title == selectedOption)
                                              .map((v, z) => (
                                                <React.Fragment key={z}>
                                                  {v.options.map((x, i) => (
                                                    <React.Fragment key={i}>
                                                      <li
                                                        onClick={() => {
                                                          let ror = 0;
                                                          let freq = "1";
                                                          let growth_rate = "10";
                                                          let asset_maturity_date =
                                                            null;
                                                          let asset_mf_end_date =
                                                            moment(
                                                              retirmentdate,
                                                              "DD/MM/YYYY"
                                                            ).format("DD/MM/YYYY");

                                                          let tmp_asset_name = x.title;
                                                          let asset_currency = false;

                                                          switch (x.id) {
                                                            case 82:
                                                              growth_rate = "5";
                                                              break;
                                                            case 81:
                                                            case 85:
                                                            case 86:
                                                            case 118:
                                                            case 120:
                                                              ror =
                                                                x.id === 81
                                                                  ? 7.1
                                                                  : x.id === 118
                                                                    ? 10
                                                                    : 6.8;
                                                              freq = "4";
                                                              if (x.id != 118) {
                                                                asset_maturity_date =
                                                                  moment(retirmentdate)
                                                                    .add(
                                                                      retirmentdate,
                                                                      "y"
                                                                    )
                                                                    .format(
                                                                      "MM/DD/YYYY"
                                                                    );
                                                              }

                                                              break;
                                                            case 84:
                                                              ror = 6.8;
                                                              break;
                                                            case 117:
                                                              asset_maturity_date =
                                                                moment(retirmentdate)
                                                                  .add(
                                                                    retirmentdate,
                                                                    "y"
                                                                  )
                                                                  .format("MM/DD/YYYY");
                                                              ror = 8.1;
                                                              break;
                                                            case 123:
                                                              tmp_asset_name = "";
                                                              asset_mf_end_date =
                                                                moment(
                                                                  "31/12/2099",
                                                                  "DD/MM/YYYY"
                                                                ).format("DD/MM/YYYY");
                                                              break;
                                                            default:
                                                              ror = 0;
                                                              break;
                                                          }

                                                          setAssetsDetails({
                                                            ...defaultAssetDetails,
                                                            // asset_name: x.title,
                                                            asset_name: tmp_asset_name,
                                                            asset_currency:
                                                              asset_currency,
                                                            subcategorydetail: x.title,
                                                            categorydetail: v.title,
                                                            asset_sub_category_id: x.id,
                                                            asset_category_id: v.id,
                                                            asset_mf_end_date:
                                                              asset_mf_end_date,
                                                            asset_maturity_date:
                                                              asset_maturity_date,
                                                            asset_ror: ror,
                                                            asset_frequency: freq,
                                                            annual_growth_rate:
                                                              growth_rate,
                                                            user_id:
                                                              session["data"][
                                                              "user_details"
                                                              ]["user_id"],
                                                            fp_log_id:
                                                              session["data"][
                                                              "user_details"
                                                              ]["fp_log_id"],
                                                            asset_member_id:
                                                              familyData["0"].value,
                                                          });
                                                          setSelectedSubOption(x.title);
                                                          simpleValidator.current.hideMessages();
                                                          setForceUpdate((v) => ++v);
                                                          setAssetEditId("");
                                                          setAddForm(true);
                                                          setUpdateForm(false);
                                                          setGoalSelected(false);
                                                          setSelectedGoals(
                                                            "Automated Linkage"
                                                          );
                                                          setSelectedGoalsId([]);
                                                          setSelectedPriorityArray([]);
                                                          setAutoMatedGoal(true);
                                                          getfpgoalsdata(
                                                            session["data"][
                                                            "user_details"
                                                            ]["fp_log_id"]
                                                          );
                                                        }}
                                                        className={`li-options ${selectedSubOption == x.title
                                                          ? "active"
                                                          : ""
                                                          }`}
                                                      >
                                                        <label htmlFor="alternate-1">
                                                          <img
                                                            alt="Assets art investment"
                                                            src={x.img}
                                                          />
                                                          <span>{x.title}</span>
                                                        </label>
                                                      </li>
                                                    </React.Fragment>
                                                  ))}
                                                </React.Fragment>
                                              ))}
                                          </ul>
                                        </div>
                                      </div>

                                      <div className="forms-container col-md-12">
                                        {selectedOption == "Alternate" && (
                                          <>
                                            <Alternate
                                              filteredAssetsData={alternateFilteredAssetsData}
                                              familyData={familyData}
                                              assetsDetails={assetsDetails}
                                              setAssetsDetails={setAssetsDetails}
                                              goalData={goalData}
                                              setDate={setDate}
                                              cryptodata={cryptodata}
                                              session={session}
                                              addForm={addForm}
                                              updateForm={updateForm}
                                              addAssetsSubmit={addAssetsSubmit}
                                              cancelAssetForm={cancelAssetForm}
                                              updateAssetsSubmit={updateAssetsSubmit}
                                              assetEditId={assetEditId}
                                              unchangedgoaldata={unchangedgoaldata}
                                              getfpgoalsdata={getfpgoalsdata}
                                              setGoalSelected={setGoalSelected}
                                              closeModal={closeModal}
                                              selectGoals={selectGoals}
                                              selectedGoals={selectedGoals}
                                              selectedGoalIdArray={selectedGoalIdArray}
                                              selectedGoalsId={selectedGoalsId}
                                              setPriorityArray={setPriorityArray}
                                              selectedPriorityArray={
                                                selectedPriorityArray
                                              }
                                              setAutoMatedGoal={setAutoMatedGoal}
                                              isAutoMatedGoal={isAutoMatedGoal}
                                              setGoalLink={setGoalLink}
                                              isGoalSelected={isGoalSelected}
                                              setSelectedGoals={setSelectedGoals}
                                              setSelectedGoalsId={setSelectedGoalsId}
                                              setSelectedPriorityArray={
                                                setSelectedPriorityArray
                                              }
                                              setTab={setTab}
                                            />
                                          </>
                                        )}

                                        {selectedOption == "Debt" && (
                                          <>
                                            <AssetDebt
                                              familyData={familyData}
                                              assetsDetails={assetsDetails}
                                              setAssetsDetails={setAssetsDetails}
                                              goalData={goalData}
                                              setDate={setDate}
                                              debtfunds={debtfunds}
                                              session={session}
                                              addForm={addForm}
                                              updateForm={updateForm}
                                              addAssetsSubmit={addAssetsSubmit}
                                              cancelAssetForm={cancelAssetForm}
                                              updateAssetsSubmit={updateAssetsSubmit}
                                              assetEditId={assetEditId}
                                              unchangedgoaldata={unchangedgoaldata}
                                              getfpgoalsdata={getfpgoalsdata}
                                              setGoalSelected={setGoalSelected}
                                              closeModal={closeModal}
                                              selectGoals={selectGoals}
                                              selectedGoals={selectedGoals}
                                              selectedGoalIdArray={selectedGoalIdArray}
                                              selectedGoalsId={selectedGoalsId}
                                              setPriorityArray={setPriorityArray}
                                              selectedPriorityArray={
                                                selectedPriorityArray
                                              }
                                              setAutoMatedGoal={setAutoMatedGoal}
                                              isAutoMatedGoal={isAutoMatedGoal}
                                              setGoalLink={setGoalLink}
                                              isGoalSelected={isGoalSelected}
                                              setSelectedGoals={setSelectedGoals}
                                              setSelectedGoalsId={setSelectedGoalsId}
                                              setSelectedPriorityArray={
                                                setSelectedPriorityArray
                                              }
                                              retirementGoalID={retirementGoalID}
                                              setTab={setTab}
                                            />
                                          </>
                                        )}

                                        {selectedOption == "Equity" && (
                                          <>
                                            <AssetEquity
                                              equityFilteredHoldings={equityFilteredHoldings}
                                              filterBroker={filterBroker}
                                              handleFilterBroker={handleFilterBroker}
                                              filteredAssetsData={equityFilteredAssetsData}
                                              upload_options={upload_options}
                                              familyData={familyData}
                                              assetsDetails={assetsDetails}
                                              setAssetsDetails={setAssetsDetails}
                                              goalData={goalData}
                                              setDate={setDate}
                                              eqfunds={eqfunds}
                                              usequity={USEquity}
                                              equityShares={equityShares}
                                              selectedOption={selectedOption}
                                              selectedSubOption={selectedSubOption}
                                              session={session}
                                              addForm={addForm}
                                              updateForm={updateForm}
                                              addAssetsSubmit={addAssetsSubmit}
                                              cancelAssetForm={cancelAssetForm}
                                              updateAssetsSubmit={updateAssetsSubmit}
                                              assetEditId={assetEditId}
                                              unchangedgoaldata={unchangedgoaldata}
                                              getfpgoalsdata={getfpgoalsdata}
                                              setGoalSelected={setGoalSelected}
                                              closeModal={closeModal}
                                              selectGoals={selectGoals}
                                              selectedGoals={selectedGoals}
                                              selectedGoalIdArray={selectedGoalIdArray}
                                              selectedGoalsId={selectedGoalsId}
                                              setPriorityArray={setPriorityArray}
                                              selectedPriorityArray={
                                                selectedPriorityArray
                                              }
                                              setAutoMatedGoal={setAutoMatedGoal}
                                              isAutoMatedGoal={isAutoMatedGoal}
                                              setGoalLink={setGoalLink}
                                              isGoalSelected={isGoalSelected}
                                              setSelectedGoals={setSelectedGoals}
                                              setSelectedGoalsId={setSelectedGoalsId}
                                              setSelectedPriorityArray={
                                                setSelectedPriorityArray
                                              }
                                              setTab={setTab}
                                            />
                                          </>
                                        )}

                                        {selectedOption == "Gold" && (
                                          <>
                                            <AssetGold
                                              filteredAssetsData={goldFilteredAssetsData}
                                              familyData={familyData}
                                              assetsDetails={assetsDetails}
                                              setAssetsDetails={setAssetsDetails}
                                              goalData={goalData}
                                              setDate={setDate}
                                              goldfunds={goldfunds}
                                              session={session}
                                              addForm={addForm}
                                              updateForm={updateForm}
                                              addAssetsSubmit={addAssetsSubmit}
                                              cancelAssetForm={cancelAssetForm}
                                              updateAssetsSubmit={updateAssetsSubmit}
                                              assetEditId={assetEditId}
                                              unchangedgoaldata={unchangedgoaldata}
                                              getfpgoalsdata={getfpgoalsdata}
                                              setGoalSelected={setGoalSelected}
                                              closeModal={closeModal}
                                              selectGoals={selectGoals}
                                              selectedGoals={selectedGoals}
                                              selectedGoalIdArray={selectedGoalIdArray}
                                              selectedGoalsId={selectedGoalsId}
                                              setPriorityArray={setPriorityArray}
                                              selectedPriorityArray={
                                                selectedPriorityArray
                                              }
                                              setAutoMatedGoal={setAutoMatedGoal}
                                              isAutoMatedGoal={isAutoMatedGoal}
                                              setGoalLink={setGoalLink}
                                              isGoalSelected={isGoalSelected}
                                              setSelectedGoals={setSelectedGoals}
                                              setSelectedGoalsId={setSelectedGoalsId}
                                              setSelectedPriorityArray={
                                                setSelectedPriorityArray
                                              }
                                              setTab={setTab}
                                            />
                                          </>
                                        )}

                                        {selectedOption == "Liquid" && (
                                          <>
                                            <Liquid
                                              filteredAssetsData={liquidFilteredAssetsData}
                                              familyData={familyData}
                                              assetsDetails={assetsDetails}
                                              setAssetsDetails={setAssetsDetails}
                                              goalData={goalData}
                                              setDate={setDate}
                                              rentalincomeData={rentalincomeData}
                                              schemedata={schemedata}
                                              liquidfunds={liquidfunds}
                                              session={session}
                                              addForm={addForm}
                                              updateForm={updateForm}
                                              addAssetsSubmit={addAssetsSubmit}
                                              cancelAssetForm={cancelAssetForm}
                                              updateAssetsSubmit={updateAssetsSubmit}
                                              deleteAssetData={deleteAssetData}
                                              assetEditId={assetEditId}
                                              unchangedgoaldata={unchangedgoaldata}
                                              getfpgoalsdata={getfpgoalsdata}
                                              setGoalSelected={setGoalSelected}
                                              closeModal={closeModal}
                                              selectGoals={selectGoals}
                                              selectedGoals={selectedGoals}
                                              selectedGoalIdArray={selectedGoalIdArray}
                                              selectedGoalsId={selectedGoalsId}
                                              setPriorityArray={setPriorityArray}
                                              selectedPriorityArray={
                                                selectedPriorityArray
                                              }
                                              setAutoMatedGoal={setAutoMatedGoal}
                                              isAutoMatedGoal={isAutoMatedGoal}
                                              setGoalLink={setGoalLink}
                                              isGoalSelected={isGoalSelected}
                                              setSelectedGoals={setSelectedGoals}
                                              setSelectedGoalsId={setSelectedGoalsId}
                                              setSelectedPriorityArray={
                                                setSelectedPriorityArray
                                              }
                                              setTab={setTab}
                                              fetch={fetched}
                                            />
                                          </>
                                        )}

                                        {selectedOption == "Real Estate" && (
                                          <>
                                            <Realestate
                                              filteredAssetsData={realEstateFilteredAssetsData}
                                              familyData={familyData}
                                              assetsDetails={assetsDetails}
                                              setAssetsDetails={setAssetsDetails}
                                              goalData={goalData}
                                              setDate={setDate}
                                              rentalincomeData={rentalincomeData}
                                              session={session}
                                              addForm={addForm}
                                              updateForm={updateForm}
                                              assetEditId={assetEditId}
                                              addAssetsSubmit={addAssetsSubmit}
                                              cancelAssetForm={cancelAssetForm}
                                              updateAssetsSubmit={updateAssetsSubmit}
                                              unchangedgoaldata={unchangedgoaldata}
                                              getfpgoalsdata={getfpgoalsdata}
                                              setGoalSelected={setGoalSelected}
                                              closeModal={closeModal}
                                              selectGoals={selectGoals}
                                              selectedGoals={selectedGoals}
                                              selectedGoalIdArray={selectedGoalIdArray}
                                              selectedGoalsId={selectedGoalsId}
                                              setPriorityArray={setPriorityArray}
                                              selectedPriorityArray={
                                                selectedPriorityArray
                                              }
                                              setAutoMatedGoal={setAutoMatedGoal}
                                              isAutoMatedGoal={isAutoMatedGoal}
                                              setGoalLink={setGoalLink}
                                              isGoalSelected={isGoalSelected}
                                              setSelectedGoals={setSelectedGoals}
                                              setSelectedGoalsId={setSelectedGoalsId}
                                              setSelectedPriorityArray={
                                                setSelectedPriorityArray
                                              }
                                              setTab={setTab}
                                            />
                                          </>
                                        )}
                                        {selectedOption == "Upload" && (
                                          <>
                                            <AssetOthers
                                              session={session}
                                              selectedOption={selectedOption}
                                              selectedSubOption={selectedSubOption}
                                              familyData={familyData}
                                              addForm={addForm}
                                              unchangedgoaldata={unchangedgoaldata}
                                              setTab={setTab}
                                            />
                                          </>
                                        )}

                                        {selectedOption == "Others" && (
                                          <>
                                            <Others
                                              familyData={familyData}
                                              assetsDetails={assetsDetails}
                                              setAssetsDetails={setAssetsDetails}
                                              goalData={goalData}
                                              addForm={addForm}
                                              updateForm={updateForm}
                                              addAssetsSubmit={addAssetsSubmit}
                                              cancelAssetForm={cancelAssetForm}
                                              updateAssetsSubmit={updateAssetsSubmit}
                                              assetEditId={assetEditId}
                                              unchangedgoaldata={unchangedgoaldata}
                                              getfpgoalsdata={getfpgoalsdata}
                                              setGoalSelected={setGoalSelected}
                                              closeModal={closeModal}
                                              selectGoals={selectGoals}
                                              selectedGoals={selectedGoals}
                                              selectedGoalIdArray={selectedGoalIdArray}
                                              selectedGoalsId={selectedGoalsId}
                                              setPriorityArray={setPriorityArray}
                                              selectedPriorityArray={
                                                selectedPriorityArray
                                              }
                                              setAutoMatedGoal={setAutoMatedGoal}
                                              isAutoMatedGoal={isAutoMatedGoal}
                                              setGoalLink={setGoalLink}
                                              isGoalSelected={isGoalSelected}
                                              setSelectedGoals={setSelectedGoals}
                                              setSelectedGoalsId={setSelectedGoalsId}
                                              setSelectedPriorityArray={
                                                setSelectedPriorityArray
                                              }
                                              setTab={setTab}
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={subtab == "Category" ? "d-block" : "d-none"}>
                      <div>
                        {isDataLoading && (
                          <div>
                            <div className=" inner-container mt-4 pt-4">
                              <div
                                className="shine w-25 mb-1"
                                style={{ height: ".7rem" }}
                              ></div>
                              <div
                                className="shine w-100"
                                style={{ height: ".7rem" }}
                              ></div>
                            </div>
                            <div className=" inner-container mt-4 pt-4">
                              <div
                                className="shine w-25 mb-1"
                                style={{ height: ".7rem" }}
                              ></div>
                              <div
                                className="shine w-100"
                                style={{ height: ".7rem" }}
                              ></div>
                            </div>
                          </div>
                        )}

                        {isDataLoading == false &&
                          assetsData &&
                          assetsData.filter((asset) => asset.subcategorydetail === "Multi Assets Linkage").filter((asset, i) => {
                            if (viewmore2 == 0) {
                              if (i < 10) {
                                return true;
                              } else {
                                return false;
                              }
                            } else {
                              return true;
                            }
                          }).map((asset) => (
                            <div>
                              {/* New UI */}
                              <div key={asset.id} className="inner-container mt-4">
                                <div className="d-md-flex justify-content-md-between align-items-center ">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0">{asset.categorydetail}{" "}
                                      {asset.asset_name ? " - " + asset.asset_name : ""}{" "}
                                      <span style={{ fontWeight: "500" }}>
                                        {/* {(asset.asset_ecas_type == "ecas" || asset.asset_ecas_type == "cdsldemat") && "(External)"}
                                        {(asset.asset_ecas_type == "cams" ||
                                          asset.asset_ecas_type == "karvy") &&
                                          "(Internal)"} */}
                                        {/* {asset.asset_ecas_type == "manual" &&
                                          (asset.asset_sub_category_id == "31" ||
                                            asset.asset_sub_category_id == "77" ||
                                            asset.asset_sub_category_id == "71" ||
                                            asset.asset_sub_category_id == "62") &&
                                          "(Manual)"} */}
                                      </span></h4>
                                    <div className={`ms-4 ${AssetLibstyle.memberbox}`}>
                                      <span className={`${AssetLibstyle.membetTxt}`}>Member:</span> <span> {asset.membername1
                                        ? asset.membername1
                                        : "Not added"}</span>
                                    </div>
                                  </div>
                                  <div className="d-flex mt-md-0 mt-3">
                                    <div className={`${AssetLibstyle.automatedLinkage}`} style={{ fontWeight: "bold" }}>
                                      Consider This Asset In Automated Linkage*   <span
                                        className="ms-md-4 info-hover-left-box float-right"
                                        style={{
                                          position: "relative !important",
                                        }}
                                      >
                                        <span className={`icon ${AssetLibstyle.InfoIcon}`}>
                                          <img
                                            alt="More information"
                                            src="https://images.minty.co.in/static/assets/img/more_information.svg"
                                          />
                                        </span>
                                        <span className="msg">
                                          Select a goal below to map this investment with a goal of your choice. Otherwise, Fintoo will link it automatically with your high priority goal. In case, you do not wish to utilize this investment for any goal, select "NO".
                                        </span>
                                      </span>
                                    </div>

                                    <div className="d-flex  ms-4">
                                      <div>No</div>
                                      <Switch
                                        onChange={(v) => {
                                          setAssetsData(prev => (prev.map((v) => {
                                            if (v.id == asset.id) {
                                              v.asset_islinkable = '' + Number(!Boolean(Number(v.asset_islinkable)));
                                            }
                                            return v;
                                          })));
                                          // setAssetIsLinkable({
                                          //   ...assetIsLinkable,
                                          //   [asset.id]: v,
                                          // })
                                          switchToggle(asset.id, Boolean(Number(asset.asset_islinkable)));
                                        }
                                        }
                                        checked={Boolean(Number(asset.asset_islinkable))}
                                        // checked={assetsDetails.asset_islinkable}
                                        // checked={assetIsLinkable[asset.id] || false}
                                        className="react-switch px-2"
                                        activeBoxShadow="0 0 2px 3px #042b62"
                                        uncheckedIcon={true}
                                        checkedIcon={true}
                                        height={20}
                                        width={40}
                                        onColor="#042b62"
                                        offColor="#042b62"
                                      />
                                      <div>Yes</div>
                                    </div>
                                  </div>
                                </div>
                                {/* {assetsDetails.asset_islinkable == true && ( */}
                                <>
                                  <div style={{ border: '1px solid rgb(231, 231, 231)' }} className="mt-3"></div>
                                  <div className={`d-md-flex justify-content-between align-items-center mt-3`}>
                                    <div className={`${AssetLibstyle.assetlibBox}`}>
                                      <div title={indianRupeeFormat(
                                        parseFloat(
                                          asset.totalinvestedvalue ||
                                          asset.totalpurchasevalue
                                        )
                                      )} className={`${AssetLibstyle.title}`}>Total Current Value:</div>
                                      <div className={`${AssetLibstyle.totalAmount}`}> {asset.multi_linkage_data.length > 0 && indianRupeeFormat(
                                        parseFloat(
                                          // asset.asset_amount ||
                                          // asset.asset_amount
                                          asset.multi_linkage_data[0]['totalCurrentValue']
                                        )
                                      )}</div>
                                    </div>
                                    <div className="d-md-block d-none" style={{ transform: 'rotate(0deg)', height: "55px", transformOrigin: '0 0', borderRight: '1px solid rgb(231, 231, 231)' }}></div>
                                    <div className={`d-md-grid d-flex ${AssetLibstyle.assetlibBox}`}>
                                      <div className={`${AssetLibstyle.title}`}>Recurring Contribution</div>
                                      <div className={`${AssetLibstyle.totalAmount}`}>{asset.multi_linkage_data.length > 0 && indianRupeeFormat(asset.multi_linkage_data[0]['totalRecurringInvestment'])}</div>
                                    </div>
                                    <div className="d-md-block d-none" style={{ transform: 'rotate(0deg)', height: "55px", transformOrigin: '0 0', border: '1px solid rgb(231, 231, 231)' }}></div>
                                    <div className={`${AssetLibstyle.assetlibBox}`}>
                                      <div className={`${AssetLibstyle.title}`}>Asset Linked</div>
                                      <div className={`${AssetLibstyle.totalAmount}`}>{asset.multi_linkage_data.length > 0 && indianRupeeFormat(asset.multi_linkage_data[0]['totalAssetValueLinked'])}</div>
                                    </div>
                                    <div className="d-md-block d-none" style={{ transform: 'rotate(0deg)', height: "55px", transformOrigin: '0 0', border: '1px solid rgb(231, 231, 231)' }}></div>
                                    <div className={`${AssetLibstyle.assetlibBox}`}>
                                      <div className={`${AssetLibstyle.title}`}>Asset available for Linkages</div>
                                      <div className={`${AssetLibstyle.totalAmount}`}>{asset.multi_linkage_data.length > 0 && indianRupeeFormat(asset.multi_linkage_data[0]['totalAssetValueForLinkages'])}</div>
                                    </div>
                                  </div>
                                </>
                                {/* )} */}
                                {
                                  Boolean(Number(asset.asset_islinkable)) && (
                                    <>
                                      <div style={{ border: '1px solid rgb(231, 231, 231)' }} className="mt-3"></div>
                                      <div className="d-md-flex justify-content-between align-items-center mt-3">
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div className="d-flex align-items-center ">
                                            <h4 className="mb-0">Selected Goals : </h4>

                                            <div className="ms-2 selectedGoals">{renderGoalNames(asset.linked_goals_id)}</div>
                                            {/* <div className={`ms-2 ${AssetLibstyle.GoalsSelected}`}>
                                              {[...Array(2)].map((_, index) => (
                                                <div className="">
                                                  <div style={{ color: "#042b62" }} className="ms-2 d-flex">
                                                    Automated Linkage <span className="ms-2 d-md-block d-none">
                                                      |
                                                    </span>
                                                  </div>

                                                </div>
                                              ))}
                                            </div> */}
                                          </div>
                                          {!asset.linked_goals_id.includes(0) && (
                                            <div onClick={() => {
                                              // setSelectedGoalsData(true);
                                              openViewAllModal(asset.linked_goals_id);
                                            }} className={`ms-4 ${AssetLibstyle.viewallBtn}`}>View All</div>
                                          )}
                                        </div>
                                        <div style={{ justifyContent: "space-between" }} className="d-flex mt-md-0 mt-3 align-items-center">
                                          <div style={{ fontWeight: "bold" }} className={`${AssetLibstyle.LinkGoals}`}>
                                            Link This Investment Asset to Goal  <span
                                              className="ms-md-1 info-hover-left-box float-right"
                                              style={{
                                                position: "relative !important",
                                              }}
                                            >
                                              <span className={`icon ${AssetLibstyle.InfoIcon}`}>
                                                <img
                                                  alt="More information"
                                                  src="https://images.minty.co.in/static/assets/img/more_information.svg"
                                                />
                                              </span>
                                              <span className="msg">
                                                You can only assign goals which are prior to the end date of the asset (for Link ths asset)
                                              </span>
                                            </span>
                                          </div>

                                          <div className={`ms-4 ${AssetLibstyle.SelectGoalsbtn}`}>
                                            <button onClick={() => {
                                              // setSelectedGoalsmodal(true);
                                              if (asset.multi_linkage_data[0]['totalAssetValueForLinkages'] === 0) {
                                                showToastMessage();
                                              } else {
                                                setIsOpenGoalSelectionModal(true);
                                                setCategoryEditId(asset.id);
                                              }
                                            }}
                                            // disabled={asset.multi_linkage_data[0]['totalAssetValueForLinkages'] === 0}
                                            >Select Goals</button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                }
                              </div>
                              {/* <div key={asset.id} className="inner-container mt-4">
                                <h4>
                                  {asset.categorydetail}{" "}
                                  {asset.asset_name ? " - " + asset.asset_name : ""}{" "}
                                  <span style={{ fontWeight: "500" }}>
                                    {asset.asset_ecas_type == "ecas" && "(External)"}
                                    {(asset.asset_ecas_type == "cams" ||
                                      asset.asset_ecas_type == "karvy") &&
                                      "(Internal)"}
                                    {asset.asset_ecas_type == "manual" &&
                                      (asset.asset_sub_category_id == "31" ||
                                        asset.asset_sub_category_id == "77" ||
                                        asset.asset_sub_category_id == "71" ||
                                        asset.asset_sub_category_id == "62") &&
                                      "(Manual)"}
                                  </span>
                                </h4>

                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="display-style">
                                      <span>Value:</span>
                                      <p
                                        className="invest-show"
                                        title={indianRupeeFormat(
                                          parseFloat(
                                            asset.totalinvestedvalue ||
                                            asset.totalpurchasevalue
                                          )
                                        )}
                                      >
                                        {indianRupeeFormat(
                                          parseFloat(
                                            asset.asset_amount ||
                                            asset.asset_amount
                                          )
                                        )}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="display-style">
                                      <span>Type: </span>
                                      <p>
                                        {asset.subcategorydetail
                                          ? asset.subcategorydetail
                                          : "Not added"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="display-style">
                                      <span>Member: </span>
                                      <p>
                                        {asset.membername1
                                          ? asset.membername1
                                          : "Not added"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="opt-options">
                                      <span>
                                        <BsPencilFill
                                          onClick={() => {
                                            editAssetData(
                                              asset.id,
                                              asset.asset_type
                                                ? asset.asset_type
                                                : "none"
                                            );
                                            setAssetEditId(asset.id);
                                            setUpdateForm(true);
                                            setAddForm(false);
                                            scrollToForm();
                                          }}
                                        />
                                      </span>

                                    </div>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          ))}

                        {isDataLoading == false &&
                          (assetsData &&
                            assetsData.filter((asset) => asset.subcategorydetail === "Multi Assets Linkage").filter((asset, i) => {
                              if (viewmore2 == 0) {
                                if (i < 10) {
                                  return true;
                                } else {
                                  return false;
                                }
                              } else {
                                return true;
                              }
                            }).length === 0) && subtab == "Category" && (
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: "17px",
                                fontWeight: "bold",
                              }}
                            >No records found</div>
                          )}

                        {asset_data.current && asset_data.current.length > 0 && (
                          <div
                            id="no-result-msgg"
                            style={{
                              textAlign: "center",
                              fontSize: "17px",
                              fontWeight: "bold",
                              display: 'none'
                            }}
                          >
                          </div>
                        )}

                        <div className="DataGridViewform" style={{
                          padding: "0"
                        }}>
                          {assetsData &&
                            assetsData.filter((asset) => asset.subcategorydetail === "Multi Assets Linkage").length > 10 && (
                              <button onClick={() => setViewMore2(viewmore2 === 0 ? 1 : 0)}>
                                {viewmore2 === 0 ? "View All" : "View Less"}
                              </button>
                            )}
                        </div>
                      </div>
                      <div className="mt-4 removefixheaderasset2">

                        <div>
                          <div className="accordion">
                            <div className="accordion-panel active" id="assetbox">
                              <div className="accordion-header d-flex justify-content-between pt-2">
                                <h4 className="accordion-heading">
                                  <img
                                    alt="Asset"
                                    className="accordian-img"
                                    src={
                                      imagePath +
                                      "/static/assets/img/assets-liabilities/asset-top.svg"
                                    }
                                  />
                                  <span
                                    style={
                                      {
                                        // fontWeight: "500",
                                        // paddingTop: "2em",
                                      }
                                    }
                                  >
                                    Add Assets
                                  </span>
                                </h4>
                                <div
                                  onClick={() => setShowView(!showview)}
                                  className={`${DGstyles.HideSHowicon} hideShowIconCustom`}
                                >
                                  {showview == true ? <>-</> : <>+</>}
                                </div>
                              </div>
                              {showview && (
                                <div
                                  className={`accordion-content  family ${DGstyles.bgAssetLib}`}
                                >
                                  <div className="">
                                    <div className="container-fluid ">
                                      <div className="row">
                                        <div className="col-md-10">
                                          <label className="category-label-title">
                                            Category
                                          </label>
                                          <ul className="card-list ">
                                            {options.map((v, i) => (
                                              <React.Fragment key={i}>
                                                <li
                                                  onClick={() => {
                                                    setGoalSelected(false);
                                                    setSelectedGoals(
                                                      "Automated Linkage"
                                                    );
                                                    setSelectedGoalsId([]);
                                                    setSelectedPriorityArray([]);
                                                    setAutoMatedGoal(true);

                                                    setAssetsDetails({
                                                      ...defaultAssetDetails,
                                                      categorydetail: v.title,
                                                      asset_category_id: v.id,
                                                      asset_name: v.options["0"]
                                                        ? v.options["0"].title
                                                        : v.title,
                                                      subcategorydetail:
                                                        v.options["0"]?.title,
                                                      asset_sub_category_id: v.options[
                                                        "0"
                                                      ]
                                                        ? v.options["0"].id
                                                        : v.id,
                                                      user_id:
                                                        session["data"]["user_details"][
                                                        "user_id"
                                                        ],
                                                      fp_log_id:
                                                        session["data"]["user_details"][
                                                        "fp_log_id"
                                                        ],
                                                      asset_member_id:
                                                        familyData["0"].value,
                                                    });
                                                    simpleValidator.current.hideMessages();
                                                    setForceUpdate((v) => ++v);
                                                    setSelectedOption(v.title);

                                                    if (v.options.length === 0) {
                                                      setSelectedSubOption("");
                                                    } else {
                                                      setSelectedSubOption(
                                                        v.options["0"].title
                                                      );
                                                    }

                                                    setAddForm(true);
                                                    setUpdateForm(false);
                                                    getfpgoalsdata(
                                                      session["data"]["user_details"][
                                                      "fp_log_id"
                                                      ]
                                                    );
                                                  }}
                                                  className={`li-options ${selectedOption == v.title
                                                    ? "active"
                                                    : ""
                                                    }`}
                                                >
                                                  <label title={v.title}>
                                                    <img src={v.img} />
                                                    <span>{v.title}</span>
                                                  </label>
                                                </li>
                                              </React.Fragment>
                                            ))}
                                          </ul>
                                          <hr />
                                          <span>
                                            <label className="">{selectedOption}</label>
                                          </span>
                                          <ul
                                            className="card-list child "
                                            id="alternate"
                                          >
                                            {options
                                              .filter((v) => v.title == selectedOption)
                                              .map((v, z) => (
                                                <React.Fragment key={z}>
                                                  {v.options.map((x, i) => (
                                                    <React.Fragment key={i}>
                                                      <li
                                                        onClick={() => {
                                                          let ror = 0;
                                                          let freq = "1";
                                                          let growth_rate = "10";
                                                          let asset_maturity_date =
                                                            null;
                                                          let asset_mf_end_date =
                                                            moment(
                                                              retirmentdate,
                                                              "DD/MM/YYYY"
                                                            ).format("DD/MM/YYYY");

                                                          let tmp_asset_name = x.title;
                                                          let asset_currency = false;

                                                          switch (x.id) {
                                                            case 82:
                                                              growth_rate = "5";
                                                              break;
                                                            case 81:
                                                            case 85:
                                                            case 86:
                                                            case 118:
                                                            case 120:
                                                              ror =
                                                                x.id === 81
                                                                  ? 7.1
                                                                  : x.id === 118
                                                                    ? 10
                                                                    : 6.8;
                                                              freq = "4";
                                                              if (x.id != 118) {
                                                                asset_maturity_date =
                                                                  moment(retirmentdate)
                                                                    .add(
                                                                      retirmentdate,
                                                                      "y"
                                                                    )
                                                                    .format(
                                                                      "MM/DD/YYYY"
                                                                    );
                                                              }

                                                              break;
                                                            case 84:
                                                              ror = 6.8;
                                                              break;
                                                            case 117:
                                                              asset_maturity_date =
                                                                moment(retirmentdate)
                                                                  .add(
                                                                    retirmentdate,
                                                                    "y"
                                                                  )
                                                                  .format("MM/DD/YYYY");
                                                              ror = 8.1;
                                                              break;
                                                            case 123:
                                                              tmp_asset_name = "";
                                                              asset_mf_end_date =
                                                                moment(
                                                                  "31/12/2099",
                                                                  "DD/MM/YYYY"
                                                                ).format("DD/MM/YYYY");
                                                              break;
                                                            default:
                                                              ror = 0;
                                                              break;
                                                          }

                                                          setAssetsDetails({
                                                            ...defaultAssetDetails,
                                                            // asset_name: x.title,
                                                            asset_name: tmp_asset_name,
                                                            asset_currency:
                                                              asset_currency,
                                                            subcategorydetail: x.title,
                                                            categorydetail: v.title,
                                                            asset_sub_category_id: x.id,
                                                            asset_category_id: v.id,
                                                            asset_mf_end_date:
                                                              asset_mf_end_date,
                                                            asset_maturity_date:
                                                              asset_maturity_date,
                                                            asset_ror: ror,
                                                            asset_frequency: freq,
                                                            annual_growth_rate:
                                                              growth_rate,
                                                            user_id:
                                                              session["data"][
                                                              "user_details"
                                                              ]["user_id"],
                                                            fp_log_id:
                                                              session["data"][
                                                              "user_details"
                                                              ]["fp_log_id"],
                                                            asset_member_id:
                                                              familyData["0"].value,
                                                          });
                                                          setSelectedSubOption(x.title);
                                                          simpleValidator.current.hideMessages();
                                                          setForceUpdate((v) => ++v);
                                                          setAssetEditId("");
                                                          setAddForm(true);
                                                          setUpdateForm(false);
                                                          setGoalSelected(false);
                                                          setSelectedGoals(
                                                            "Automated Linkage"
                                                          );
                                                          setSelectedGoalsId([]);
                                                          setSelectedPriorityArray([]);
                                                          setAutoMatedGoal(true);
                                                          getfpgoalsdata(
                                                            session["data"][
                                                            "user_details"
                                                            ]["fp_log_id"]
                                                          );
                                                        }}
                                                        className={`li-options ${selectedSubOption == x.title
                                                          ? "active"
                                                          : ""
                                                          }`}
                                                      >
                                                        <label htmlFor="alternate-1">
                                                          <img
                                                            alt="Assets art investment"
                                                            src={x.img}
                                                          />
                                                          <span>{x.title}</span>
                                                        </label>
                                                      </li>
                                                    </React.Fragment>
                                                  ))}
                                                </React.Fragment>
                                              ))}
                                          </ul>
                                        </div>
                                      </div>

                                      <div className="forms-container col-md-12">
                                        {selectedOption == "Alternate" && (
                                          <>
                                            <Alternate
                                              filteredAssetsData={alternateFilteredAssetsData}
                                              familyData={familyData}
                                              assetsDetails={assetsDetails}
                                              setAssetsDetails={setAssetsDetails}
                                              goalData={goalData}
                                              setDate={setDate}
                                              cryptodata={cryptodata}
                                              session={session}
                                              addForm={addForm}
                                              updateForm={updateForm}
                                              addAssetsSubmit={addAssetsSubmit}
                                              cancelAssetForm={cancelAssetForm}
                                              updateAssetsSubmit={updateAssetsSubmit}
                                              assetEditId={assetEditId}
                                              unchangedgoaldata={unchangedgoaldata}
                                              getfpgoalsdata={getfpgoalsdata}
                                              setGoalSelected={setGoalSelected}
                                              closeModal={closeModal}
                                              selectGoals={selectGoals}
                                              selectedGoals={selectedGoals}
                                              selectedGoalIdArray={selectedGoalIdArray}
                                              selectedGoalsId={selectedGoalsId}
                                              setPriorityArray={setPriorityArray}
                                              selectedPriorityArray={
                                                selectedPriorityArray
                                              }
                                              setAutoMatedGoal={setAutoMatedGoal}
                                              isAutoMatedGoal={isAutoMatedGoal}
                                              setGoalLink={setGoalLink}
                                              isGoalSelected={isGoalSelected}
                                              setSelectedGoals={setSelectedGoals}
                                              setSelectedGoalsId={setSelectedGoalsId}
                                              setSelectedPriorityArray={
                                                setSelectedPriorityArray
                                              }
                                              setTab={setTab}
                                            />
                                          </>
                                        )}

                                        {selectedOption == "Debt" && (
                                          <>
                                            <AssetDebt
                                              familyData={familyData}
                                              assetsDetails={assetsDetails}
                                              setAssetsDetails={setAssetsDetails}
                                              goalData={goalData}
                                              setDate={setDate}
                                              debtfunds={debtfunds}
                                              session={session}
                                              addForm={addForm}
                                              updateForm={updateForm}
                                              addAssetsSubmit={addAssetsSubmit}
                                              cancelAssetForm={cancelAssetForm}
                                              updateAssetsSubmit={updateAssetsSubmit}
                                              assetEditId={assetEditId}
                                              unchangedgoaldata={unchangedgoaldata}
                                              getfpgoalsdata={getfpgoalsdata}
                                              setGoalSelected={setGoalSelected}
                                              closeModal={closeModal}
                                              selectGoals={selectGoals}
                                              selectedGoals={selectedGoals}
                                              selectedGoalIdArray={selectedGoalIdArray}
                                              selectedGoalsId={selectedGoalsId}
                                              setPriorityArray={setPriorityArray}
                                              selectedPriorityArray={
                                                selectedPriorityArray
                                              }
                                              setAutoMatedGoal={setAutoMatedGoal}
                                              isAutoMatedGoal={isAutoMatedGoal}
                                              setGoalLink={setGoalLink}
                                              isGoalSelected={isGoalSelected}
                                              setSelectedGoals={setSelectedGoals}
                                              setSelectedGoalsId={setSelectedGoalsId}
                                              setSelectedPriorityArray={
                                                setSelectedPriorityArray
                                              }
                                              retirementGoalID={retirementGoalID}
                                              setTab={setTab}
                                            />
                                          </>
                                        )}

                                        {selectedOption == "Equity" && (
                                          <>
                                            <AssetEquity
                                              filteredAssetsData={equityFilteredAssetsData}
                                              upload_options={upload_options}
                                              familyData={familyData}
                                              assetsDetails={assetsDetails}
                                              setAssetsDetails={setAssetsDetails}
                                              goalData={goalData}
                                              setDate={setDate}
                                              eqfunds={eqfunds}
                                              usequity={USEquity}
                                              equityShares={equityShares}
                                              selectedOption={selectedOption}
                                              selectedSubOption={selectedSubOption}
                                              session={session}
                                              addForm={addForm}
                                              updateForm={updateForm}
                                              addAssetsSubmit={addAssetsSubmit}
                                              cancelAssetForm={cancelAssetForm}
                                              updateAssetsSubmit={updateAssetsSubmit}
                                              assetEditId={assetEditId}
                                              unchangedgoaldata={unchangedgoaldata}
                                              getfpgoalsdata={getfpgoalsdata}
                                              setGoalSelected={setGoalSelected}
                                              closeModal={closeModal}
                                              selectGoals={selectGoals}
                                              selectedGoals={selectedGoals}
                                              selectedGoalIdArray={selectedGoalIdArray}
                                              selectedGoalsId={selectedGoalsId}
                                              setPriorityArray={setPriorityArray}
                                              selectedPriorityArray={
                                                selectedPriorityArray
                                              }
                                              setAutoMatedGoal={setAutoMatedGoal}
                                              isAutoMatedGoal={isAutoMatedGoal}
                                              setGoalLink={setGoalLink}
                                              isGoalSelected={isGoalSelected}
                                              setSelectedGoals={setSelectedGoals}
                                              setSelectedGoalsId={setSelectedGoalsId}
                                              setSelectedPriorityArray={
                                                setSelectedPriorityArray
                                              }
                                              setTab={setTab}
                                            />
                                          </>
                                        )}

                                        {selectedOption == "Gold" && (
                                          <>
                                            <AssetGold
                                              filteredAssetsData={goldFilteredAssetsData}
                                              familyData={familyData}
                                              assetsDetails={assetsDetails}
                                              setAssetsDetails={setAssetsDetails}
                                              goalData={goalData}
                                              setDate={setDate}
                                              goldfunds={goldfunds}
                                              session={session}
                                              addForm={addForm}
                                              updateForm={updateForm}
                                              addAssetsSubmit={addAssetsSubmit}
                                              cancelAssetForm={cancelAssetForm}
                                              updateAssetsSubmit={updateAssetsSubmit}
                                              assetEditId={assetEditId}
                                              unchangedgoaldata={unchangedgoaldata}
                                              getfpgoalsdata={getfpgoalsdata}
                                              setGoalSelected={setGoalSelected}
                                              closeModal={closeModal}
                                              selectGoals={selectGoals}
                                              selectedGoals={selectedGoals}
                                              selectedGoalIdArray={selectedGoalIdArray}
                                              selectedGoalsId={selectedGoalsId}
                                              setPriorityArray={setPriorityArray}
                                              selectedPriorityArray={
                                                selectedPriorityArray
                                              }
                                              setAutoMatedGoal={setAutoMatedGoal}
                                              isAutoMatedGoal={isAutoMatedGoal}
                                              setGoalLink={setGoalLink}
                                              isGoalSelected={isGoalSelected}
                                              setSelectedGoals={setSelectedGoals}
                                              setSelectedGoalsId={setSelectedGoalsId}
                                              setSelectedPriorityArray={
                                                setSelectedPriorityArray
                                              }
                                              setTab={setTab}
                                            />
                                          </>
                                        )}

                                        {selectedOption == "Liquid" && (
                                          <>
                                            <Liquid
                                              filteredAssetsData={liquidFilteredAssetsData}
                                              familyData={familyData}
                                              assetsDetails={assetsDetails}
                                              setAssetsDetails={setAssetsDetails}
                                              goalData={goalData}
                                              setDate={setDate}
                                              rentalincomeData={rentalincomeData}
                                              schemedata={schemedata}
                                              liquidfunds={liquidfunds}
                                              session={session}
                                              addForm={addForm}
                                              updateForm={updateForm}
                                              addAssetsSubmit={addAssetsSubmit}
                                              cancelAssetForm={cancelAssetForm}
                                              updateAssetsSubmit={updateAssetsSubmit}
                                              deleteAssetData={deleteAssetData}
                                              assetEditId={assetEditId}
                                              unchangedgoaldata={unchangedgoaldata}
                                              getfpgoalsdata={getfpgoalsdata}
                                              setGoalSelected={setGoalSelected}
                                              closeModal={closeModal}
                                              selectGoals={selectGoals}
                                              selectedGoals={selectedGoals}
                                              selectedGoalIdArray={selectedGoalIdArray}
                                              selectedGoalsId={selectedGoalsId}
                                              setPriorityArray={setPriorityArray}
                                              selectedPriorityArray={
                                                selectedPriorityArray
                                              }
                                              setAutoMatedGoal={setAutoMatedGoal}
                                              isAutoMatedGoal={isAutoMatedGoal}
                                              setGoalLink={setGoalLink}
                                              isGoalSelected={isGoalSelected}
                                              setSelectedGoals={setSelectedGoals}
                                              setSelectedGoalsId={setSelectedGoalsId}
                                              setSelectedPriorityArray={
                                                setSelectedPriorityArray
                                              }
                                              setTab={setTab}
                                              fetch={fetched}
                                            />
                                          </>
                                        )}

                                        {selectedOption == "Real Estate" && (
                                          <>
                                            <Realestate
                                              filteredAssetsData={realEstateFilteredAssetsData}
                                              familyData={familyData}
                                              assetsDetails={assetsDetails}
                                              setAssetsDetails={setAssetsDetails}
                                              goalData={goalData}
                                              setDate={setDate}
                                              rentalincomeData={rentalincomeData}
                                              session={session}
                                              addForm={addForm}
                                              updateForm={updateForm}
                                              assetEditId={assetEditId}
                                              addAssetsSubmit={addAssetsSubmit}
                                              cancelAssetForm={cancelAssetForm}
                                              updateAssetsSubmit={updateAssetsSubmit}
                                              unchangedgoaldata={unchangedgoaldata}
                                              getfpgoalsdata={getfpgoalsdata}
                                              setGoalSelected={setGoalSelected}
                                              closeModal={closeModal}
                                              selectGoals={selectGoals}
                                              selectedGoals={selectedGoals}
                                              selectedGoalIdArray={selectedGoalIdArray}
                                              selectedGoalsId={selectedGoalsId}
                                              setPriorityArray={setPriorityArray}
                                              selectedPriorityArray={
                                                selectedPriorityArray
                                              }
                                              setAutoMatedGoal={setAutoMatedGoal}
                                              isAutoMatedGoal={isAutoMatedGoal}
                                              setGoalLink={setGoalLink}
                                              isGoalSelected={isGoalSelected}
                                              setSelectedGoals={setSelectedGoals}
                                              setSelectedGoalsId={setSelectedGoalsId}
                                              setSelectedPriorityArray={
                                                setSelectedPriorityArray
                                              }
                                              setTab={setTab}
                                            />
                                          </>
                                        )}
                                        {selectedOption == "Upload" && (
                                          <>
                                            <AssetOthers
                                              session={session}
                                              selectedOption={selectedOption}
                                              familyData={familyData}
                                              selectedSubOption={selectedSubOption}
                                              addForm={addForm}
                                              unchangedgoaldata={unchangedgoaldata}
                                              setTab={setTab}
                                            />
                                          </>
                                        )}

                                        {selectedOption == "Others" && (
                                          <>
                                            <Others
                                              familyData={familyData}
                                              assetsDetails={assetsDetails}
                                              setAssetsDetails={setAssetsDetails}
                                              goalData={goalData}
                                              addForm={addForm}
                                              updateForm={updateForm}
                                              addAssetsSubmit={addAssetsSubmit}
                                              cancelAssetForm={cancelAssetForm}
                                              updateAssetsSubmit={updateAssetsSubmit}
                                              assetEditId={assetEditId}
                                              unchangedgoaldata={unchangedgoaldata}
                                              getfpgoalsdata={getfpgoalsdata}
                                              setGoalSelected={setGoalSelected}
                                              closeModal={closeModal}
                                              selectGoals={selectGoals}
                                              selectedGoals={selectedGoals}
                                              selectedGoalIdArray={selectedGoalIdArray}
                                              selectedGoalsId={selectedGoalsId}
                                              setPriorityArray={setPriorityArray}
                                              selectedPriorityArray={
                                                selectedPriorityArray
                                              }
                                              setAutoMatedGoal={setAutoMatedGoal}
                                              isAutoMatedGoal={isAutoMatedGoal}
                                              setGoalLink={setGoalLink}
                                              isGoalSelected={isGoalSelected}
                                              setSelectedGoals={setSelectedGoals}
                                              setSelectedGoalsId={setSelectedGoalsId}
                                              setSelectedPriorityArray={
                                                setSelectedPriorityArray
                                              }
                                              setTab={setTab}
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={tab == "tab2" ? "d-block" : "d-none"}>
              <Liabilities tab={tab} familyData={familyData} setTab={setTab} isFetch={isFetched} />
            </div>
          </div>
        </div>
      </div>
      {session['data'] && (filteredAssetsDataCheck.length === selectedCategories.length) && session['data']['fp_lifecycle_status'] === 2 ? (
        // {session['data'] && checkboxShouldExcludel == true && (filteredAssetsDataCheck.length === selectedCategories.length) && session['data']['fp_lifecycle_status'] === 2 ? (
        <Modal className="popupmodal" centered show={show} onHide={handleClose}>
          <Modal.Header className="ModalHead">
            <div className="text-center">Alert</div>
          </Modal.Header>
          <div className=" p-5 d-grid place-items-center align-item-center">
            <div className=" HeaderModal">
              <div
                style={{
                  fontSize: "1rem",
                  textAlign: "center",
                }}
              >
                As you have already generated the report there should be at least one self/spouse asset in this section.
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center pb-5">
            <button
              onClick={() => {
                handleClose("no");
              }}
              className="outline-btn m-2"
            >
              Ok
            </button>
          </div>
        </Modal>

      ) : (

        <Modal className="popupmodal" centered show={show} onHide={handleClose}>
          <Modal.Header className="ModalHead">
            <div className="text-center">Delete Confirmation</div>
          </Modal.Header>
          <div className=" p-5 d-grid place-items-center align-item-center">
            <div className=" HeaderModal">
              <div
                style={{
                  fontSize: "1rem",
                  textAlign: "center",
                }}
              >
                This will permanently erase the record and its associated
                information.
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center pb-5">
            {tab && tab == "tab1" && (
              <button
                onClick={() => {
                  handleClose("yes", "asset");
                }}
                className="outline-btn m-2"
              >
                Yes
              </button>
            )}

            {tab && tab == "tab2" && (
              <button
                onClick={() => {
                  handleClose("yes", "liability");
                }}
                className="outline-btn m-2"
              >
                Yes
              </button>
            )}

            <button
              onClick={() => {
                handleClose("no");
              }}
              className="outline-btn m-2"
            >
              No
            </button>
          </div>
        </Modal>
      )}


      {/* For Selected Goals Member */}
      <Modal className="popupmodal" centered show={selectedgoalsdata} onHide={selectedgoalsdatahandleClose}>
        <Modal.Header className="ModalHead">
          <div className="d-flex justify-content-between align-items-center">
            <div className="text-left">Selected Goals</div>
            <div>
              <IoCloseCircleOutline onClick={() => {
                selectedgoalsdatahandleClose()
              }} style={{ fontSize: "2.3rem", cursor: "pointer" }} />
            </div>
          </div>
        </Modal.Header>
        <div style={{ padding: "1.4rem" }} className="d-grid place-items-center align-item-center">
          <table style={{
            border: "1px solid #042b62",

          }}>
            <thead style={{
              border: "1px solid #042b62",
            }}>
              <th style={{
                border: "1px solid #042b62",
                padding: ".6rem 1rem",
                fontWeight: "bold"
              }}>Member</th>
              <th style={{
                border: "1px solid #042b62", padding: ".6rem 1rem", fontWeight: "bold"
              }}>Goal Name</th>
            </thead>
            <tbody>
              {viewAllGoalsData.map((v, i) => (
                <tr key={i}>
                  <td style={{
                    borderRight: "1px solid #042b62", padding: ".6rem 1rem",
                  }}>{v.member.trim()}</td>
                  <td style={{ padding: ".6rem 1rem", }}>{v.goal.trim()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>

      {/* For Edit goals */}
      {subtab.toLowerCase() == "category" && categoryEditId && <GoalSelectionModal
        isOpenGoalSelectionModal={isOpenGoalSelectionModal}
        setIsOpenGoalSelectionModal={setIsOpenGoalSelectionModal}
        goalsData={[defaultGoal, ...goalData]}
        handleOnClose={(e) => {
          updateMultiAssetLinkageData(e.selectedGoals, categoryEditId)
          setAssetsData(v => v.map(v => {
            if (v.id == categoryEditId) {
              const uniqueSet = new Set(e.selectedGoals);
              const uniqueArray = [...uniqueSet];
              v.linked_goals_id = uniqueArray;
              // v.linked_goals_id = e.selectedGoals;
            }
            return v;
          }));
          setCategoryEditId(null);
        }}
        onHide={() => {
          setCategoryEditId(null);
        }}
        assetData={assetsData.filter(v => v.id == categoryEditId)[0] ?? {}}
      />}
      {/* For Selected Goals */}
      {
        selectedgoalsmodal == true && (
          <>
            <GoalsDropdown
              setGoalSelected={setGoalSelected}
              goals={goalData}
              unchangedgoaldata={unchangedgoaldata}
              closeModal={closeModal}
              selectGoals={selectGoals}
              selectedGoals={categoryEditId ? goalsInAssetsData() : selectedGoals}
              selectedGoalIdArray={selectedGoalIdArray}
              selectedGoalsId={selectedGoalsId}
              setPriorityArray={setPriorityArray}
              selectedPriorityArray={selectedPriorityArray}
              setAutoMatedGoal={setAutoMatedGoal}
              isAutoMatedGoal={isAutoMatedGoal}
              setGoalLink={setGoalLink}
              type={"Asset"}
            />
          </>
        )
      }

      <SlidingPanel
        className="Filter_Panel"
        type={"right"}
        isOpen={openPanel}
        size={sidePanelWidth}
      >
        <form id="FilterData" className="d-flex flex-column">
          <div className="ps-3 pe-3 pt-3">
            <div className="SideBar_Filter">
              <div className="filter_text">
                <p
                  style={{
                    fontSize: "1.2rem",
                  }}
                >
                  Filters
                </p>
              </div>
              <div>
                <button type="button" onClick={() => closeFilter()}>
                  <img src={CloseFilter} alt="" srcset="" />
                </button>
              </div>
            </div>
            <div style={{ marginTop: "1rem" }} className="Line"></div>
            <div className="fltr-section NSEBSE remove-border-li">
              <div className="fltr-section">
                <h4>Category</h4>
                <div className="Category_Filter">
                  <ul className="fltr-items">
                    {categories.map((v, i) => (
                      <li className="fltr-items-li" key={i}>
                        <FintooCheckbox
                          checked={category == v.id}
                          title={v.title}
                          onChange={() => {
                            setCategory(v.id), setFilterText(v.title);
                            setSelectedBrokers([]);
                          }}
                        />
                        {/* <input type="checkbox" name="Equity" value="Equity" id="" onChange={handleCategoryChange} /> <span>Equity</span> */}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {subtab === "individual" && (
                (categories.filter((v) => v.id == category)[0]?.child.length ?? 0) > 0 && (
                  <div className="fltr-section">
                    {(category == "1" || category == "2") ? <h4>Type</h4> : <h4>Sub Category</h4>}
                    <div className="Category_Filter">
                      <ul className="fltr-items subcate">
                        {categories
                          .filter((v) => v.id == category)
                          .map((v) => (
                            <>
                              {v.child.map((x) => (
                                <li className="fltr-items-li" key={x.id}>
                                  <FintooSubCheckbox
                                    checked={selectedSubcategory.indexOf(x.id) > -1}
                                    title={x.title}
                                    onChange={() => {
                                      if (selectedSubcategory.indexOf(x.id) > -1) {
                                        setSelectedSubcategory((_a) =>
                                          _a.filter((_v) => _v !== x.id)
                                        );
                                      } else {
                                        setSelectedSubcategory((_a) => [..._a, x.id]);
                                      }
                                    }}
                                  />
                                </li>
                              ))}
                            </>
                          ))}
                      </ul>
                    </div>
                  </div>
                )
              )}

              {showBrokers &&
                <>
                  <div className="fltr-section">
                    <h4>Brokers</h4>
                    <div className="Category_Filter">
                      <ul className="fltr-items Memebsrs" style={{ height: "50px" }}>
                        {cdslBrokers.map((v, i) => (
                          <li className="fltr-items-li " key={i}>
                            <FintooSubCheckbox
                              checked={selectedBrokers.indexOf(v.brokerId) > -1}
                              title={v.brokerName}
                              onChange={() => {
                                if (selectedBrokers.indexOf(v.brokerId) > -1) {
                                  setSelectedBrokers((_a) =>
                                    _a.filter((_v) => _v != v.brokerId)
                                  );
                                } else {
                                  setSelectedBrokers((_a) => [..._a, v.brokerId]);
                                }
                              }}

                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </>}

              <div className="fltr-section">
                <h4>Members</h4>
                <div className="Category_Filter">
                  <ul className="fltr-items Memebsrs">
                    {memberData.map((v, i) => (
                      <li className="fltr-items-li " key={i}>
                        <FintooSubCheckbox
                          checked={selectedMember.indexOf(v.value) > -1}
                          title={v.label}
                          onChange={() => {
                            if (selectedMember.indexOf(v.value) > -1) {
                              setSelectedMember((_a) =>
                                _a.filter((_v) => _v != v.value)
                              );
                            } else {
                              setSelectedMember((_a) => [..._a, v.value]);
                            }
                          }}
                        />
                        {/* <input type="checkbox" name="Equity" value="Equity" id="" onChange={handleCategoryChange} /> <span>Equity</span> */}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

            </div>
          </div>

          <div className="p-3 Filter_Btn_panel">
            <div>
              <button type="button" onClick={applyFilter}>
                Apply
              </button>
            </div>
            <div className="reset_btn">
              <button className="Reset" type="button" onClick={resetFilter}>
                Reset All
              </button>
            </div>
          </div>
        </form>
      </SlidingPanel>
      <PortfolioBalance open={isOpen} setIsOpen={setIsOpen} modalData={modalData}
        isDashboard={false}
        isFetch={isFetched}
      />

    </DatagatherLayout>

  );
};
export default AssetsLiabilities;