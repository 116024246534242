import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Link from "../../MainComponents/Link";
import FintooCheckbox from "../../FintooCheckbox/FintooCheckbox";
import FintooBackButton from "../../HTML/FintooBackButton";
import PaymentApprove from "../ErrosPages/PaymentApprove";
import {
  apiCall,
  getItemLocal,
  getUserId,
  errorAlert,
  loginRedirectGuest,
  maskBankAccNo,

} from "../../../common_utilities";
import {
  DMF_PLACEORDER_API_URL,
  DMF_CANCELORDER_API_URL,
  DMF_SUCCESSORDER_API_URL,
  DMF_PAYMENTGATEWAYRESPONSE_API_URL,
  IS_DIRECT,
  DMF_GETBANKUPIID_API_URL,
} from "../../../constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FintooLoader from "../../../components/FintooLoader";

const upiRegex = new RegExp(/^[a-zA-Z0-9.-]{2,256}@[a-zA-Z][a-zA-Z]{2,64}$/);

function UPIBOX(props) {
  const userid = getUserId();
  const mandateType = getItemLocal("mandateType");
  const bankid = getItemLocal("selbankid");
  const [isOpened, setIsOpened] = useState(false);
  const [checked, setChecked] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [upiId, setUpiId] = useState("");
  const [loader, setloader] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [htmlResponse, setHtmlResponse] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const interval = useRef(null);
  const btnRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    setChecked(props.save_upi_id);
    if (props.save_upi_id == true) {
      setUpiId(props.upi_id);
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [props.upi_id, props.save_upi_id]);

  useEffect(() => { }, [props.upi_id, props.save_upi_id]);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (!userid) {
      loginRedirectGuest();
    }
  }, []);

  const handleShow = async () => {
    setloader(true);
    checkErrors(upiId);
    if (Object.keys(errors).length > 0) {
      return;
    }
    btnRef.current.setAttribute("disabled", "disabled");
    console.log('nil123', getItemLocal("lumpsum"));
    let data = {
      user_id: userid,
      bank_id: bankid,
      mandate_type: mandateType,
      upi_id: upiId,
      is_direct: IS_DIRECT,
      purchase_type: getItemLocal("lumpsum") ? "lumpsum" : "sip"
    };
    if (checked == true) {
      data["save_upi_id"] = "Y";
    } else {
      data["save_upi_id"] = "N";
    }

    var res = await apiCall(DMF_PLACEORDER_API_URL, data);
    if (res != "") {
      setloader(false);
    }
    if (res.error_code == "100") {
      setShow(true);
      setHtmlResponse(res.data.html);
      setTransactionId(res.data.transaction_data);
      interval.current = setInterval(() => {
        checkPayment(res.data.order_id, res.data.transaction_data);
      }, 10000);

      btnRef.current.removeAttribute("disabled");
    } else {
      btnRef.current.removeAttribute("disabled");
      errorAlert(res.message);
    }
  };

  const checkPayment = async (orderId, transaction_data) => {
    var res = await apiCall(DMF_PAYMENTGATEWAYRESPONSE_API_URL, {
      user_id: userid,
      order_id: orderId,
      is_direct: IS_DIRECT,
    });

    let status = "";
    let paymentAwaiting = "0";
    // res = {
    //   error_code: "100",
    //   message: "Success",
    //   data: { status: "Success", message: "APPROVED ( UPI )" },
    // }; // comment later

    if (res.error_code == "100") {
      clearInterval(interval.current);
      interval.current = null;

      if (res.data.message.toUpperCase().includes("APPROVED ( UPI )")) {
        status = "Y";
      } else if (
        res.data.message
          .toUpperCase()
          .includes("AWAITING FOR FUNDS CONFIRMATION ( NODAL )") ||
        res.data.message
          .toUpperCase()
          .includes("AWAITING FOR RESPONSE FROM BILLDESK ( NODAL )") ||
        res.data.message
          .toUpperCase()
          .includes("AWAITING FOR RESPONSE FROM BILLDESK ( DIRECT )")
      ) {
        status = "Y";
        paymentAwaiting = "1";
      } else if (
        res.data.message
          .toUpperCase()
          .includes("PAYMENT NOT INITIATED FOR GIVEN ORDER")
      ) {
        status = "D";
      }

      if (status == "Y") {
        let data = {
          user_id: userid,
          bank_id: bankid,
          transaction_data: transaction_data,
          payment_status: status,
          payment_awaiting: paymentAwaiting,
          upi_id: upiId,
          payment_mode: "upi",
          is_direct: IS_DIRECT,
        };

        var res = await apiCall(DMF_SUCCESSORDER_API_URL, data);

        if (res.error_code == "100") {
          dispatch({ type: "UPDATE_CART_COUNT", payload: 0 });
          navigate(
            `${process.env.PUBLIC_URL}/direct-mutual-fund/PaymentSucess`
          );
        }
      } else if (status == "D") {
        cancelPayment(transaction_data);
      }
    }
  };

  function toggle() {
    setIsOpened((wasOpened) => !wasOpened);
  }

  const cancelPayment = async (transaction_data = "") => {
    setloader(true);
    setShow(false);
    if (transaction_data == "") {
      transaction_data = transactionId;
    }

    try {
      let data = {
        user_id: userid,
        bank_id: bankid,
        trxn_data: transaction_data,
        is_direct: IS_DIRECT,
      };
      if (checked == true) {
        data["upi_id"] = upiId;
      }
      var res = await apiCall(DMF_CANCELORDER_API_URL, data);
      setloader(false);
      if (res.error_code == "100") {
        // Calcel Order mail API call
        navigate(`${process.env.PUBLIC_URL}/direct-mutual-fund/PaymentFailed`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpiChange = (e) => {
    setUpiId(e.target.value.replace(/\s+/g, ''));
    checkErrors(e.target.value.replace(/\s+/g, ''));
  };
  
  const handleInputChange = (event) => {
    if (event.key == ' ' || event.key == 'Spacebar') {
      event.preventDefault();
    }
  }

  const checkErrors = (val) => {
    if (upiRegex.test(val) == false) {
      setErrors({ ...errors, upiError: "Please enter valid UPI ID" });
      btnRef.current.setAttribute("disabled", "disabled");
    } else {
      setErrors({});
      btnRef.current.removeAttribute("disabled");
    }
  };



  return (
    <>
      <div className="UPIBox">
        <div className="paymentgatewayoption">
          <FintooLoader isLoading={loader} />
          <Container>
            <div className="row">
              <div className="col-1 col-sm-1 col-lg-1">
                <div className="select-bank">
                  <FintooCheckbox
                    checked={props.selectedType.bank_id == props.thisTypeName}
                    className=""
                    onChange={() => {
                      props.onClick();
                      setChecked(false);
                    }}
                  />
                </div>
              </div>
              <div className="col-4 col-sm-4 col-lg-4">
                <div
                  className="BankName"
                  style={{
                    lineHeight: "20px",
                  }}
                >
                  <div className="AccountLabel">Bank</div>
                  <div
                    className="AccountNo"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {props.selectedType.bank_name}
                  </div>
                </div>
                <div
                  className="AccountDetails"
                  style={{
                    lineHeight: "20px",
                  }}
                >
                  <div className="AccountLabel">Account No.</div>
                  <div className="AccountNo">
                    {maskBankAccNo(props.selectedType.bank_acc_no)}
                  </div>
                </div>
              </div>
            </div>
          </Container>
          {props.selectedType.bank_id == props.thisTypeName && (
            <div className="boxContent">
              <hr />
              <Container>
                <Row>
                  <Col>
                    <div className="AccountDetails">
                      <h4 style={{ whiteSpace: 'normal',lineHeight:'0.8rem' }}>
                        UPI ID for Real estate Banking {props.selectedType.bank_name}{" "}
                        {maskBankAccNo(props.selectedType.bank_acc_no)}
                      </h4>
                      <div className="AccountLabel" style={{ whiteSpace: 'normal',lineHeight:'0.8rem' }}>
                        Order will fail if UPI ID is not linked to this bank
                        account
                      </div>
                    </div>
                    <div></div>
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row className="mb-grid">
                  <Col className="col-9">
                    <div className="UPIID">
                      <input
                        type="text"
                        name="UPIID"
                        maxLength={50}
                        value={upiId}
                        onChange={handleUpiChange}
                        onKeyDown={handleInputChange}
                      />
                      {errors.upiError && (
                        <span className="error center">{errors.upiError}</span>
                      )}
                    </div>
                    <div></div>
                  </Col>
                  <Col className="col-3">
                    <div>
                      <button
                        ref={btnRef}
                        className="PayNow"
                        type="button"
                        disabled={upiRegex.test(upiId) == false}
                        onClick={handleShow}
                      >
                        Pay Now
                      </button>
                    </div>
                  </Col>
                </Row>
                <div>
                  <Row className="mb-grid mt-3 text-left">
                    <Col className="col-12">
                      <div className="text-left">
                        <FintooCheckbox
                          title=" Save UPI ID for future transactions"
                          checked={checked}
                          onChange={() => setChecked((v) => !v)}
                        />
                      </div>
                      <div></div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          )}
        </div>
      </div>
      <Modal className="NomineeModal" centered show={show}>
        <div className="d-flex justify-center p-4">
          <div> {/* <FintooBackButton onClick={handleClose} /> */}</div>
          <div
            className="DeleteBank text-center pb-3 w-100"
            style={{
              borderBottom: "1px solid #eeee",
            }}
          >
            <div
              style={{
                fontWeight: "700",
              }}
            >
              Please Approve the Payment
            </div>
          </div>
        </div>
        <div className="d-grid justify-content-center align-items-center">
          <div>
            <p
              className="text-center "
              style={{
                fontWeight: "500",
                color: "gray",
              }}
            >
              Open your UPI app to approve the payment request.
            </p>
          </div>
          <div className="RoundOTP d-flex justify-content-center align-items-center p-4">
            <PaymentApprove />
          </div>
          <div>
            <p
              className="text-center pt-4"
              style={{
                fontWeight: "600",
              }}
            >
              Please approve the payment request before it times out.
            </p>
          </div>
          <div className="">
            <p
              className="text-center pt-2"
              style={{
                fontWeight: "500",
                fontSize: ".8em",
                color: "gray",
              }}
            >
              Note : Please do not go back or refresh the page.
            </p>
          </div>
          <div className="mt-5 pb-4">
            <p
              className="text-center"
              style={{
                color: "#042b62",
                fontWeight: "600",
                cursor: "pointer",
              }}
              onClick={() => {
                cancelPayment("");
              }}
            >
              Cancel Payment
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UPIBOX;
