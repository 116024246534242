// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_YoutubevideoSection__bRumh {\n    background-color: #e8f8ff6b;\n  }\n  .style_YoutubevideoContent__4VHhM {\n    width: 80%;\n    margin: auto;\n  }\n  .style_YoutubevideoBox__Bobj- {\n    margin-top: 2rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  .style_VideoFrame__NM0V3 {\n    width: 746px;\n    height: 428px;\n  }\n  /* ------------------------------------------------------------------------media query----------------------------------------------------------------- */\n  \n  @media only screen and (max-width: 600px) {\n    .style_YoutubevideoContent__4VHhM {\n      width: 100%;\n      padding: 1rem;\n    }\n    .style_VideoFrame__NM0V3 {\n      width: 100%;\n      height: 100%;\n    }\n  }\n  ", "",{"version":3,"sources":["webpack://./src/components/HTML/TaxPlanning/TaxPlanningVideoSection/style.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;EAC7B;EACA;IACE,UAAU;IACV,YAAY;EACd;EACA;IACE,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,YAAY;IACZ,aAAa;EACf;EACA,yJAAyJ;;EAEzJ;IACE;MACE,WAAW;MACX,aAAa;IACf;IACA;MACE,WAAW;MACX,YAAY;IACd;EACF","sourcesContent":[".YoutubevideoSection {\n    background-color: #e8f8ff6b;\n  }\n  .YoutubevideoContent {\n    width: 80%;\n    margin: auto;\n  }\n  .YoutubevideoBox {\n    margin-top: 2rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  .VideoFrame {\n    width: 746px;\n    height: 428px;\n  }\n  /* ------------------------------------------------------------------------media query----------------------------------------------------------------- */\n  \n  @media only screen and (max-width: 600px) {\n    .YoutubevideoContent {\n      width: 100%;\n      padding: 1rem;\n    }\n    .VideoFrame {\n      width: 100%;\n      height: 100%;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"YoutubevideoSection": "style_YoutubevideoSection__bRumh",
	"YoutubevideoContent": "style_YoutubevideoContent__4VHhM",
	"YoutubevideoBox": "style_YoutubevideoBox__Bobj-",
	"VideoFrame": "style_VideoFrame__NM0V3"
};
export default ___CSS_LOADER_EXPORT___;
