// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.style_calender__V-M62 {\n    position: relative;\n    min-width: 320px;\n    height: 630px;\n}\n.style_section__cCsGp {\n    background-color: #ffff;\n}\n.style_container__BE7aG {\n    max-width: 1140px;\n}\n.style_container__BE7aG h2{\n    font-size: 2.5rem;\n    font-weight: 700;\n}\n.style_container__BE7aG h3{\n    font-size: 1.6rem;\n    font-weight: 400;\n}", "",{"version":3,"sources":["webpack://./src/components/ContactUs/style.module.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;AACjB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":["\n.calender {\n    position: relative;\n    min-width: 320px;\n    height: 630px;\n}\n.section {\n    background-color: #ffff;\n}\n.container {\n    max-width: 1140px;\n}\n.container h2{\n    font-size: 2.5rem;\n    font-weight: 700;\n}\n.container h3{\n    font-size: 1.6rem;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calender": "style_calender__V-M62",
	"section": "style_section__cCsGp",
	"container": "style_container__BE7aG"
};
export default ___CSS_LOADER_EXPORT___;
