// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_parent__6zyhl {\n    position: absolute;\n    z-index: 9999;\n    display: flex;\n    top: 27px;\n    /* left: -85px; */\n    right: 0;\n}\n/* .parent::before {\n    content: ' ';\n    width: 100vw;\n    height: 100vh;\n    position: fixed;\n    top: 0;\n    left: 0;\n} */\n.style_link__rINwN {\n    padding-left: 1rem;\n    padding-right: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/components/HTML/FintooShareBox/style.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,aAAa;IACb,SAAS;IACT,iBAAiB;IACjB,QAAQ;AACZ;AACA;;;;;;;GAOG;AACH;IACI,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".parent {\n    position: absolute;\n    z-index: 9999;\n    display: flex;\n    top: 27px;\n    /* left: -85px; */\n    right: 0;\n}\n/* .parent::before {\n    content: ' ';\n    width: 100vw;\n    height: 100vh;\n    position: fixed;\n    top: 0;\n    left: 0;\n} */\n.link {\n    padding-left: 1rem;\n    padding-right: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parent": "style_parent__6zyhl",
	"link": "style_link__rINwN"
};
export default ___CSS_LOADER_EXPORT___;
