import SimpleReactValidator from 'simple-react-validator';
import Styles from '../style.module.css'
import { useRef, useState } from 'react';
import { apiCall, getPublicMediaURL } from '../../../../common_utilities';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { VERIFY_GOOGLE_CAPTCHA } from '../../../../constants';
import ReCaptchaComponent from '../../../Recaptcha/ReCaptchaComponent';

// const initialContactFormData = {
//     fullname: "",
//     mobile: "",
//     email: ""
// };

function Details({ setCurrView, formState, SetFormState, sendOtp }) {

    const recaptchaRef = useRef();

    const [subscribeChecked, setSubscribeChecked] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [subscribeError, setSubscribeErrorMsg] = useState('');
    const [, forceUpdateContact] = useState();


    const contactFormValidator = useRef(
        new SimpleReactValidator({
            validators: {
                phone: {
                    required: true,
                    message: "Invalid phone number.",
                    rule: (val, _, validator) => {
                        if (`${val}`.charAt(0) === '0') return false;
                        if (!validator.helpers.testRegex(val, /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)) return false;
                        return parseInt(val) > 5000000000 && parseInt(val) < 9999999999;
                    },
                },
                Email: {
                    required: true,
                    message: "Please enter valid email address.",
                    rule: (val, _, validator) => {
                        return validator.helpers.testRegex(val, /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
                    },
                },
            },
        })
    );

    const onContinue = async () => {

        if (contactFormValidator.current.allValid() && subscribeChecked && (recaptchaToken != null)) {
            // const token = await recaptchaRef.current.executeAsync();
            let url = VERIFY_GOOGLE_CAPTCHA;
            let data = { token: recaptchaToken };
            let captchaVerify = await apiCall(url, data, false, false);

            if (captchaVerify["error_code"] == "100" && captchaVerify["data"]["success"]) {
                recaptchaRef.current.reset();
                setRecaptchaToken(null)
                sendOtp();
                setCurrView('OTP');
            } else {
                return;
            }

        } else {
            contactFormValidator.current.showMessages();
            forceUpdateContact(1);
            if (!subscribeChecked) {
                setSubscribeErrorMsg('Please agree to receive alerts.');
            }

        }
    };

    const onFormDataChange = (e) => {
        e.preventDefault();

        SetFormState({ ...formState, [e.target.name]: e.target.value });
    };

    const handleRecaptchaChange = (token) => {
        if (token) {
            setRecaptchaToken(token)
        } else {
            setRecaptchaToken(null)
        }
    };

    return (

        <div>
            <div className={Styles.inputDetailsContainer}>
                <div className={Styles.inputElemTitle}>Enter Details</div>
                <div className={Styles.inputElemContainer}>
                    <div className={Styles.inputElemLabel}>Name *</div>
                    <input
                        type="text"
                        tabIndex="1"
                        name="fullname"
                        id="fullname"
                        autoComplete="off"
                        value={formState.fullname}
                        className={Styles.inputElem}
                        onChange={(e) => onFormDataChange(e)}
                    />
                    <span >
                        {contactFormValidator.current.message(
                            "Name",
                            formState.fullname,
                            "required|alpha_space"
                        )}
                    </span>
                </div>
                <div className={Styles.inputElemContainer}>

                    <div className={Styles.inputElemLabel}>Mobile Number *</div>
                    <div style={{ position: 'relative' }}>
                        <input
                            type="number"
                            tabIndex="1"
                            name="mobile"
                            id="mobile"
                            autoComplete="off"
                            value={formState.mobile}
                            className={Styles.inputElem}
                            onChange={(e) => onFormDataChange(e)}
                            style={{ paddingLeft: '50px' }}
                        />
                        <img
                            src={getPublicMediaURL("static/media/flagIndia.svg")}
                            alt="DOB"
                            style={{
                                width: '30px',
                                position: 'absolute',
                                left: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)'
                            }}
                        />
                    </div>

                    <span >
                        {contactFormValidator.current.message(
                            "Mobile_Number",
                            formState.mobile,
                            "phone"
                        ) && formState.mobile === "" ? (
                            <div className='srv-validation-message'>
                                The mobile number field is required.
                            </div>
                        ) : (
                            contactFormValidator.current.message(
                                "Mobile_Number",
                                formState.mobile,
                                "phone"
                            )
                        )}
                    </span>
                </div>
                <div className={Styles.inputElemContainer}>
                    <div className={Styles.inputElemLabel}>Email *</div>
                    <input
                        type="email"
                        tabIndex="1"
                        name="email"
                        id="email"
                        autoComplete="off"
                        value={formState.email}
                        className={Styles.inputElem}
                        onChange={(e) => onFormDataChange(e)}
                    />
                    <span >
                        {contactFormValidator.current.message(
                            "Email",
                            formState.email,
                            "required|email"
                        )}
                    </span>
                </div>
                <div className={`${Styles.termsSection}`}>

                    <div className={`${Styles.input} ${Styles.checkbox} d-flex align-items-center`}>
                        <input type="checkbox" name="" id="accept" onChange={(e) => {
                            setSubscribeChecked(prev => !prev);
                            if (e.target.checked == false) {
                                setSubscribeErrorMsg('Please agree to receive alerts.')
                            }
                            else {
                                setSubscribeErrorMsg('')
                            }
                        }} checked={subscribeChecked} />
                        <label className="ms-2" for="accept" style={{ fontSize: '1rem' }}>
                            I agree to receive alerts via email & text messages.*
                        </label>
                    </div>
                    {subscribeError && <p style={{ fontSize: '12px', color: 'red' }} >{subscribeError}</p>}

                </div>
                <div className={`${Styles.termsSectionTxt}`} style={{ color: "#9e9e9e", fontSize: '1rem' }}>
                    By continuing, you acknowledge and accept our&nbsp;
                    <Link to={process.env.PUBLIC_URL + "/terms-conditions"} style={{ textDecoration: "underline", fontWeight: "600" }} className={`${Styles.forget} custom-color`} target="_blank" >
                        <span style={{ fontWeight: "500" }}>Terms & Conditions</span>.</Link>
                </div>
                <ReCaptchaComponent ref={recaptchaRef} onChange={handleRecaptchaChange} />
            </div>
            <br />
            <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                <button id='continue' className={Styles.btnElem} onClick={() => onContinue()}>Continue</button>
            </div>
            {/* <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LeTEa8pAAAAAHCO5zVqhJOTPCRZp6rzaZ5tCjiR"
            /> */}
        </div>
    );
}

export default Details;
