import { Modal } from "react-bootstrap";
import FintooBackButton from "../HTML/FintooBackButton";
import FintooCheckbox from "../FintooCheckbox/FintooCheckbox";
import React from "react";
import { indianRupeeFormat } from "../../common_utilities";
import Table from "react-bootstrap/Table";
import style from "./otpmodal.module.css";

const StopSipSelectionModal = ({ detailedMfPotfolio, setDetailedMfPotfolio }) => {

  const toggleTrnxCheckbox = (v) => {
    setDetailedMfPotfolio(prev => ({
      ...prev,
      sip_stp_swp_data: {
        ...prev.sip_stp_swp_data,
        moreSIP: prev.sip_stp_swp_data.moreSIP.map(x => ({
          ...x,
          checked: x.transaction_id === v.transaction_id ? !(Boolean(x.checked)) : x.checked
        }))
      }
    }));
  }


  const toggleeditTrnxCheckbox = (v) => {
    setDetailedMfPotfolio(prev => ({
      ...prev,
      sip_stp_swp_data: {
        ...prev.sip_stp_swp_data,
        moreSIP: prev.sip_stp_swp_data.moreSIP.map(x => ({
          ...x,
          editsipchecked: x.transaction_id === v.transaction_id ? !(Boolean(x.editsipchecked)) : x.editsipchecked
        }))
      }
    }));
  }
  // console.log('detailedMfPotfolio29', detailedMfPotfolio);

  return (
    <>
      <Table responsive className="trx-tbl8">
        <thead>
          <tr>
            <th>SIP Amount</th>
            <th>SIP Date</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {(detailedMfPotfolio?.sip_stp_swp_data?.moreSIP ?? []).map((v) => (<React.Fragment key={'k-' + v.transaction_id}>
            <tr>
              <td>{indianRupeeFormat(v.cart_amount)}</td>
              <td>{v.sip_start_date}</td>
              <td>{v.sip_start_date}</td>
              <td>{v.sip_start_date}</td>
              <td scope="row" data-label="">
                <div className={`${style.SIPButtons}`}>
                  <div className="d-md-block d-none">
                    <button onClick={() => {
                    toggleeditTrnxCheckbox(v)
                    }}>Edit SIP</button>
                    <button onClick={() => {

                    }}>Skip SIP</button>
                    <button onClick={() => {
                      toggleTrnxCheckbox(v);
                    }}
                    >Stop SIP</button>
                  </div>
                  <div className="d-md-none d-block w-100">
                    <center>
                      <button onClick={() => {

                      }}>Manage SIP</button>
                    </center>
                  </div>
                </div>
              </td>
            </tr>
          </React.Fragment>))}

        </tbody>
      </Table>
    </>
  );
};
export default StopSipSelectionModal;
