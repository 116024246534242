import { useEffect, useRef } from "react";
import { removeMemberId, removeUserId } from "../../common_utilities";

const AutoLogout = () => {

  const myTimer = useRef();
  useEffect(() => {
    if (Boolean(localStorage.getItem("sky")) && Boolean(localStorage.getItem('lastVisit'))) {
      let d = new Date();
      if (d.valueOf() > Number(localStorage.getItem("lastVisit"))) {
        redirectNow();
        return;
      }
    }
    checkStorage();
    autoExpire();
    document.addEventListener("click", setStorage);
    document.addEventListener("mouseover", setStorage);
  }, []);

  const checkStorage = () => {
    setStorage();
  };

  const setStorage = () => {
    const d = new Date();
    // d.setTime(d.getTime() + 60 * 60 * 1000);
    d.setTime(d.getTime() + (15 * 60 * 1000));
    localStorage.setItem("lastVisit", d.valueOf());
  };

  const autoExpire = () => {
    myTimer.current = setInterval(() => {
      if (Boolean(localStorage.getItem("sky")) && Boolean(localStorage.getItem('lastVisit'))) {
        let d = new Date();
        if (d.valueOf() > Number(localStorage.getItem("lastVisit"))) {
          clearInterval(myTimer.current);
          myTimer.current = null;
          redirectNow();
        }
      }
    }, 5000);
  };

  const redirectNow = () => {
    removeMemberId();
    removeUserId();
    localStorage.removeItem("sky");
    const _w = window.location.href;
    localStorage.setItem("redirectToThis", _w);
    console.log("lastVisted set:", _w);

    // Check if current URL does not already contain '/session-expired'
    if (!_w.includes('/session-expired')) {
      // Store the current URL in localStorage as last visited
      localStorage.setItem("lastVisited", _w);
    }
    window.location.href = process.env.PUBLIC_URL + "/session-expired";
  };

  return <></>;
};

export default AutoLogout;
