// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_nil-tooltip__UYVd7 {\n    position: relative;\n    cursor: pointer;\n}\n.style_nil-tooltip-text__mtz2Y {\n    display: none;\n    position: absolute;\n    background: #fff;\n    border: 1px solid #eee;\n    border-radius: 15px;\n    padding: 1rem;\n    font-size: .8rem;\n    top: -1rem;\n    left: 1.2rem;\n}\n.style_nil-tooltip__UYVd7:hover .style_nil-tooltip-text__mtz2Y {\n    display: block;\n}", "",{"version":3,"sources":["webpack://./src/components/HTML/ToolTip/style.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB,UAAU;IACV,YAAY;AAChB;AACA;IACI,cAAc;AAClB","sourcesContent":[".nil-tooltip {\n    position: relative;\n    cursor: pointer;\n}\n.nil-tooltip-text {\n    display: none;\n    position: absolute;\n    background: #fff;\n    border: 1px solid #eee;\n    border-radius: 15px;\n    padding: 1rem;\n    font-size: .8rem;\n    top: -1rem;\n    left: 1.2rem;\n}\n.nil-tooltip:hover .nil-tooltip-text {\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nil-tooltip": "style_nil-tooltip__UYVd7",
	"nil-tooltip-text": "style_nil-tooltip-text__mtz2Y"
};
export default ___CSS_LOADER_EXPORT___;
