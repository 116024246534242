// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_TradeText__zIBxv {\n    padding-left: 1rem;\n    padding-right: 1rem;\n}\n.style_PlanBox__XcPHP {\n    border: 1px solid #d8d8d8;\n    padding: 0rem;\n    border-radius: 12px;\n    /* margin-top: 2.6rem; */\n}\n.style_tradebox__21X6n {\n    padding-top: 3rem;\n    padding-bottom: 3rem;\n    \n}\n.style_TradeNextImg__mEGye {\n    padding-right: 3rem;\n}\n.style_BigPlanText__nlIvK {\n    color: #005b98;\n    font-weight: 700;\n    font-size: 1.8rem;\n}\n.style_SmallPlanText__Z\\+5UK {\n    color: #042b62;\n    font-size: 1rem;\n}\n.style_TradeImg__MG9aT {\n    margin-left: -2rem;\n    margin-top: -1rem;\n}", "",{"version":3,"sources":["webpack://./src/components/MakeYourTaxPlan/style.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,wBAAwB;AAC5B;AACA;IACI,iBAAiB;IACjB,oBAAoB;;AAExB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".TradeText {\n    padding-left: 1rem;\n    padding-right: 1rem;\n}\n.PlanBox {\n    border: 1px solid #d8d8d8;\n    padding: 0rem;\n    border-radius: 12px;\n    /* margin-top: 2.6rem; */\n}\n.tradebox {\n    padding-top: 3rem;\n    padding-bottom: 3rem;\n    \n}\n.TradeNextImg {\n    padding-right: 3rem;\n}\n.BigPlanText {\n    color: #005b98;\n    font-weight: 700;\n    font-size: 1.8rem;\n}\n.SmallPlanText {\n    color: #042b62;\n    font-size: 1rem;\n}\n.TradeImg {\n    margin-left: -2rem;\n    margin-top: -1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TradeText": "style_TradeText__zIBxv",
	"PlanBox": "style_PlanBox__XcPHP",
	"tradebox": "style_tradebox__21X6n",
	"TradeNextImg": "style_TradeNextImg__mEGye",
	"BigPlanText": "style_BigPlanText__nlIvK",
	"SmallPlanText": "style_SmallPlanText__Z+5UK",
	"TradeImg": "style_TradeImg__MG9aT"
};
export default ___CSS_LOADER_EXPORT___;
