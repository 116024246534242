// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n@media (min-width: 1400px) {\n    .style_container__o7DxP {\n        width: 1140px!important;\n    }\n}\n\n.style_card-item__waXUa img {\n    margin: auto;\n}\n@media (max-width: 767px) {\n    .style_featured-in-xu__FUAvx {\n        width: 80%;\n        margin: auto;\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/HTML/FeaturedIn/style.module.css"],"names":[],"mappings":";AACA;IACI;QACI,uBAAuB;IAC3B;AACJ;;AAEA;IACI,YAAY;AAChB;AACA;IACI;QACI,UAAU;QACV,YAAY;IAChB;AACJ","sourcesContent":["\n@media (min-width: 1400px) {\n    .container {\n        width: 1140px!important;\n    }\n}\n\n.card-item img {\n    margin: auto;\n}\n@media (max-width: 767px) {\n    .featured-in-xu {\n        width: 80%;\n        margin: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container__o7DxP",
	"card-item": "style_card-item__waXUa",
	"featured-in-xu": "style_featured-in-xu__FUAvx"
};
export default ___CSS_LOADER_EXPORT___;
