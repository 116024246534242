import styles from './style.module.css';


const FooterUAE = () => {
    return (<div className={styles.footer}>
        <div className="container">
            <div className='py-4'>
                <p>Financial Hospital Advisor LLP (Brand Name - Fintoo.in, Fintoo app, Fintoo) makes no warranties or representations, express or implied, on products and services offered through the platform. It accepts no liability for any damages or losses, however, caused in connection with the use of, or on the reliance of its advisory or related services.</p>
                <p>Past performance is not indicative of future returns. Please consider your specific investment requirements, risk tolerance, goal, time frame, risk and reward balance and the cost associated with the investment before choosing a fund, or designing a portfolio that suits your needs. Performance and returns of any investment portfolio can neither be predicted nor guaranteed. Investments made on advisory are subject to market risks, read all scheme related documents carefully. Marketing and distribution of various financial products such as Mutual Funds,Loans,Insurance,Bonds, Domestic Equity and International Equity are powered by Mihika Financial Services Private Limited (FintooInvest.in, FintooInvest app, FintooInvest).</p>
                <p>© FINANCIAL HOSPITAL ADVISOR LLP [SEBI RIA Registration No: INA000015756] [Type of Registration: Non-Individual] [Validity of registration: February 17, 2021-Perpetual] [Address: Financial Hospital B-404, Kanakia Wall Street, Andheri - Kurla Rd, Hanuman Nagar, Andheri East, Mumbai, Maharashtra 400093] [Platform Partner : BSE (Member code-10096)] [LLP - AAE-1913] [GST No : 27AAEFF2161P1Z6] [Principal Officer details : Principal Officer: Ms. Nidhi Manchanda(nidhi.manchanda@fintoo.in)] [Corresponding SEBI regional/local office: Plot No. C 4-A , G Block, Near Bank of India, Bandra Kurla Complex,Bandra East, Mumbai, Maharashtra 400051]</p>
                <p className="text-center mb-0 ">Copyright © 2022 Fintoo,. All rights reserved</p>
            </div>
        </div>
    </div>);
}
export default FooterUAE;