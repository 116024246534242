// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.style_footer__3fTIT {\n    background-color: #001c38;\n    color: #adadad;\n}", "",{"version":3,"sources":["webpack://./src/components/FooterUAE/style.module.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":["\n.footer {\n    background-color: #001c38;\n    color: #adadad;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "style_footer__3fTIT"
};
export default ___CSS_LOADER_EXPORT___;
