// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".insuranceForm_recurringFormContainer__TodA0 {\n    display: flex;\n}\n\n@media only screen and (max-width: 500px) {\n    .insuranceForm_recurringFormContainer__TodA0 {\n        display: unset;\n    }\n}", "",{"version":3,"sources":["webpack://./src/Pages/DMF/CommonDashboard/NewInsuranceFormViews/insuranceForm.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".recurringFormContainer {\n    display: flex;\n}\n\n@media only screen and (max-width: 500px) {\n    .recurringFormContainer {\n        display: unset;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recurringFormContainer": "insuranceForm_recurringFormContainer__TodA0"
};
export default ___CSS_LOADER_EXPORT___;
