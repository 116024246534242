// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fhc_Membershiporg__R2ppL {\n  margin-top: 19rem;\n}\n\n@media only screen and (max-width: 600px) {\n  .fhc_Membershiporg__R2ppL {\n    margin-top: 0;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/Pages/FHC/fhc.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".Membershiporg {\n  margin-top: 19rem;\n}\n\n@media only screen and (max-width: 600px) {\n  .Membershiporg {\n    margin-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Membershiporg": "fhc_Membershiporg__R2ppL"
};
export default ___CSS_LOADER_EXPORT___;
