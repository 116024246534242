import { useState } from "react";
import { useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import DatagatherReportLayout from "../../components/Layout/Datagather/Reports";
import { Link } from "react-router-dom";
import { CHECK_SESSION, imagePath } from "../../constants";
import {
  apiCall,
  fetchEncryptData,
  getItemLocal,
  getParentUserId,
  loginRedirectGuest,
} from "../../common_utilities";
import Currentequityinv from "../../components/HTML/Report/AssetsandLiabilities/Currentequityinv";
import Assetsummary from "../../components/HTML/Report/AssetsandLiabilities/Assetsummary";
import Debtinv from "../../components/HTML/Report/AssetsandLiabilities/Debtinv";
import Alternateinv from "../../components/HTML/Report/AssetsandLiabilities/Alternateinv";
import Realestate from "../../components/HTML/Report/AssetsandLiabilities/Realestate";
import Ulip from "../../components/HTML/Report/AssetsandLiabilities/Ulip";
import FintooLoader from "../../components/FintooLoader";
import Liability from "../../components/HTML/Report/AssetsandLiabilities/Liability";
import Networth from "../../components/HTML/Report/AssetsandLiabilities/Networth";
import Fintoorecommends from "../../components/HTML/Report/AssetsandLiabilities/Fintoorecommends";
import Portfolioanalysis from "../../components/HTML/Report/AssetsandLiabilities/Portfolioanalysis";
import { ScrollToTop } from "./ScrollToTop"
const AssetsLib = () => {
  const [tab, setTab] = useState("tab3");
  const [assettab, setAssetTab] = useState("tab7");
  const [isLoading, setIsLoading] = useState(false);
  const [session, setSession] = useState({});
  const userid = getParentUserId();

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (!userid) {
      loginRedirectGuest();
    }
  }, []);

  useEffect(() => {
    document.body.classList.add("dg-layout");
    document.body.classList.add("rp-layout");
    document.getElementById('report-bg-assets').style.background = 'url(' + imagePath +
                          '/static/assets/img/reports/ill-assest-liabilities.svg)' + " no-repeat right top";
    return () => {
      document.body.classList.remove("dg-layout");
      document.body.classList.remove("rp-layout");
    };
  }, []);

  useEffect(() => {
    checksession();
  }, []);

  const checksession = async () => {
    try {
      setIsLoading(true)
      var payload = {
        method: "post",
        url: CHECK_SESSION,
        data: { user_id: getParentUserId(), sky: getItemLocal("sky") },
      };
      let session_data = await fetchEncryptData(payload);
      if (session_data["error_code"] == "100") {
        setSession(session_data["data"]);
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);

    }
  };

  const handleSetTab = (data) => {
    setAssetTab(data);
  };

  const handleMainSetTab = (data) => {
    setTab(data);
  };

  return (
    <>
      <FintooLoader isLoading={isLoading} />
      <DatagatherReportLayout>
        <div className="reports ">
          <div className="">
            <div className="background-div">
              <div class="bg active" id="report-bg-assets"></div>
            </div>
            <div className="white-box">
              <div className="d-flex justify-content-md-center tab-box">
                <div className="d-flex top-tab-menu noselect">
                
                  <div
                    className={`tab-menu-item ${tab == "tab3" ? "active" : ""}`}
                    onClick={() => setTab("tab3")}
                  >
                    <div className="tab-menu-title">NET WORTH</div>
                  </div>
                  <div
                    className={`tab-menu-item ${tab == "tab4" ? "active" : ""}`}
                    onClick={() => setTab("tab4")}
                  >
                    <div className="tab-menu-title">FINTOO RECOMMENDS</div>
                  </div>
                  <div
                    className={`tab-menu-item ${tab == "tab5" ? "active" : ""}`}
                    onClick={() => setTab("tab5")}
                  >
                    <div className="tab-menu-title">PORTFOLIO ANALYSIS</div>
                  </div>
                  <div
                    className={`tab-menu-item ${tab == "tab6" ? "active" : ""}`}
                    onClick={() => setTab("tab6")}
                  >
                    <div className="tab-menu-title">WHAT IF ANALYSIS</div>
                  </div>
                </div>
              </div>

              {Object.keys(session).length > 0 && (
                <div className="asset-recomm ">
                  <div className={tab == "tab3" ? "d-block" : "d-none"}>
                    <Networth session={session} settab1={handleMainSetTab} />
                  </div>
                  <div className={tab == "tab4" ? "d-block" : "d-none"}>
                    <Fintoorecommends
                      session={session}
                      settab1={handleMainSetTab}
                    />
                  </div>
                  {tab == "tab5" && <div>
                    <Portfolioanalysis session={session} settab1={handleMainSetTab} />
                  </div>}
                  <div className={tab == "tab6" ? "d-block" : "d-none"}>
                    <div className="analysis-section text-center ">
                      <div className="container">
                        <div className="row justify-content-center align-items-center">
                          <div className="col-md-10">
                          <img src={imagePath + "/web/static/media/DMF/coming-soon.svg"} alt="comming-soon" />
                            {/* <p class="mt-2">Coming Soon</p> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row py-2">
                      <div className=" text-center">
                        <div>
                          <div className="btn-container">
                            <div className="d-flex justify-content-center">
                              <div
                                className="previous-btn form-arrow d-flex align-items-center"
                                onClick={() => 
                                  {
                                    ScrollToTop();
                                    setTab("tab5")
                                  }
                                }
                              >
                                <FaArrowLeft />
                                <span className="hover-text">
                                  &nbsp;Previous
                                </span>
                              </div>
                              <Link
                                to={
                                  process.env.PUBLIC_URL +
                                  "/report/goal-analysis"
                                }
                              >
                                <div className="next-btn form-arrow d-flex align-items-center">
                                  <span
                                    className="hover-text"
                                    style={{ maxWidth: 100 }}
                                  >
                                    Continue&nbsp;
                                  </span>
                                  <FaArrowRight />
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </DatagatherReportLayout>
    </>
  );
};
export default AssetsLib;
