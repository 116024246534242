// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_ExtraPerformanceAdviceSection__nlWYl {\n    background-color: #00132E;\n    text-align: center;\n}\n\n.style_ExtraPerformanceAdviceSection__nlWYl p {\n    color: white;\n}\n\n.style_GlobalText2__zGQl0 {\n    padding: 2rem;\n    color: white;\n    font-size: 2rem;\n    font-weight: bold;\n}\n\n@media (max-width: 850px) {\n    .style_ExtraPerformanceAdviceSection__nlWYl img{\n        width: 80%;\n    }\n}", "",{"version":3,"sources":["webpack://./src/Pages/FinancialPlanningPage/ExtraPerformanceAdvice/style.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".ExtraPerformanceAdviceSection {\n    background-color: #00132E;\n    text-align: center;\n}\n\n.ExtraPerformanceAdviceSection p {\n    color: white;\n}\n\n.GlobalText2 {\n    padding: 2rem;\n    color: white;\n    font-size: 2rem;\n    font-weight: bold;\n}\n\n@media (max-width: 850px) {\n    .ExtraPerformanceAdviceSection img{\n        width: 80%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ExtraPerformanceAdviceSection": "style_ExtraPerformanceAdviceSection__nlWYl",
	"GlobalText2": "style_GlobalText2__zGQl0"
};
export default ___CSS_LOADER_EXPORT___;
