import React, { useEffect, useState } from "react";
import WealthContactForm from "../components/WealthManagementContactForm/";
import backImg from "../components/Layout/Fullpage/assets/insurance1-contact-form-background.jpg";
import SecuritySection from "../components/HTML/SecuritySection";
import { Helmet } from "react-helmet-async";
import BondInvestmentHeaderSection from "../components/HTML/BondInvestment/BondInvestmentHeader";
import WhyChooseBondInvestmentSection from "../components/HTML/BondInvestment/WhyChooseBondInvestmentSection";
import BondinvestmentVideoSection from "../components/HTML/BondInvestment/BondInvestmentVideoSection";
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import Fullpage from "../components/Layout/Fullpage";
import ThankyouSection from "../components/ThankyouSection";
import AppointmentBox from "../components/Pages/Calendly";
import FaqSection from '../components/HTML/BondInvestment/FaqSection';
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
function BondInvestment() {
  const [show, SetShow] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [pageurl, setPageurl] = React.useState();
  const [utmSource, setUtmSource] = useState(26);
  const [tagval, setTagval] = useState(null);
  useEffect(() => {
    function extractParametersFromURL() {
      // const urlSearchParams = new URLSearchParams(new URL(url).search);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const utmSource = urlSearchParams.get('utm_source');
      const tagval = urlSearchParams.get('tags');
      setPageurl(location.pathname);
      setUtmSource(utmSource);
      setTagval(tagval);
    }
    extractParametersFromURL();
    window.addEventListener('popstate', extractParametersFromURL);
    return () => {
      window.removeEventListener('popstate', extractParametersFromURL);
    };
  }, []);
  React.useEffect(() => {
    document.body.classList.add('MainDivRemove');

  }, []);
  return (
    <Fullpage>
       <Helmet>
        <title>Best Online Bond Investment Platform</title>
        <meta name="description" content="Explore all available bonds, select the ones that match your requirements, and invest for a secure and profitable financial future." />
      </Helmet>
      <div style={{ backgroundColor: "white" }}>
        <BondInvestmentHeaderSection />
        <WhyChooseBondInvestmentSection />
        <BondinvestmentVideoSection />
        <SecuritySection />
        {/* <FaqSection /> */}
        <section
          style={{

          }}
          id="bondInvestmentContact"
        >
          {utmSource && tagval ? (
            <AppointmentBox
              extraParams={{ "utm_source": utmSource, "service": 98 }}
              eventCode={tagval}
              serviceName="Bonds"
              eventUrl={"https://calendly.com/fintoo/15-min-consultation-call-bond-investment?hide_event_type_details=1"}
              planId = "47"
            />
          ) : (
            <AppointmentBox
              eventCode={'Callback_mintyApp_33'}
              serviceName="Bonds"
              eventUrl={"https://calendly.com/fintoo/15-min-consultation-call-bond-investment?hide_event_type_details=1"}
              planId = "47"
            />
          )}
          {/* <AppointmentBox eventCode={'Callback_mintyApp_33'} serviceName="Bonds" eventUrl="https://calendly.com/fintoo/15-min-consultation-call-bond-investment?hide_event_type_details=1" /> */}
          {/* <WealthContactForm imgSrc={backImg} serviceType={servicesTypes.bondInvestment} onSuccess={() => SetShow(true)} /> */}
        </section>
        <FaqSection />
        {show && <ThankyouSection onClose={() => SetShow(false)} />}
      </div>
    </Fullpage>
  );
}

export default BondInvestment;
