// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_insurance-clients-testemonial-section__DTUnu {\n    background-color: #ffffff;\n    padding: 5rem 0;\n}\n.style_Testimonial__n3l9r{\n    width: 80%;\n    margin: auto;\n}", "",{"version":3,"sources":["webpack://./src/components/HTML/VirtualItrHelpdesk/ITRClientTestimonialSection/style.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;AACnB;AACA;IACI,UAAU;IACV,YAAY;AAChB","sourcesContent":[".insurance-clients-testemonial-section {\n    background-color: #ffffff;\n    padding: 5rem 0;\n}\n.Testimonial{\n    width: 80%;\n    margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"insurance-clients-testemonial-section": "style_insurance-clients-testemonial-section__DTUnu",
	"Testimonial": "style_Testimonial__n3l9r"
};
export default ___CSS_LOADER_EXPORT___;
