// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_successData__mvcTv {\n    padding: 2rem;\n    display: grid;\n    align-items: center;\n    place-content: center;\n    text-align: center;\n}\n\n.style_successData__mvcTv .style_title__iX-8l {\n    font-size: 1.3rem;\n    font-weight: 600;\n}\n\n.style_currVal__JTGsi {\n    font-size: 1.5rem;\n    font-weight: bold;\n    margin: 1rem;\n}\n\n.style_currVal__JTGsi span {\n    color: #042b62;\n    font-weight: bold;\n}\n\n.style_successData__mvcTv .style_Description__S3mBI {\n    color: #99a1b7;\n    font-size: 1rem;\n    margin: 0 2rem;\n}", "",{"version":3,"sources":["webpack://./src/components/CommonDashboard/Report/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,cAAc;AAClB","sourcesContent":[".successData {\n    padding: 2rem;\n    display: grid;\n    align-items: center;\n    place-content: center;\n    text-align: center;\n}\n\n.successData .title {\n    font-size: 1.3rem;\n    font-weight: 600;\n}\n\n.currVal {\n    font-size: 1.5rem;\n    font-weight: bold;\n    margin: 1rem;\n}\n\n.currVal span {\n    color: #042b62;\n    font-weight: bold;\n}\n\n.successData .Description {\n    color: #99a1b7;\n    font-size: 1rem;\n    margin: 0 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successData": "style_successData__mvcTv",
	"title": "style_title__iX-8l",
	"currVal": "style_currVal__JTGsi",
	"Description": "style_Description__S3mBI"
};
export default ___CSS_LOADER_EXPORT___;
