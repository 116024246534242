// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommonPopup_modalContent__G3c\\+W{\n    width: 100%;\n    display: flex;\n    flex-direction:  column;\n    align-items: center;\n    justify-content: center;\n}\n.CommonPopup_modalContent__G3c\\+W img{\n    width: 250px;\n}\n .CommonPopup_infoText__TTBDP{\n    font-size: 1.1rem;\n    font-weight: 600;\n    padding: 1rem 2rem;\n    /* margin-top: 1rem; */\n}", "",{"version":3,"sources":["webpack://./src/components/CommonStyle/CommonPopup.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,YAAY;AAChB;CACC;IACG,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,sBAAsB;AAC1B","sourcesContent":[".modalContent{\n    width: 100%;\n    display: flex;\n    flex-direction:  column;\n    align-items: center;\n    justify-content: center;\n}\n.modalContent img{\n    width: 250px;\n}\n .infoText{\n    font-size: 1.1rem;\n    font-weight: 600;\n    padding: 1rem 2rem;\n    /* margin-top: 1rem; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": "CommonPopup_modalContent__G3c+W",
	"infoText": "CommonPopup_infoText__TTBDP"
};
export default ___CSS_LOADER_EXPORT___;
